body {
    #wrapper_top_menu {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .main_menu {
            &.main_wrapper {
                display: flex;
                justify-content: center;
                align-items: center;

                .categ_item {
                    padding: 0 1rem;

                    & > .block_lnk {
                        text-decoration: none;
                        display: block;

                        .menu_categ {
                            display: flex;
                            align-items: center;
                            height: 56px;
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                        }
                    }

                    &:last-of-type {
                        & > .block_lnk {
                            padding-right: 0 !important;
                        }
                    }

                    .block_lnk {
                        color: var(--textcolor, $primaryColor);
                        text-decoration: none;
                    }

                    &:hover {
                        .block_lnk {
                            color: var(--hovercolor, var(--textcolor, $primaryColor));
                        }
                    }

                    .menu_categ {
                        span {
                            position: relative;

                            &:before {
                                content: '';
                                position: absolute;
                                width: 0;
                                height: 1px;
                                background-color: var(--hovercolor, var(--textcolor, $primaryColor));
                                left: 0;
                                right: 0;
                                bottom: -2px;
                                margin: auto;
                                transition: width ease-in-out 0.5s;
                            }
                        }

                        &:hover {
                            span {
                                &:before {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    .menu_categ_wrapper {
                        position: fixed;
                        width: 100%;
                        display: none;
                        max-width: 1920px;
                        height: auto;
                        left: 0;
                        right: 0;
                        background-color: $white;
                        margin: 0 auto;
                        padding: 20px;
                        border-top: 1px solid rgba($black, 0.2);
                        z-index: -1;

                        .sscateg_wrapper {
                            display: flex;
                            column-gap: 50px;
                            margin-right: 50px;

                            .sscateg_rayon {
                                display: flex;
                                flex-direction: column;
                                column-gap: 50px;
                                row-gap: 0.5rem;
                                flex-wrap: wrap;
                                height: auto;
                                max-height: 230px;
                                padding: 0;
                                list-style-type: none;

                                li {
                                    font-family: $primaryFont;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 300;
                                    line-height: normal;
                                    color: var(--textcolor, $primaryColor);
                                    text-decoration: none;
                                    transition: all ease-in-out 0.5s;
                                    width: 10rem;

                                    &:hover {
                                        color: var(--hovercolor, var(--textcolor, $primaryColor));
                                    }

                                    &.sscateg_see_all {
                                        .menu_sscateg {
                                            text-decoration: underline;
                                            text-underline-offset: 4px;
                                        }
                                    }

                                    &:not(.sscateg_see_all) {
                                        .menu_sscateg:hover {
                                            font-weight: 500;
                                        }
                                    }
                                }

                                &:has(li:nth-child(n+8)) {
                                    white-space: nowrap;
                                    flex: 1 1 50%;
                                }

                                &:li:nth-child(n+8) {
                                    white-space: normal;
                                }
                            }

                            & + .sscateg_push,
                            & +.menu_img {
                                margin-left: auto;
                            }

                            @media only screen and (max-width: 1520px) {
                                margin-right: 20px;

                                .sscateg_rayon {
                                    li {
                                        font-size: 15px;
                                    }
                                }
                            }
                        }

                        .push_2_and_3_wrapper {
                            display: flex;
                            margin-left: auto;
                        }

                        .sscateg_push,
                        .menu_img {
                            display: block;
                            margin-right: 24px;
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                            text-decoration-line: underline;
                            text-align: center;

                            a {
                                display: block;
                                color: $black;

                                span {
                                    border-bottom: 1px solid $black;
                                }
                            }

                            img {
                                display: block;
                                height: auto;
                                width: 100%;

                                & + span,
                                & + a {
                                    display: inline-block;
                                    width: auto;
                                    font-family: $primaryFont;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 300;
                                    line-height: 1;
                                    margin-top: 20px;
                                    text-decoration: none;

                                    span {
                                        display: inline-block;
                                        width: auto;
                                        border-bottom: 1px solid $black;
                                    }
                                }
                            }

                            &.push_1 {
                                max-width: 545px;
                                // max-width: 28.5%;
                                width: fs-vw(545, 1920);

                                img {
                                    // width: 100%;
                                    // height: auto;

                                    max-height: 206px;
                                    height: fs-vw(206, 1920);
                                    width: auto;
                                }

//                                 @media only screen and (max-width: 1870px) {
//                                     width: 440px;
//                                     max-width: 27%;
//
//                                     img {
//                                         width: auto;
//                                         max-height: 165px;
//                                     }
//                                 }
//
//                                 @media only screen and (max-width: 1320px) {
//                                     max-width: 34%;
//                                 }
//
//                                 @media only screen and (max-width: 1280px) {
//                                     max-width: 30%;
//                                 }
                            }

                            &.push_2 {
                                max-width: 477px;
                                // max-width: 25.75vw;
                                width: fs-vw(477, 1920);
                                margin-left: 18px;
                                margin-right: 18px;

                                img {
                                    // width: 100%;
                                    // height: auto;

                                    max-height: 206px;
                                    height: fs-vw(206, 1920);
                                    width: auto;
                                }


//                                 @media only screen and (max-width: 1870px) {
//                                     width: 430px;
//                                     margin-left: 12px;
//                                     margin-right: 12px;
//
//                                     img {
//                                         width: auto;
//                                         max-height: 165px;
//                                     }
//                                 }
                            }

                            &.push_3 {
                                max-width: 477px;
                                width: fs-vw(477, 1920);
                                // max-width: 25.75vw;
                                margin-left: auto;
                                margin-right: 0;

                                img {
                                    // width: 100%;
                                    // height: auto;

                                    max-height: 206px;
                                    height: fs-vw(206, 1920);
                                    width: auto;
                                }

//                                 @media only screen and (max-width: 1870px) {
//                                     width: 440px;
//
//                                     img {
//                                         width: auto;
//                                         max-height: 165px;
//                                     }
                                // }
                            }
                        }

                        &:has(.push_3) {
                            .sscateg_wrapper {
                                margin-right: 18px;
                            }

                            .push_2,
                            .push_3 {
                                margin-left: auto;
                            }
                        }

                        &:not(:has(.block_lnk)) {
                            padding: 0;
                        }
                    }

                    &.actif,
                    &:hover {
                        .menu_categ {
                            span {
                                position: relative;

                                &:before {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

//                 @media only screen and (max-width: 1560px) {
//                     .categ_item {
//                         & > .block_lnk {
//                             padding-right: 50px;
//                         }
//                     }
//                 }
//
//                 @media only screen and (max-width: 1360px) {
//                     .categ_item {
//                         & > .block_lnk {
//                             padding-right: 30px;
//                         }
//                     }
//                 }
            }
        }
    }

    &.desktop_mode {
        #wrapper_top_menu {
            .main_menu {
                &.main_wrapper {
                    .categ_item {
                        &:hover {
                            .menu_categ_wrapper {
                                display: flex;
                                z-index: 1;

                                &.has-banner-1.has-banner-2.has-banner-3 {
//                                     .menu_img {
//                                         &.push_1,
//                                         &.push_2,
//                                         &.push_3 {
//                                             @media only screen and (max-width: 1680px) {
//                                                 img {
//                                                     width: auto;
//                                                     height: 160px;
//                                                 }
//                                             }
//
//                                             @media only screen and (max-width: 1640px) {
//                                                 img {
//                                                     height: 140px;
//                                                 }
//                                             }
//
//                                             @media only screen and (max-width: 1360px) {
//                                                 img {
//                                                     height: 120px;
//                                                 }
//                                             }
//                                         }
//                                     }
                                }
                            }
                        }

                        .menu_categ_wrapper {
//                             &.has-banner-1.has-banner-2:not(.has-banner-3),
//                             &.has-banner-1.has-banner-3:not(.has-banner-2),
//                             &.has-banner-2.has-banner-3:not(.has-banner-1) {
//                                 .sscateg_wrapper {
//                                     .sscateg_rayon {
//                                         flex: 1 !important;
//
//                                         a.block_lnk {
//                                             white-space: nowrap;
//                                         }
//                                     }
//                                 }
//
//                                 .menu_img {
//                                     &.push_1,
//                                     &.push_2,
//                                     &.push_3 {
//                                         @media only screen and (max-width: 1680px) {
//                                             img {
//                                                 width: auto;
//                                                 height: 160px;
//                                             }
//                                         }
//
//                                         @media only screen and (max-width: 1640px) {
//                                             img {
//                                                 height: 140px;
//                                             }
//                                         }
//
//                                         @media only screen and (max-width: 1360px) {
//                                             img {
//                                                 height: 120px;
//                                             }
//                                         }
//                                     }
//                                 }
//                             }

                            .push_2_and_3_wrapper {
                                .menu_img:last-of-type {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.tablet_mode {
        #wrapper_top_menu {
            .main_menu {
                &.main_wrapper {
                    display: block;
                    width: 100%;
                    padding-right: 0;
                    max-height: 100%;
                    padding-bottom: 30px;
                    overflow: auto;
                    scrollbar-width: thin;
                    scrollbar-color: $black $grey;

                    ::-webkit-scrollbar {
                        width: 1px;
                        background-color: $grey;
                    }

                    ::-webkit-scrollbar-thumb {
                        background-color: $black;
                    }

                    .categ_item {
                        margin-bottom: 10px;

                        & > .block_lnk {
                            .menu_categ {
                                height: auto;
                            }
                        }

                        .block_lnk {
                            margin-bottom: 10px;
                        }

                        .menu_categ_wrapper {
                            position: relative;
                            top: inherit !important;
                            display: none;
                            flex-direction: column;
                            height: auto;
                            max-height: inherit;
                            border-top: none;
                            padding: 0;
                            opacity: 1 !important;
                            z-index: 1;

                            .sscateg_wrapper {
                                flex-direction: column;

                                .sscateg_rayon {
                                    margin-bottom: 20px;
                                    max-height: inherit;

                                    li {
                                        padding-left: 15px;
                                    }
                                }

                                & + .sscateg_push,
                                & +.menu_img {
                                    margin-left: 0;
                                }
                            }

                            .sscateg_push {
                                margin: 0 0 10px 0;

                                img {
                                    width: 100%;
                                    height: auto;
                                }
                            }

                            .push_2_and_3_wrapper {
                                display: flex;
                                flex-direction: column;

                                .menu_img + .menu_img {
                                    margin-top: 10px;
                                }
                            }

                            .sscateg_push,
                            .menu_img {
                                display: block;
                                margin-right: 0;
                                font-family: $primaryFont;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: normal;
                                text-decoration-line: underline;
                                text-align: center;

                                a {
                                    display: block;
                                    color: $black;
                                }

                                img {
                                    display: block;
                                    height: auto;
                                    width: 100%;
                                    min-width: 100%;

                                    & + span,
                                    & + a {
                                        display: inline-block;
                                        font-family: $primaryFont;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 300;
                                        line-height: 1;
                                        text-align: center;
                                        margin-top: 10px;
                                    }
                                }

                                &.push_1,
                                &.push_2,
                                &.push_3 {
                                    max-width: calc(100% - 20px);
                                    margin-bottom: 20px;
                                    margin-left: 0;
                                }
                            }
                        }

                        &.actif {
                            .menu_categ_wrapper {

                                .menu_img {
                                    width: 100%;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.in_tunnel {
        #wrapper_top_menu {
            display: none !important;
        }
    }
}
