.newlogin_page {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem;
    height: auto;
    background-image: url('../img/connexion_290424.jpg');
    background-repeat: no-repeat;

    &.bandeau_connexion_style {
        &.vp_connexion {
            background-image: url('../img/connexion_vp_130524.jpg');

            .wrapper_bandeau_connexion {
                display: none !important;
            }

            .title {
                &.my_account {
                    .acc_login_title {
                        display: block;
                    }
                }
            }
        }
    }

    .w-newlogin-form {
        width: 100%;
    }

    .login_wrapper {
        background-color: $white;
        height: auto;
        min-height: 552px;
        width: 582px;
        padding: 50px;

        .login_bloc {
            width: 100%;
            height: initial;
            padding-bottom: 2.5rem;

            &:nth-of-type(2) {
                align-items: flex-start;

                .title {
                    .subtitle {
                        padding-top: 10px;
                    }
                }
            }

            &:first-of-type {
                border-color: #f0f0f0;
            }

            &:last-of-type {
                padding-bottom: 0;
            }

            .title {
                text-align: left;
                margin: 0;

                .subtitle {
                    font-family: $primaryFont;
                    font-size: 16px;
                    color: $black;
                    font-weight: 300;
                    margin: 1rem 0 0.2rem 0;
                }

                h1,
                h2, 
                h3,
                h4 {
                    font-family: $primaryFont;
                    font-size: 16px;
                    color: $black;
                    font-weight: 300;
                    letter-spacing: initial;
                    text-transform: initial;
                }
                
                &.my_account {
                    .subtitle {
                        display: none;
                    }
                }

                h2 {
                    margin: 0;
                }

                .acc_login_title {
                    display: none;
                }
            }

            .w-oneclick-connect {
                width: 100%;
                justify-content: flex-end;
                margin: 10px 0 0 7px;
                flex-direction: row-reverse;
                align-items: center;

                .w-social-btn {
                    background-color: $white;
                    border: 0;
                    margin-left: 0.8rem;

                    &.fb-connect {
                        width: 28px;
                        padding: 0;
                        height: 30px;

                        .fb-oneclick {
                            height: 100%;
                            width: 100%;
                        }
                    }
                }

                #googleConnect {
                    margin: 0;
                    position: relative;
                    left: -0.5rem;
                    width: 40px;
                    height: 40px;

                    #googleConnectLabel {
                        background-color: $white;
                    }
                }
            }

            .login_form_wrapper {
                .w-input-container {
                    .w-input {
                        .w-input-element {
                            border-bottom: 1px solid $black !important;
                            border: initial;
                            border-radius: 0;
                            padding: 0;
                            padding-top: 6px;
                        }

                        .w-input-label {
                            left: 0;
                            font-family: $primaryFont;
                            font-size: 16px;
                            color: rgba(0, 0, 0, 0.5);
                            margin-top: 0.2rem;
                            transform: translateY(-50%);
                        }

                        &.w-focused {
                            .w-input-element {
                                &:focus {
                                    outline: initial;
                                    box-shadow: none;
                                }
                            }

                            .w-input-label {
                                padding-bottom: 0rem;
                            }
                        }

                        &.w-has-error {
                            .w-input-element {
                                box-shadow: none;
                            }
                        }

                        &.w-nonempty {
                            .w-input-label {
                                margin: 0;
                                transform: translateY(-125%);

                                span {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }

                .w-form {
                    &.w-newlogin-form {
                        .w-submit-button {
                            width: 19.5rem;
                            height: 2.8rem;
                        }
                    }
                }

                .w-lines-group {
                    border: 0;
                    padding: 0;
                    margin-top: 1.875rem;
                    margin-bottom: 0;

                    .w-fieldset-label {
                        font-family: $primaryFont;
                        font-size: 16px;
                        color: $black;
                        margin: 0;
                    }

                    .w-form-line {
                        &.optin_container {
                            &.column {
                                text-align: initial;
                                margin-top: 1rem;

                                .w-form-line {
                                    width: 100%;
                                    justify-content: flex-start;

                                    &:first-child {
                                        margin-bottom: 0.5rem;
                                    }

                                    .w-line-label {
                                        font-family: $primaryFont;
                                        color: $black;
                                        font-size: 16px;
                                    }

                                    .w-radio-group {
                                        &.w-has-error {
                                            .w-input-element {
                                                & + .w-input-label {
                                                    &::before {
                                                        border-color: $red;
                                                    }
                                                }
                                            }
                                    	}
                                    }

                                    .w-radio-input {
                                        .w-input-element {
                                            & + .w-input-label {
                                                font-family: $primaryFont;
                                                color: $black;
                                                font-size: 16px;

                                                &::before {
                                                    border: 1px solid $black;
                                                }
                                            }
                                        }
                                    }
                                }

                                .knowMoreOptin {
                                    display: none;
                                }
                            }

                            .w-input-note {
                                &.w-input-error {
                                    text-align: left !important;
                                }
                            }
                        }
                    }
                }

                .w-form-line {
                    margin-bottom: 0;

                    &.email-form-line {
                        margin: 0;
                    }

                    &.pwd-form-line {
                        margin-top: 18px;

                        .w-visibility-toggle {
                            border: 0;
                            background-color: transparent;
                        }
                    }

                    &.pwd-reset-line {
                        order: 4;
                        margin-top: 0.6rem;
                        margin-bottom: 0;

                        .w-pwd-reset {
                            width: 100%;
                            border: 0;
                            color: $black;
                            font-size: 16px;
                            font-family: $primaryFont;

                            span {
                                border-bottom: 1px solid $black;
                                line-height: 1;
                            }
                        }
                    }

                    &.w-submit {
                        max-width: initial;
                        margin: 0;
                        order: 3;
                        margin-top: 1.875rem;    
                    }
                }
            }

            #resetPass {
                .w-form {
                    &.w-lostpass-form {
                        .mdpSentEmail {
                            font-size: 16px;
                            margin-top: 12px;
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .login_protection_donnees_msg {
                display: block !important;
                margin-top: 40px;

                p {
                    font-size: 12px;
                }
            }
        }

        .tunnel_track_pusher {
            display: block;
        }
    }
}

body {
    &.customer {
        .bandeau_container {
            display: none;
        }
    }

    &.en {
        .login_wrapper {
            .w-lines-group {
                .w-form-line {
                    &.optin_container {
                        .w-form-line {
                            &:nth-of-type(1) {
                                .w-line-label {
                                    margin-right: 3rem;
                                }
                            }

                            &:nth-of-type(2) {
                                .w-radio-group {
                                    margin-left: 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // CONNEXION TUNNEL
    &.in_tunnel {
        .main_wrapper {
            .wrapper_tunnel {
                width: 100%;
                display: flex;
                background-repeat: no-repeat;

                .newlogin_page {
                    width: 100%;
                    background-image: none;
                    background-color: $white;
                    display: flex;
                    flex-direction: row;
                    padding: 0;
                    align-items: flex-start;

                    &::before {
                        content: ''; 
                        background-image: url('../img/tunnel_connexion.jpg');
                        max-width: 1065px;
                        height: 84vh;
                        background-size: contain;
                        position: sticky;
                        top: 50px;
                        max-height: 800px;
                        width: 100%;
                        background-repeat: no-repeat;
                    }

                    @media only screen and (max-width: 1350px) {
                        &::before {
                            height: 66vh;
                        }
                    }

                    .login_wrapper {
                        padding: 0 6.7% 0 4%;
                        width: 50%;

                        @media only screen and (max-width: 820px) {
                            padding: 0 5%;
                        }

                        .tunnel_track_pusher {
                            display: block;
                        }

                        .login_bloc {
                            align-items: flex-start;

                            &:nth-of-type(3) {      
                                border-top: 1px solid $grey;                  
                            }

                            .login_form_wrapper {
                                .title {
                                    h3 {
                                        text-align: center;
                                        margin-top: -2px;
                                        padding-bottom: 13px;
                                    }

                                    #subtitleLogin {
                                        margin-bottom: 1px;
                                        white-space: pre-wrap;
                                        
                                        .sub {
                                            display: block;
                                            margin-bottom: 16px;
                                        }

                                        .title {
                                            display: block;
                                            margin-bottom: 19px;
                                        }
                                    }
                                }
                            }

                            .title {
                                .subtitle {
                                    width: 104%;
                                }
                            }

                            .login_protection_donnees_msg {
                                width: 100%;
                                text-align: left;
                            }

                            &:nth-of-type(2) {
                                .title {
                                    .subtitle {
                                        max-width: 464px;
                                    }
                                }
                            }

                            .w-form {
                                .w-lines-group {
                                    .w-fieldset-label {
                                        margin-top: 20px;
                                    }
                                }

                                .intro {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.customer.body_login {
        .wrapper_tunnel {
            .fb-connect {
                display: none;
            }

            .tunnel_track_pusher {
                display: none;
            }
        }
    }
}

.all_wrapper {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 15rem;

    .wrapper_password {
        .bandeau_top_compte {
            .title {
                font-size: 32px;
                font-weight: 700;
            }
        }

        .w-form {
            .w-form-line {
                margin-bottom: 25px;

                .w-input-container {
                    .w-input {
                        .w-input-element {
                            padding: 0;
                            font-family: $primaryFont;
                            font-size: 16px;
                            color: $black;
                            padding-top: 27px;
                            border-bottom: 1px solid $black !important;
                            border: 0;
                            border-radius: 0;  
                        }

                        .w-input-label {
                            font-family: $primaryFont;
                            color: rgba(0, 0, 0, 0.5);
                            padding-top: 27px;
                        }

                        &.w-focused {
                            .w-input-element {
                                &:focus {
                                    outline: initial;
                                    box-shadow: none;
                                    padding: 0;
                                    padding-top: 27px;
                                }
                            }
                        }

                        &.w-has-error {
                            .w-input-element {
                                box-shadow: none;
                            }
                        }

                        .w-input-label {
                            left: 0;
                            font-family: $primaryFont;
                            color: $black;
                            font-size: 16px;
                        }

                        &.w-nonempty {
                            .w-input-label {
                                font-size: 12px;
                                padding-top: 0;
                            }

                            .w-input-element {
                                padding-top: 27px;
                            }
                        }

                        .w-visibility-toggle {
                            border: 0;
                            background-color: transparent;
                        }
                    }
                }
                
                &.w-submit {
                    width: 100% !important;
                    max-width: 18rem;
                    margin-top: 3.4rem !important;
                    margin: 0;
                }
            }
        }
    }
}