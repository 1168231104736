/* Achat express */
.item {
    position: relative;

    .wrap_rolloverproduit {
        margin-top: 13px;
        width: 125px;

        form {
            .ligne_form {
                /* Couleur */
                &.productColorFieldset {
                    .prod_listes {
                        .choices_list {
                            display: flex;
                            justify-content: flex-end;
                            flex-wrap: wrap;
                            padding-right: 2px;
                            height: 22px;
                            overflow: hidden;
                            align-items: center;

                            .form_itm {
                                position: relative;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 16px;
                                height: 16px;
                                margin-left: 6px;

                                &:first-of-type {
                                    margin-left: 0;
                                }

                                [type=radio] {
                                    position: absolute;
                                    left: -3px;
                                    top: -3px;
                                    width: 14px;
                                    height: 14px;
                                    z-index: 1;
                                    opacity: 0;
                                    cursor: pointer;
                                }

                                [type=radio]:not(:checked) + label,
                                [type=radio]:checked + label {
                                    position: relative;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background-position: center;
                                    background-size: cover !important;
                                    width: 12px;
                                    height: 12px;
                                    margin: 0;
                                    transition: all .4s ease-in-out;
                                    border: 1px solid $white;
                                    box-shadow: inherit;
                                    position: relative;
                                    border-radius: 100%;
                                    cursor: pointer;
                                    z-index: 1;

                                    &:before {
                                        content: '';
                                        position: absolute;
                                        top: -3px;
                                        left: -3px;
                                        width: 14px;
                                        height: 14px;
                                        background-color: transparent;
                                        border: 1px solid rgba($black, 0.5);
                                        border-radius: 100%;
                                    }
                                }

                                [type=radio]:checked+label {
                                    &:before {
                                        border: 1px solid $black;
                                    }
                                }

                                &.lonely {
                                    [type=radio] {
                                        cursor: default;

                                        & + label {
                                            cursor: default;
                                        }
                                    }
                                }
                            }

                            .remaining_colors_link {
                                text-decoration: none;
                            }

                            .remaining_colors {
                                margin-left: 6px;
                                font-family: $primaryFont;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: 1;
                                letter-spacing: -2px;
                                text-transform: uppercase;
                                color: $black;
                            }
                        }
                    }
                }

                /* Taille */
                &.productSizeFieldset {
                    position: absolute;
                    top: auto;
                    bottom: 65px;
                    left: 20px;
                    opacity: 0;
                    z-index: 2;
                    transition: opacity ease-in-out 0.5s;

                    .choices_list {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 10px;

                        .form_itm {
                            position: relative;
                            display: inline-block;
                            width: auto;
                            height: 39px;
                            min-width: 39px;
                            text-align: center;
                            padding: 11px 9px 9px 9px;
                            border: 1px solid $black;
                            overflow: hidden;
                            z-index: 1;
                            transition: all ease-in-out 0.35s;

                            [type=radio]:checked,
                            [type=radio]:not(:checked) {
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                width: 100%;
                                height: 100%;
                                z-index: 0;
                                opacity: 0;
                                cursor: pointer;

                                & + label {
                                    position: relative;
                                    text-align: center;
                                    font-family: $primaryFont;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 300;
                                    line-height: normal;
                                    color: $black;
                                    cursor: pointer;
                                    white-space: nowrap;
                                    z-index: 1;

                                    &:before,
                                    &:after{
                                        display: none;
                                    }
                                }
                            }

                            &:has([type=radio]:checked),
                            &:hover {
                                background-color: $black;

                                label {
                                    color: $white !important;
                                }
                            }

                            &:has(.disabled) {
                                border-color: $secondaryColor;

                                &:before {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    left: -10px;
                                    bottom: 0;
                                    height: 1px;
                                    width: calc(100% + 20px);
                                    background-color: $secondaryColor;
                                    transform: rotate(135deg);
                                    margin: auto;
                                    z-index: 1;
                                }

                                [type=radio]:checked,
                                [type=radio]:not(:checked) {
                                    border-color: $secondaryColor;

                                    & + label {
                                        color: $secondaryColor;
                                    }
                                }

                                &:has([type=radio]:checked),
                                &:hover {
                                    background-color: $secondaryColor;

                                    &:before {
                                        background-color: $white;
                                    }

                                    label {
                                        color: $white !important;
                                    }
                                }
                            }
                        }
                    }
                }

                .prod_listes {
                    .titre_choices_list {
                        display: none;
                    }
                }
            }
        }

        .btnAddBasketWrapper {
            &.expressAlertStock {
                position: absolute;
                left: 0;
                top: calc(100% - 3rem);
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                width: 100%;
                background-color: $white;
                padding: 6px 0;
                z-index: 1;

                @media only screen and (max-width: 1212px) {
                    padding: 4px 0;
                }

                .alert_stock {
                    max-width: calc(50% - 5px);

                    @media only screen and (max-width: 1470px) {
                        max-width: calc(40% - 5px);
                    }
                }

                .alert_return {
                    position: absolute;
                    z-index: 10;
                    top: 5px;
                    right: 1px;
                    width: 12px;
                    height: 12px;
                    font-size: 0;
                    cursor: pointer;
                    background-image: url(../svg/spritesheet.svg);
                    @include bgIconCoord(22, 8, 5);
                    opacity: 1;
                    transition: all ease-in-out 0.5s;

                    span {
                        font-size: 0;
                    }
                }

                .bloc_add_alert_form {
                    width: 250px;
                    max-width: calc(50% - 7px);
                    margin-right: 20px;

                    @media only screen and (max-width: 1470px) {
                        width: calc(100% - 142px);
                        max-width: calc(60% - 18px);
                    }

                    .w-form-line {
                        position: relative;
                        width: 100%;

                        .w-input-container {
                            .w-text-input {
                                .w-input-element {
                                    width: 100%;
                                    height: 32px;
                                    font-family: $primaryFont;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 1;
                                    color: $black;
                                    padding: 0;
                                    padding-right: 24px;
                                    border: none;
                                    border-bottom: 1px solid $black;
                                    border-radius: 0;

                                    &:focus {
                                        box-shadow: none;
                                    }
                                    
                                    &:placeholder {
                                        font-family: $primaryFont;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 1;
                                        color: $secondaryColor;
                                    }

                                    &.inputErr {
                                        border-color: $red;
                                    }
                                }
                            }
                        }

                        .form_submit {
                            position: absolute;
                            right: 0;
                            top: 5px;

                            button {
                                height: 22px !important;
                                width: 22px;
                                border: none;

                                &:focus {
                                    background-color: $white;

                                    span {
                                        color: $black;
                                    }
                                }

                                span {
                                    position: absolute;
                                    right: 0;
                                    font-size: 16px;
                                    color: $black;
                                    background-color: $white;
                                    padding-left: 3px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .bloc_add_color {
            display: none !important;
        }
    }

    .productVisualMulti,
    .productVisualMulti_home {
        .swiper-container {
            .swiper-button-next,
            .swiper-button-prev {
                position: absolute;
                top: 0;
                left: 17px;
                bottom: 0;
                right: auto;
                display: block;
                width: 30px;
                height: 30px;
                margin: auto;
                cursor: pointer;
                background: inherit;
                z-index: -1;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    display: block;
                    width: 18px;
                    height: 18px;
                    background-image: url(../svg/spritesheet.svg);
                    @include bgIconCoord(5,8,5);
                    margin: auto;
                    transform: rotate(90deg);
                }

                &.disabled,
                &.swiper-button-lock,
                &.swiper-button-disabled {
                    opacity: 0.5;
                    display: block;
                    pointer-events: auto;
                }
            }

            .swiper-button-next {
                transform: rotate(180deg);
                right: 17px;
                left: auto;
            }
        }

        .swiper-slide,
        & > .block_lnk:not(.swiper-container) {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba($white, 0) 0%, rgba($white, 0.8) 100%);
                z-index: -1;
                opacity: 0;
                transition: opacity ease-in-out 0.5s;
            }
        }

        .wrap_video,
        .has_video {
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: calc((765 / 612) * 100%);
            position: relative;
            background-color: $black;

            iframe {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                background: $black;
                width: 100%;
                height: 100%;
                z-index: 0;
                border: none;
            }

            .block_redirect {
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(180deg, rgba($white, 0) 0%, rgba($white, 0.8) 100%);
                    z-index: -1;
                    opacity: 0;
                    transition: opacity ease-in-out 0.5s;
                }
            }
        }

        & > .block_lnk {
            display: block;

            .no_lazy,
            span.ill_img,
            .imgProd  {
                position: relative;
                display: block;

                img {
                    display: block;
                }

                & + .no_lazy,
                & + span.ill_img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    z-index: -1;
                    transition: opacity 0.35s ease-in-out;
                }
            }

            span.ill_img:not(.imgLoaded) {
                position: relative;
                height: 0;
                padding-bottom: calc((765 / 612) * 100%);
            }
        }

        .bloc_bientot_disponible {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            text-decoration: none;
            color: $black;
            margin: auto;
            z-index: 5;
            cursor: pointer;

            .txt {
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                margin-bottom: 10px;
            }

            .cta {
                display: flex;
                justify-content: center;
                align-items: center;
                width: calc(100% - 1rem);
                max-width: 241px;
                height: 41px;
                background-color: $white;
                border: 1px solid $black;
                transition: all ease-in-out 0.5s;

                &:hover {
                    color: $white;
                    background-color: $black;
                }
            }
        }
    }

    .block_lnk {
        text-decoration: none;
    }

    .wrapper_description {
        display: block;
        width: calc(100% - 125px);
        font-family: $primaryFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        color: $black;
        text-align: left;
        text-decoration: none;
        margin-top: 13px;

        .item_title {
            font-size: 16px;
            font-weight: 300;
            text-transform: capitalize;
        }

        .item_subtitle {
            display: none;
        }

        .item_price {
            display: flex;
        }
    }

    .eclat_top,
    .eclat_rect {
        position: absolute;
        top: 10px;
        left: 10px;
        display: flex;
        flex-direction: column;
        min-width: 78px;
        height: 28px;
        z-index: 2;

        .eclat_rect {
            top: 0;
            left: 0;
            order: -1;
        }

        img {
            position: relative;
            top: 0;
            left: 0;
            display: block;
            height: 100%;
            width: auto;
        }
    }

    .eclat_rond {
        position: absolute;
        top: 10px;
        right: 10px;
        order: -1;
        display: block;
        height: 28px;
        z-index: 2;

        img {
            display: block;
            height: 100%;
            width: auto;
        }
    }

    &.reminder_on {
        .productVisualMulti,
        .productVisualMulti_home {
            .swiper-container {
                .swiper-slide {
                    opacity: 0.5;
                }

                .swiper-button-next,
                .swiper-button-prev {
                    display: none;
                }
            }
        }

        .description_color_size_wrapper {
            .wrap_rolloverproduit {
                .achat_express {
                    .productSizeFieldset {
                        display: none;
                    }
                }
            }
        }

        .expressAlertStock {
            display: none !important;
        }
    }

    &:hover {
        .wrap_rolloverproduit {
            form {
                .rollover_left {
                    .ligne_form {
                        &.productSizeFieldset {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .productVisualMulti,
        .productVisualMulti_home {
            .swiper-container {
                .swiper-slide {
                    &:before {
                        z-index: 2;
                        opacity: 0.8;
                    }
                }

                .swiper-button-next,
                .swiper-button-prev {
                    z-index: 5;
                }
            }

             & > .block_lnk {
                .no_lazy {
                    & + .no_lazy {
                        opacity: 1;
                        z-index: 1;
                    }
                }

                &:not(.swiper-container) {
                    &:before {
                        z-index: 2;
                        opacity: 0.9;
                    }
                }
            }

            .block_redirect {
                &:before {
                    opacity: 1;
                }
            }
        }
    }
}

.description_color_size_wrapper {
    display: flex;
    justify-content: space-between;
}