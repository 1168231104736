//PAGE CGV
.page_satellite {
    width: 100%;
    max-width: 1190px;
    min-width: 998px;
    margin: 0 auto;
    text-align: center;

    #breadcrumbs {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h2 {
        font-size: 32px;
        font-weight: 700;
        margin: 0 40px 0;
    }
    
    .satellite_content {
        text-align: left;
        
        h3,
        .article {
            font-size: 16px;
            font-weight: 300;            
        }

        h3 {
            text-transform: uppercase;
            margin-bottom: 10px;
        }
    
        a {
            color: $black;
        }
    
        ul {
            margin: 2rem 0 0 3rem;
    
            li {
                list-style: inside disc;
                line-height: 1.5;
            }
        }
    }
}

.satellite_breadcrumbs_wrapper {
    #breadcrumbs {
        display: block;
    }
}

.wrapper_content_sat {
    width: 88.5%;
    max-width: 1920px;
    margin: 0 auto;

    h1.title {
        font-size: 32px;
        text-align: center;
        padding-top: 0;
        text-transform: none;
    }

    h2 {
        font-size: 16px;
        font-weight: $light;
        padding: 10px 0;
    }

    h3 {
        text-align: left;
        font-weight: $light;
        font-size: 16px;
        text-decoration: none;
        text-transform: uppercase;
        margin: 39px 0 10px;
    }

    table {
        width: auto !important;
        border-spacing: 0;
        border-collapse: collapse;
        border: none;

        tr > td {
            text-align: center;
            font-size: 12px;
            font-family: $primaryFont;
            padding: 1.05rem 0;
            border: 1px solid $black;

            &:nth-child(3n+1) {
                background-color: $grey;
                width: 8.3rem;
            }

            &:nth-child(3n+2) {
                width: 4.6rem;
            }

            &:nth-child(3n+3) {
                width: 6.1rem;
            }
        }
    }

    &.page_text {
        .content_sat {
            margin: 2.5rem 0 3.2rem;

            #row_bloc_0 {
                h2 {
                    padding-top: 0;
                }
            }

            .row {
                margin-top: 2rem;

                a {
                    color: $black;
                }

                ul {
                    padding-left: 1.5rem;
                }
            }
        }
    }
}