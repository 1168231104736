// Fairly made poppin
@keyframes slideInFairlyMade {
    from {
        right: -666px;
    }
    to {
        right: 0;
    }
}

@keyframes slideOutFairlyMade {
    from {
        right: 0;
    }
    to {
        right: -666px;
    }
}

/* Lightbox */
.lightbox {
    position: fixed;
    background-color: $white;
    transition: opacity ease-in-out 0.5s;
    opacity: 0;
    z-index: -1;

    &.actif {
        display: block;
        z-index: 34;
        opacity: 1;
    }

    .close_pop,
    .closeBtn {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 20px;
        right: 20px;
        font-size: 0;
        background-image: url(../svg/spritesheet.svg);
        @include bgIconCoord(22,8,5);
        border: none;
        cursor: pointer;

        span {
            font-size: 0;
        }
    }

    /* Newsletter */
    &#exit_overlay_OVI {
        width: 480px;
        max-height: 336px;
        height: fit-content;
        transform: inherit;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 50px;
        margin: auto;

        .js-closeLightboxNewsletter {
            &:hover,
            &:focus {
                background-color: $white;
            }
        }

        #formu_inscription_news form.w-adwords-form {
            text-align: center;

            .w-input-note.w-input-error {
                text-align: left;
            }

            .w-form-line { 
                margin-bottom: 1.6rem;

                .w-email-input {
                    margin-top: 0.9rem;

                    &.w-nonempty {
                        .w-input-label {
                            position: absolute;
                            top: 0.7rem;
                        }
                    }
                    
                    input {
                        border: 0;
                        border-radius: 0;
                        border-bottom: 1px solid $black;
                        padding: 0;
                        height: 2.5em;
                        box-shadow: none;
                        font-size: 16px;
                        font-family: $primaryFont;
    
                        +.w-input-label {
                            left: 0;
    
                            span {
                                font-family: $primaryFont;
                                font-size: 16px;
                            }
                        }
                    }
                }

                &:nth-last-of-type(2) {
                    display: none;
                }

                &.w-submit {
                    max-width: 100%;
                }
            }

            .pop_txt_listing {
                margin-top: 20px;

                a {
                    color: $black;
                }
            }
        }

        .pop_contents {
            display: flex;
            justify-content: space-between;
            align-content: center;

            .wrapper_img {
                display: none;
            }

            .wrapper_newspopup_home {
                width: 100%;

                .content_newspopup {
                    .pop_contents.blockpop {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-weight: 300;
                        line-height: normal;

                        .title_popup_inscription_home {
                            margin-bottom: 30px;
                        }
                        
                        div.w-form-line.w-submit{
                            &:hover {
                                background-color: $white;
                                color: $black
                            }
                        }
                    }

                    .pop_txt_listing + .form_submit {
                        margin-top: 1.6rem;
                    }
                }
            }
        }
    }

    &#abox,
    &#modboxpromo {
        position: fixed;
        top: 0%;
        right: 0;
        transform: translateX(100%);
        background: $white;
        width: 550px;
        height: 100vh;
        padding: 50px;
        z-index: 35;
        transition: transform ease-in-out 0.5s;

        .wrapper_informations {
            display: none;
        }

        &.actif {
            transform: translateX(0);
        }

        .box_wishlist,
        &.alertV2 {
            .light_title {
                text-align: center;
                margin-bottom: 40px;
            }

            p {
                margin-bottom: 30px;
            }

            &.actif {
                z-index: 35;
            }
        }

        &.alertV2 {
            opacity: 1;
            display: block;
            z-index: 34;
        }

        .texte {
            margin-bottom: 20px;
        }
    }

    &#sizeguideBox {
        position: fixed;
        top: 0%;
        right: 0;
        transform: translateX(100%);
        background: $white;
        width: 618px;
        height: 100vh;
        padding: 40px 20px 40px 40px;
        text-align: left;
        opacity: 1;
        z-index: 35;
        transition: transform ease-in-out 0.5s;

        .title_sizeguide {
            text-align: center;
            margin-bottom: 40px;
        }

        .sub_title {
            margin-bottom: 30px;
        }

        .guide_feats_wrapper {
            height: auto;

            .featWrapper {
                padding-bottom: 20px;
                border-bottom: 1px solid $black;

                .thumblnk {
                    position: relative;
                    display: block;
                    width: 100%;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    color: $black;
                    padding: 20px 0 0;
                    cursor: pointer;
                    text-decoration: none;

                    &:before {
                        content: '';
                        position: absolute;
                        right: 0;
                        width: 16px;
                        height: 16px;
                        background-image: url(../svg/spritesheet.svg);
                        @include bgIconCoord(21,8,5);
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: -20px;
                        left: 0;
                        display: block;
                        width: 100%;
                        height: 20px;
                    }

                    &.actif {
                        padding-top: 20px;
                        padding-bottom: 0;
                        border-color: $white;

                        &:before {
                            @include bgIconCoord(20,8,5);
                        }
                    }
                }
            }

            .thumbslide {
                display: none;

                .table_title {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    margin-bottom: 10px;
                }

                .table_wrapper {
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                table {
                    position: relative;
                    width: 100%;
                    font-family: $primaryFont;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    text-align: center;
                    border: 1px solid $black;
                    border-top: none;
                    border-bottom: none;
                    border-collapse: collapse;
                    overflow: hidden;

                    &:before {
                        content: '';
                        position: absolute;
                        height: 1000vh;
                        width: 1px;
                        top: 0;
                        left: 0px;
                        background-color: $black;
                        z-index: 2;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        height: 1px;
                        width: 100%;
                        top: 0;
                        left: 0;
                        background-color: $black;
                        z-index: 2;
                    }

                    tbody {
                        position: relative;

                        &:before {
                            content: '';
                            position: absolute;
                            height: 1000vh;
                            width: 1px;
                            top: 0;
                            right: -1px;
                            background-color: $black;
                            z-index: 2;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            height: 1px;
                            width: 100%;
                            top: 0;
                            left: 0;
                            background-color: $black;
                            z-index: 2;
                        }
                    }

                    tr {
                        td {
                            position: relative;
                            width: 57px;
                            height: 50px;
                            line-height: 50px;
                            padding: 18px 0;
                            text-align: center;
                            border: 1px solid $black;

                            span {
                                position: absolute;
                                top: 0;
                                left: 0;
                                display: inline-block;
                                height: 50px;
                                width: 100%;
                                background: transparent;
                                border-bottom: 1px solid $black;
                                z-index: 3;

                                &:before {
                                    content:'';
                                    position: absolute;
                                    top: 0;
                                    left: -1px;
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    background-color: transparent;
                                    border-left: 1px solid $black;
                                    border-right: 1px solid $black;
                                }
                            }

                            &.rowspan_2 {
                                height: 100px;
                                line-height: 100px;

                                span {
                                    height: 100px;
                                }
                            }

                            &:nth-child(1) {
                                &:not(.except) {
                                    width: 134px;
                                    min-width: 134px;
                                    background-color: $white;

                                    span {
                                        background-color: $white;
                                        z-index: 5;
                                    }
                                }
                            }

                            &:hover {
                                background-color: $grey;

                                &:before {
                                    content: '';
                                    position: absolute;
                                    height: 50px;
                                    width: 1280px;
                                    top: 0;
                                    right: 0;
                                    background-color: $grey;
                                }

                                &:after {
                                    content: '';
                                    position: absolute;
                                    height: 100vh;
                                    width: 100%;
                                    bottom: 0;
                                    right: 0;
                                    background-color: $grey;
                                }

                                &:nth-child(1) {
                                    background-color: $white;

                                    span {
                                        background-color: $white;
                                        z-index: 4;
                                    }

                                    &:before,
                                    &:after {
                                        background-color: $white;
                                    }
                                }

                                &.except {
                                    background-color: $grey;

                                    span {
                                        background-color: $grey;
                                    }

                                    &:after {
                                        background-color: $grey !important;
                                    }
                                }
                            }
                        }
                    }

                    &:before {
                        left: -1px;
                    }
                }

                &.actif {
                    padding: 20px 0;
                    border-color: $black;
                }
            }
        }

        .gdt_bottom {
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin-top: 31px;

            .gdt_bottom_title {
                margin-bottom: 20px;
            }

            .gdt_bottom_subtitle {
                margin-bottom: 10px;
            }

            p {
                position: relative;
                padding-left: 20px;

                &:before {
                    content: '•';
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            span {
                display: block;
                margin-bottom: 20px;
            }
        }

        .rollover_gdt_content {
            height: auto;
            max-height: calc(100vh - 200px);
            max-height: calc(100dvh - 200px);
            padding-right: 25px;
            overflow: auto;
        }

        &.actif {
            transform: translateX(0);
            opacity: 1;
        }
    }

    &#pdvBox {
        position: fixed;
        top: 0%;
        right: 0;
        transform: translateX(100%);
        background: $white;
        width: 618px;
        height: 100vh;
        padding: 40px 20px 40px 40px;
        text-align: left;
        opacity: 1;
        z-index: 35;
        transition: transform ease-in-out 0.5s;

        #retailers_map {
            display: none;
        }

        &.deployed {
            transform: translateX(0);
            opacity: 1;
        }
    }

    //commande
    &.popup_numero_retour {
        &.actif {
            display: block;
            z-index: 1000;
            opacity: 1;
            top: 0;
            right: 0;
            padding: 40px 31px 0px 67px;
            height: 100vh;
            width: 34.5rem;
        }

        .lb_retour {
            .texte_num_retour {
                text-align: center;
                margin-bottom: 40px;
            }

            .recommandation {
                margin-bottom: 28px;

                &.reco_bottom {
                    display: none;
                }
            }

            .holder_form {
                .return_products_form {
                    position: relative;
                    width: 100%;
                    height: calc(90vh - 164px);
                    height: calc(90dvh - 164px);
                    margin-bottom: 15px;

                    .holder_wrapper {
                        overflow: hidden;
                        height: 100%;
                        
                        .productReturnListWrapper {
                            display: flex;

                            .imgWrapper {
                                img {
                                    width: 208px;
                                    height: 260px;
                                }
                            }

                            .formWrapper {
                                margin-left: 30px;

                                .price_wrapper {
                                    display: flex;
                                }

                                .holder_subs {
                                    width: 200px;
                                    
                                    .sub_title,
                                    .sub_couleur,
                                    .sub_taille,
                                    .sub_qte {
                                        padding-bottom: 10px;
                                    }
                                }
                            }
                        }

                        .error_retours {
                            display: none;
                            font-size: 14px;
                            color: $red;

                            &.actif {
                                display: block;
                            }
                        }

                        .selectWrapper {
                            display: flex;
                            width: 100%;
                            padding: 10px 0 32px 0;

                            .qty_dropdown {
                                margin-right: 11px;

                                .date_select {
                                    position: relative;
                                    
                                    .return_quantity {
                                        background-color: transparent;
                                        border: 0;
                                        border-bottom: 1px solid $black;
                                        width: 5.3rem;
                                        outline: 0;
                                        font-family: $primaryFont;
                                        color: $black;
                                        font-size: 16px;
                                        appearance: none;
                                        padding-bottom: 10px;
                                    }
                                    
                                    &:before {
                                        content: '';
                                        position: absolute;
                                        right: 2px;
                                        top: 40%;
                                        transform: translateY(-50%);
                                        width: 11px;
                                        height: 11px;
                                        background-image: url(../svg/spritesheet.svg);
                                        @include bgIconCoord(5,8,5);
                                        z-index: -1;
                                    }
                                }
                            }

                            .motif_dropdown {
                                .date_select {
                                    position: relative;

                                    .return_choice {
                                        background-color: transparent;
                                        border: 0;
                                        border-bottom: 1px solid $black;
                                        outline: 0;
                                        font-family: $primaryFont;
                                        color: rgba($black, 0.5);
                                        font-size: 16px;
                                        width: 20.2rem;
                                        appearance: none;
                                        padding-bottom: 10px;
                                    }
                                    
                                    &:before {
                                        content: '';
                                        position: absolute;
                                        right: 2px;
                                        top: 40%;
                                        transform: translateY(-50%);
                                        width: 11px;
                                        height: 11px;
                                        background-image: url(../svg/spritesheet.svg);
                                        @include bgIconCoord(5,8,5);
                                        z-index: -1;
                                    }
                                }
                            }

                            // Return blocked
                            &.none {
                                select {
                                    pointer-events: none !important;
                                    color: $thirdColor !important;
                                }
                            }
                        }
                    }

                    .btn_container {
                        display: block !important;
                        width: 92%;
                        padding: 22px 0;
                        background-color: $white;
                    }
                }
            }
        }

        #printPdf {
            .print-return {
                display: flex;
                flex-direction: column;
                padding-right: 34px;

                .content {
                    margin-bottom: 30px;
                    order: 2;

                    span {
                        .title {
                            display: block;
                            text-align: center;
                            margin-bottom: 36px;
                        }
                    }

                    &:nth-child(2) {
                        text-align: center;
                        order: 1;
                    }
                }

                #btnPrintPdf {
                    width: 100% !important;
                    display: block !important;
                    order: 3;
                }

                .btn_container {
                    margin-top: 10px;
                    order: 4;
                }
            }
        }
    }

    &#availabilityInStore {
        position: fixed;
        top: 0%;
        right: 0;
        transform: translateX(100%);
        background: $white;
        width: 500px;
        height: 100vh;
        height: 100dvh;
        padding: 40px 15px 40px 30px;
        text-align: left;
        opacity: 1;
        z-index: 35;
        transition: transform ease-in-out 0.25s;

        #stepStoreList {
            .availabilityInStoreTitle {
                display: block;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                text-align: center;
                padding-right: 15px;
                margin-bottom: 40px;
            }

            .availabilityInStoreDescription {
                padding-right: 15px;
                margin-bottom: 30px;
            }

            #storeListForm {
                width: calc(100% - 15px);
                margin-bottom: 40px;
                border: none;

                fieldset {
                    padding: 0;
                    border: none;
                }

                .formWrapper {
                    .country_select {
                        display: none;
                    }

                    .field_address {
                        position: relative;

                        #searchAddress {
                            width: 100%;
                            height: 30px;
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 300;
                            color: $black;
                            border: none;
                            border-bottom: 1px solid $black;

                            &::placeholder {
                                font-family: $primaryFont;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: normal;
                                color: $black;
                                opacity: 0.5;
                            }

                            & + button {
                                position: absolute;
                                right: 0;
                                bottom: 10px;
                                display: block;
                                width: 22px;
                                height: 22px;
                                background-color: $white;
                                background-image: url('../svg/spritesheet.svg');
                                @include bgIconCoord(25, 8, 5);
                                padding: 0;
                                border: none;
                                cursor: pointer;

                                span {
                                    font-size: 0;
                                }
                            }
                        }
                    }
                }
            }

            #storeList {
                padding-bottom: 30px;

                #storeListScrollWrapper {
                    max-height: calc(100vh - 280px);
                    max-height: calc(100dvh - 280px);
                    padding-right: 15px;

                    .liste_distributeur_scrollbar {
                        background-color: $white;

                        .elem_liste_distributeur {
                            position: relative;
                            padding-bottom: 30px;
                            margin-bottom: 30px;
                            border-bottom: 1px solid $black;

                            .elem_list_contents {
                                padding-left: 0;
                                list-style-type: none;

                                .store_name {
                                    .store_name_wrapper {
                                        display: block;
                                        margin-bottom: 10px;

                                        a {
                                            color: $black;
                                            text-decoration: none;

                                            .title {
                                                font-family: $primaryFont;
                                                font-size: 17px;
                                                font-weight: 700;
                                                line-height: 1;
                                                margin-bottom: 10px;

                                                .distance {
                                                    display: none;
                                                }
                                            }
                                        }

                                        .storeStock {
                                            position: absolute;
                                            right: 6px;
                                            bottom: 30px;
                                            margin: auto;
                                            font-family: $primaryFont;
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 300;
                                            line-height: normal;

                                            &:before {
                                                content: '';
                                                position: relative;
                                                top: -2px;
                                                display: inline-block;
                                                width: 7px;
                                                height: 7px;
                                                margin-right: 8px;
                                                border-radius: 100%;
                                            }

                                            &[data-status="3"],
                                            &.high {
                                                &:before {
                                                    background-color: $green;
                                                }
                                            }

                                            &[data-status="2"],
                                            &.medium {
                                                &:before {
                                                    background-color: $orange;
                                                }
                                            }

                                            &[data-status="1"],
                                            &.low {
                                                &:before {
                                                    background-color: $red2;
                                                }
                                            }
                                        }
                                    }
                                }

                                .store_details {
                                    a {
                                        color: $black;
                                        text-decoration: none;

                                        .store_address {
                                            font-family: $primaryFont;
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 300;
                                            line-height: normal;
                                            margin-bottom: 6px;

                                            & > span {
                                                display: inline-block;

                                                span:first-of-type {
                                                    display: inline-block !important;
                                                    margin-right: 5px;
                                                }
                                            }
                                        }

                                        &.see_map {
                                            display: none;
                                        }
                                    }

                                    .store_phone {
                                        display: none;
                                    }

                                    .horaire_today,
                                    .store_open_hours {
                                        display: block !important;
                                        font-family: $primaryFont;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 300;
                                        line-height: normal;
                                        margin-bottom: 11px;

                                        .puce {
                                            display: inline-block;
                                            height: 10px;
                                            padding-left: 12px;
                                            position: relative;

                                            &:before {
                                                content: '•';
                                                display: inline-block;
                                                font-family: sans-serif;
                                                font-size: 30px;
                                                font-style: normal;
                                                font-weight: 300;
                                                line-height: 10px;
                                                position: absolute;
                                                left: 0;
                                                top: 1px;
                                            }

                                            &.store_open {
                                                &:before,
                                                + .mag_open_status {
                                                    color: $green;
                                                }
                                            }

                                            &.store_closed {
                                                &:before,
                                                + .mag_open_status {
                                                    color: $red;
                                                }
                                            }
                                        }

                                        .txt_store_closed {
                                            color: $red;
                                        }

                                        .txt_store_open {
                                            color: $green;
                                            position: relative;
                                            padding-left: 13px;

                                            &:before {
                                                display: inline-block;
                                                content: '•';
                                                font-family: sans-serif;
                                                font-size: 30px;
                                                font-style: normal;
                                                font-weight: 300;
                                                line-height: 10px;
                                                position: absolute;
                                                left: 0;
                                                top: 3px;
                                            }
                                        }
                                    }

                                    .link_store_wrapper {
                                        .schedule_wrapper {
                                            .show_schedule {
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 300;
                                                line-height: normal;
                                                text-decoration: underline;
                                                cursor: pointer;
                                            }

                                            .store_week_schedule {
                                                display: none;
                                                padding-top: 20px;

                                                p {
                                                    margin-bottom: 8px;

                                                    &:nth-last-of-type {
                                                        margin-bottom: 0;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.actif {
            transform: translateX(0);
            opacity: 1;
        }
    }

    //precommande
    &#lightbox_preco {
        &.actif {
            right: 0;
            top: 0;
            padding: 38px 31px 31px 29px;
            height: 100vh;
            height: 100dvh;
            width: 500px;
        }

        .close_pop {
            background-color: transparent;
        }

        .texte {
            margin-bottom: 20px;

            a {
                color: $black;
                text-decoration: none;
            }
        }

        .t_lightbox_preco {
            .title {
                display: block;
                text-align: center;
                margin-bottom: 41px;
            }
        }

        .blocPrecoWrapper {
            position: relative;
            
            .bloc_lightbox_preco {
                position: relative;
                padding-left: 38px;
                border-bottom: 1px solid $black;
                padding-top: 28px;
                padding-bottom: 28px;
                cursor: pointer;

                div {
                    span.title {
                        display: block;
                        margin-bottom: 10px;
                    }

                    span.sub {
                        margin-bottom: 7px;
                        display: inline-block;
                    }

                    span.para2 {
                        display: inline-block;
                        margin-top: 14px;
                    }

                    &:before {
                        content: '';
                        width: 15px;
                        height: 15px;
                        border: 1px solid $black;
                        border-radius: 50%;
                        background: $primaryColor;
                        box-shadow: inset 0 0 0 16px $white;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                    }

                    &:hover {
                        border-color: $primaryColor;

                        &:before {
                            box-shadow: inset 0 0 0 2px $white;
                        }
                    }
                }

                &.actif {
                    border-color: $primaryColor;

                    div {
                        &:before {
                            box-shadow: inset 0 0 0 2px $white;
                        }
                    }
                }
            }

            .precoWarning {
                font-size: 12px;
                padding: 30px 0;
            }
        }
    }

    &#selection-offer  {
        order: 4;
        right: 0;
        top: 0;
        height: 100vh;
        height: 100dvh;
        width: 641px;
        padding: 24px 69px 28px 74px;

        &.actif {
            z-index: 35;
        }

        .selection-offer-header {
            .selection-offer-title {
                font-weight: normal;
                font-size: 16px;
            }

            p {
                &:nth-of-type(2) {
                    display: none;
                }
            }
        }

        .selection-offer-content {
            max-height: initial;
            height: 80vh;
            height: 80dvh;
            
            #wrap_lion {
                flex-direction: column;

                .js-form-selection-offer {
                    opacity: 1;
                    min-height: initial;
                    border: initial;
                    margin: initial;
                    display: flex;
                    width: 100%;
                    padding: 0;
                    padding-top: 6px;
                    margin-bottom: 22px;

                    div {
                        &:nth-of-type(1) {
                            img {
                                width: 208px;
                                height: 260px;
                            }
                        }
                    }

                    &:before {
                        display: none;
                    }

                    .wrap_product_info {
                        display: flex;
                        flex-direction: column;
                        margin-left: 29px;

                        .titleOfferedProd {
                            font-size: 16px;
                            font-weight: normal;
                            order: 1;
                            margin: 0;
                            margin-bottom: 5px;

                            span {
                                display: none;
                            }
                        }

                        .priceOfferedProd {
                            display: none;
                        }

                        .size_list {
                            padding: 0;
                            order: 3;

                            input[type=radio]:checked {
                                & + label {
                                    font-weight: normal;
                                    background-color: $black;
                                    color: $white;
                                }
                            }

                            .swiper-slide:has(input[type=radio]:checked) {
                                background-color: $black;
                            }

                            .prevSize,
                            .nextSize {
                                display: none;
                            }
                            
                            label {
                                &:hover {
                                    font-weight: normal;
                                }

                                &:after {
                                    height: 0;
                                }
                            }

                            .swiper-wrapper {
                                .swiper-slide {
                                    padding: 10px;
                                    border: 1px solid;
                                    width: 38px;
                                    height: 37px;
                                    margin-right: 5px;
                                    
                                    &:hover {
                                        background-color: $black;
                                        color: $white;
                                        font-size: 16px;
                                        font-weight: normal;
                                    }
                                }
                            }
                        }

                        .color_list {
                            order: 2;
                            padding: 0;
                            margin-bottom: 6px;

                            .prevColour,
                            .nextColour {
                                display: none;
                            }

                            input[type=radio] {
                                & + label {
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }

                        .quatity_info {
                            display: block!important;
                            order: 4;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }

        .selection-offer-submit  {
            .button {
                width: 100%;
                color: $white;
                background-color: $black;
                text-transform: capitalize;
                cursor: pointer;

                &:hover {
                    color: $black;
                    background-color: $white;
                }

                &:focus {
                    background-color: $black;
                    color: $white;

                    &:hover {
                        color: $black;
                        background-color: $white;
                    }
                }
            }
        }
    }

    /* Send wishlist */
    &#sendwishlistbox {
        position: fixed;
        top: 0%;
        right: 0;
        transform: translateX(100%);
        background: $white;
        width: 500px;
        height: 100vh;
        height: 100dvh;
        padding: 40px 15px 40px 30px;
        text-align: left;
        opacity: 1;
        z-index: 35;
        transition: transform ease-in-out 0.25s;

        .close {
            top: 22px;
            right: 22px;
            font-size: 0;

            span {
                width: 15px;
                height: 15px;
            }
        }

        .w-sendwishlist-form {
            width: 100%;

            #content_sendwishlistbox {
                padding-bottom: 40px;

                #sendfriend_alert_mail div {
                    font-family: $primaryFont!important;
                    color: $primaryColor !important;
                }

                .w-form-line.w-submit {
                    max-width: none;

                    .w-btn-wrapper {
                        button.w-submit-button {
                            border: 1px solid $black;
                        }
                    }
                }
            }
        }

        #sendwishlist {
            .popup_title {
                .title {
                    display: block;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    text-align: center;
                    padding-right: 15px;
                    margin-bottom: 40px;
                }

                .subtitle {
                    display: block;
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    text-align: center;
                    margin-bottom: 20px;
                }
            }

            #wrapper_sendwishlist {
                .form_title {
                    font-family: $primaryFont;
                    color: $black;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    margin-bottom: 7px;
                }

                #sendfriend_form_buttons {
                    margin: 10px auto 0;
                    width: 300px;
                }

                .w-input {
                    .w-input-element {
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        color: $black;
                        height: 40px;
                        border: 0;
                        border-bottom: solid 1px $black;
                        padding: 0;
                        border-radius: 0;
                    }

                    .w-input-label {
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        color: $secondaryColor;
                        left: 0;

                        span {
                            color: $secondaryColor;
                        }
                    }

                    &.w-nonempty {
                        .w-input-element {
                            padding-top: 10px;
                        }

                        .w-input-label {
                            font-size: 12px;
                            line-height: 14px;
                            color: $black;
                            top: 44%;

                            span {
                                font-size: 12px !important;
                            }
                        }
                    }

                    &.w-focused {
                        .w-input-element {
                            box-shadow: none;
                        }
                    }

                    &.w-has-error {
                        .w-input-element {
                            border-bottom: solid 1px $red;
                            box-shadow: none;
                        }
                    }
                }

                #sendfriend_form_to,
                #sendfriend_form_froms {
                    width: 100%;
                    margin: 0 auto;
                    max-width: 100%;
                    margin-top: 20px;
                }

                #sendfriend_form_to {
                    margin-bottom: 34px;

                    .w-form-line:nth-child(2) {
                        margin-bottom: 52px;
                    }

                    .w-form-line:nth-child(3) {
                        margin-bottom: 0;
                    }

                    .w-form-line:nth-child(4) {
                        margin-bottom: 25px;
                    }

                    .w-form-line {
                        .w-input-container {
                            .w-textarea {
                                .w-input-element {
                                    height: 8.5em;
                                    padding-top: 1.75rem;

                                    &:focus {
                                        box-shadow: inherit;
                                    }
                                }

                                .w-input-label {
                                    top: 10px;
                                }

                                &.w-nonempty .w-input-label {
                                    top: 0;
                                }
                            }

                            label.w-input.w-nonempty {
                                .w-input-label {
                                    font-size: 12px;
                                }
                            }
                        }
                    }

                    .w-input-note {
                        font-family: $primaryFont;
                        font-size: 13px;
                        font-weight: 300;
                        color: $primaryColor;
                        margin-top: 9px;

                        &.w-input-error {
                            color: $red;
                        }
                    }

                    #title_destinataire {
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-weight: 300;
                        color: $primaryColor;
                    }

                    .w-input-label {
                        span {
                            color: $secondaryColor;
                        }
                    }
                }

                #sendfriend_form_froms {
                    .w-form-line:nth-child(2) {
                        margin-bottom: 23px;
                    }

                    .w-form-line:nth-child(3) {
                        margin-bottom: 28px;
                    }

                    .w-form-line:nth-child(4) {
                        margin-bottom: 19px;
                    }

                    #recaptcha {
                        max-width: 300px;
                        margin: 0 auto;
                        transform: scale(0.8);
                    }

                    .w-input-label {
                        span {
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-weight: 300;
                            color: $secondaryColor;
                            line-height: 21px;
                        }
                    }

                    #title_de_la_part {
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-weight: 300;
                        color: $primaryColor;
                        line-height: 19px;
                    }

                    .w-checkbox-input {
                        span {
                            color: $secondaryColor;
                        }
                    }

                    .w-input-note {
                        font-family: $primaryFont;
                        font-size: 13px;
                        font-weight: 300;

                        &.w-input-error {
                            color: $red;
                        }
                    }
                }

                #sendfriend_form_buttons {
                    .w-btn-wrapper {
                        .w-submit-button {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 41px;
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                            color: $white;
                            background-color: $primaryColor;
                            border: 1px solid $primaryColor;
                            transition: background ease-in-out .5s, color ease-in-out .5s;
                            cursor: pointer;
                            text-transform: inherit;
                            text-decoration: none;

                            span {
                                font-family: $primaryFont;
                                font-size: 16px;
                                font-weight: 300;
                                line-height: 1;
                                color: $white;
                            }

                            &:hover {
                                background-color: $white;

                                span {
                                    color: $primaryColor;
                                }
                            }
                        }
                    }
                }
            }

            .w-submit {
                .w-submit-button {
                    background-color: $primaryColor;

                    span {
                        font-family: $primaryFont;
                        color: $white;
                    }
                }
            }
        }

        .sendfriend_alert_mail_msg {
            font-family: $primaryFont !important;
            font-weight: 300 !important;
            text-align: center !important;

            & + p {
                text-align: center !important;
            }
        }

        &.actif {
            transform: translateX(0);
            opacity: 1;
        }
    }
}

/* Cookies */
#cookiesLightbox,
#cookiesLightbox.notAccepted {
    left: 0;
    right: 0;
    width: 100%;
    max-width: 1920px;
    padding: 30px 30px 18px;
    margin: auto;

    .cookiesLightbox_container {
        display: grid;
        grid-template-columns: .433fr 3.4fr 1fr;
        grid-template-rows: auto auto 1fr;
    }

    section {
        width: 100%;
        max-width: 1920px;
        font-family: $primaryFont;
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        line-height: normal;
        margin-bottom: 9px;

        &.cookiesLightbox_content {
            grid-column: 1 / span 2;
            grid-row: 1;
            padding-right: 15%;

            h3,
            .cookies_title {
                font-family: $primaryFont;
                font-size: 16px;
                font-weight: 300;
                text-transform: lowercase;
                margin-bottom: 30px;

                &:first-letter {
                    text-transform: capitalize;
                }
            }

            a {
                font-size: 16px;
                color: $black;
                text-underline-offset: 0px;
            }

            .toggleCookiePrefs {
                font-family: $primaryFont;
                font-size: 16px;
                font-weight: 300;
                margin-top: 1rem;

                &:after {
                    display: none;
                }
            }

            &:nth-child(2) {
                text-transform: uppercase;
            }
        }

        a {
            font-size: 16px;
            color: $black;
            text-underline-offset: 0;
        }
    }

    .cookiesLightbox_accept_btn {
        grid-column: 3;
        grid-row: 3;
        bottom: 97px;

        #js_cookie_accept {
            height: 41px;
            max-width: 23.75rem;

            span {
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                text-transform: initial;
            }
        }
    }
    
    .cookiesLightbox_refuse_btn {
        grid-column: 2;
        grid-row: 3;
        margin-left: 0;

         #js_cookie_refuse {
            width: auto;
            color: $black;
            background-color: $white;
            margin-top: 0;
            border: none;
            text-decoration: underline;
            text-underline-position: under;
            text-transform: inherit;

            span {
                font-family: $primaryFont;
                font-size: 16px;
                font-weight: 300;
                color: $black;
                line-height: normal;
            }

            &:after {
                display: none;
            }
        }

        button.w-submit-button.w-loader.loading {
            display: none;
        }
    }

    .cookiesLightbox_preferences {
        grid-column: 1 / span 2;
        grid-row: 2;
        width: 100%;
        padding-right: 15.5%;
        margin-bottom: 0;

        .cookiesInfos {
            padding-left: 0;

            li {
                list-style-type: none;

                span {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-weight: 300;
                    text-transform: uppercase;
                    color: $black;
                }

                .toggleCookieBloc {
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .cookieBlocInfo {
                text-transform: initial;
            }

            h4,
            .h4_style {
                font-family: $primaryFont;
                font-weight: 300;
                font-size: 16px;
                letter-spacing: 0;
                text-transform: initial;
                display: flex;
                align-items: center;

                .icon {
                    display: inline-block !important;
                    margin-left: 0;
                    width: 22px;
                    height: 22px;
                }
            }
        }
    }

    .cookiesLightbox_custom_btn {
        margin: auto;
        grid-column: 1;
        grid-row: 3;
        text-wrap: nowrap;
        margin-right: 1.5rem;

        button.w-submit-button {
            height: auto !important;
            width: auto;
            color: $black;
            background-color: $white;
            margin-top: 0;
            border: none;
            text-decoration: underline;
            text-underline-position: under;
            text-transform: inherit;
        }

        #jsCookieCustom {
            height: auto !important;
            color: $black;
            background-color: $white;
            margin-top: 0;
            border: none;
            text-decoration: underline;
            text-underline-position: under;
            text-transform: inherit;

            span {
                font-family: $primaryFont;
                font-size: 16px;
                font-weight: 300;
                color: $black;
                line-height: normal;
            }

            &:after {
                display: none;
            }
        }
    }

    &.displayCookies {
        display: block!important;
    }

    &.hideCookies {
        display: none!important;
    }

    .w-form-line.w-submit button.w-loader.hideLoader {
        z-index: -1;
        opacity: 0;
    }
}

/* popup mention legales */
#mention_legales_pop_up {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    max-width: 650px;
    height: 180px;
    padding: 2rem;
    text-align: center;

    #content_mention_legales_pop_up {
        .light_title {
            font-family: $primaryFont;
            font-size: 22px;
            font-weight: 700;
        }
    }

    .txt_alert {
        .pop_mention_detail {
            font-family: $primaryFont;
            font-size: 16px;
            font-weight: 300;
            margin-top: 1.5rem;
        }
    }
}

// Fairly made poppin
#popup-fairlymade {
    display: none;
    position: fixed;
    z-index: 1000;
    width: 50%;
    max-width: 666px;
    height: 100%;
    top: 0;
    right: -666px;
    background-color: $bgFairlyMade;
    border: 1px solid $black;
    
    &.actif {
        display: block;
        animation: slideInFairlyMade 1s ease-in-out forwards;
    }

    &.closing {
        display: block;
        animation: slideOutFairlyMade 1s ease-in-out forwards;
    }

    @media screen and (min-width: 1300px) {
        width: 34.7%;
    }

    #popup_fairlymade_wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        #close_fairlymade_wrapper {
            width: 100%;
            height: 4.5rem;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 10%;

            #button_close_fairlymade {
                background-image: url(../svg/spritesheet.svg);
                background-size: 800% auto;
                background-position: 71.42857% 50%;
                width: 14px;
                height: 14px;
                cursor: pointer;
            }
        }

        #fairlymade_header {
            text-align: center;
            padding: 15px 0px 20px 0px;

            #fairlymade_zapa_logo {
                width: 26%;
            }
        }

        .popup-content {
            display: block;
            flex-grow: 1;

            iframe{
                width: 100%;
                height: 100%;
                border: none;
            }
        }
    }
}