.store_locator {
    #breadcrumbs.main_ariane {
        display: block;
        padding: 2.6rem 0rem 1.6rem;

        .breadcrumb.spcovid a {
            text-decoration: underline;
        }
    }

    .wrapper_store {
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;

        .wrapper_retailers_map {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 63px;

            .page-title {
                margin-bottom: 41px;
                font-size: 32px;
                font-weight: $bold;
            }

            .storeloc_form {
                width: 100%;
                margin: 0 auto 40px;
                order: 1;

                .form_itm {
                    max-width: 54%;
                    margin: auto;

                    &.country_select {
                        display: none;
                    }

                    &.zip_input {
                        div {
                            position: relative;

                            #search_cp {
                                box-sizing: border-box;
                                border: none;
                                border-bottom: 1px solid $black;
                                border-radius: 0;
                                padding: 0;
                                height: 29px;
                                width: 100%;
                                font-family: $primaryFont;
                                font-size: 16px;
                                font-weight: $light;
                                line-height: 18px;
                                color: $black;

                                &::placeholder {
                                    font-family: $primaryFont;
                                    font-size: 16px;
                                }

                                &:empty{
                                    + label.placeholder {
                                        display: none;
                                    }
                                }
                            }

                            .placeholder {
                                position: absolute;
                                top: -45%;
                                left: -1px;
                                font-size: 12px;
                                font-weight: $light;
                            }

                            p.ou {
                                display: none;
                            }

                            .magnifying_glass {
                                cursor: pointer;
                                width: 20px;
                                height: 20px;
                                border: none;
                                background-color: $white;
                                position: absolute;
                                right: 1px;
                                top: -1px;
                                padding: 0;
                                width: 22px;
                                height: 22px;
                                background-image: url(../svg/spritesheet.svg);
                                @include bgIconCoord(25,8,5);
                            }

                            #btn_geoloc {
                                display: none;
                            }
                        }
                    }
                }
            }

            #retailers_map {
                width: 64.2%!important;
                order: 3;
                margin-left: auto;

                button {
                    width: inherit !important;
                    border-radius: 0 !important;
                }

                .gm-style-iw {
                    width: 309px;
                    min-height: 131px;
                    border-radius: 0;
                    border: 1px solid $black;
                    padding: 20px 71px 20px 20px;

                    &.gm-style-iw-c {
                        transform: translate(calc(-100% + 25px), calc(-100% + 149px)) !important;
                        display: flex;

                        .view-link a {
                            text-decoration: underline;
                        }

                        .gm-style-iw-chr {
                            position: absolute;

                             &:has(.gm-style-iw-ch:not(:empty)) {
                                position: unset;
                            }
                        }

                        .gm-style-iw-ch {
                            font-size: 16px;
                            font-weight: 700;
                            text-transform: uppercase;
                            font-family: $primaryFont;

                            &:empty {
                                display: none;
    
                                +.gm-ui-hover-effect{
                                    transform: translate(16rem, 10px);
                                }
                            }
                        }

                        .gm-style-iw-d {
                            padding-top: 20px;
                        }
                    }

                    a {
                        color: $black;
                    }

                    .info_popup {
                        text-align: left;
                        font-family: $primaryFont;

                        .name,
                        .adresse {
                            padding-bottom: 10px;
                            line-height: 20px;
                        }
                        
                        .adresse {
                            font-size: 16px;
                        }

                        .horaire_info,
                        .coord {
                            display: none;
                        }

                        .horaire_title {
                            display: none;
                        }

                        .store_localisation_container {
                            display: flex;
                            font-size: 16px;
                            column-gap: 20px;
                            padding-bottom: 10px;
                        }

                        .savoir_plus {
                            font-size: 16px;
                        }

                        .name {
                            font-size: 16px;
                            font-weight: $bold;
                            text-transform: uppercase;

                            .dist {
                                display: none;
                            }

                            a {
                                text-decoration: none;
                                outline: none;
                            }
                        }
                    }
                }

                .gm-style-iw-tc {
                    display: none;
                }
            }

            .img-loader-container {
                display: none !important;

                img {
                    width: 50px;
                    height: auto;
                }
            }

            .section-list-store {
                width: 33.9%;
                height: 727px;
                order: 2;
                padding-left: 6%;
                overflow: hidden;

                .search-result {
                    font-size: 16px;
                    font-weight: $light;
                    padding-bottom: 10px;

                    .search-result-text {
                        display: inline;
                    }

                }

                .element_count + br {
                    display: none;
                }

                #store-container {
                    #list-store {
                        max-height: 725px;
                        width: 100% !important;
                        overflow: hidden;
                        padding: 0;

                        .lb-v-scrollbar {
                            right: 0;
                            width: 1px;
                            color: $grey;

                            .lb-v-scrollbar-slider {
                                height: 153px !important;
                                color: $black;
                            }
                        }

                        .lb-wrap {
                            width: 100%!important;
                            height: 700px!important;
                            overflow-x: hidden !important;
                            scrollbar-width: none;
                            padding-right: 1.2rem !important;
                        }

                        li {
                            list-style-type: none;
                        }

                        .liste_distributeur {
                            .no_result {
                                font-size: 0;
                            }
                        }

                        .os-scrollbar-handle {
                            height: 150px !important;
                        }

                        .elem-list-store {
                            font-family: $primaryFont;
                            padding: 28px 0 16px;
                            border-bottom: 1px solid rgba($black, 0.2);

                            &:first-of-type {
                               padding-top: 0;
                           }

                            &:last-of-type {
                                padding-bottom: 2px;
                                margin-bottom: 0;
                                border-bottom: none;
                            }

                            .nom_store .title {
                                font-size: 18px;
                                padding-bottom: 5px;

                                .distance {
                                    display: none;
                                }
                            }

                            .detail_store {
                                font-size: 16px;
                                line-height: 22px;

                                .ville_store {
                                    display: block;
                                }

                                .pays_store {
                                    display: none;
                                }

                                .horaire_today {
                                    position: relative;
                                    font-size: 16px;
                                    display: inline-block;
                                    padding-left: 17px;
                                    margin: 3px 0 21px;

                                    &:before {
                                        display: inline-block;
                                        content: '•';
                                        left: 0;
                                        top: 5px;
                                        font-family: sans-serif;
                                        font-size: 30px;
                                        font-style: normal;
                                        font-weight: 300;
                                        line-height: 10px;
                                        position: absolute;
                                    }

                                    &.puce_store_open {
                                        &:before,
                                        .open {
                                            color: $green;
                                        }
                                    }

                                    &.puce_store_closed {
                                        color: $red;

                                        &:before,
                                        + .mag_open_status {
                                            color: $red;
                                        }
                                    }


                                    &.puce_store_open {
                                        &:before,
                                        .mag_open_status {
                                            color: $green;
                                        }
                                    }

                                    &.puce_store_closed {
                                        &:before,
                                        .mag_open_status,
                                        span.txt {
                                            color: $red;
                                        }
                                    }
                                }
                            }

                            .tel_store {
                                display: flex;
                                font-size: 16px;
                            }

                            .addfavorite,
                            .horaire_info {
                                display: none;
                            }

                            .store_more {
                                a {
                                    font-size: 16px;
                                    text-decoration: underline;
                                    color: $black;
                                }

                                + a {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.store_locator #retailers_map .gm-style-iw,
.satellite_store_details  .right_bloc .gm-style-iw-a {
    button.gm-ui-hover-effect {
        width: 28px !important;
        height: 36px !important;
        background-image: url(../svg/spritesheet.svg) !important;
        @include bgIconCoord(7,8,5);
        background-size: 800% auto !important;
        background-position: 86.71429% 7px !important;
        opacity: 1;
        
        span {
            display: none!important;
        }
    }
}

.satellite_store_details {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;

    #breadcrumbs.main_ariane {
        padding: 2.6rem 0rem 1.6rem;

        .breadcrumb {
            &.spcovid {
                a {
                    text-decoration: underline;
                }
    
                &::after {
                    display: none;
                }
            }

            &.current {
                display: none;
            }
        }        
    }

    .store_locator {        
        .innerWrapper {
            display: flex;
            width: 100%;
            justify-content: space-between;
            
            .left_bloc { 
                padding-left: 6%;
                width: 41.2%;

                .go_store {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    left: -6px;
                    color: $black;
                    font-size: 16px;
                    margin: 0 0 2rem;
        
                    &::before {
                        content: '';
                        position: relative;
                        display: block;
                        width: 15px;
                        height: 15px;
                        left: 3px;
                        transform: rotate(90deg);
                        background-image: url('../svg/spritesheet.svg');
                        @include bgIconCoord(5, 8, 8);
                        margin-right: 6px;
                    }
                }
                
                .nom_mag {
                    width: 68%;
                    font-size: 14px;
                    padding-bottom: 5px;
                }

                .content_info  {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    border-bottom: 1px solid $black;
                    padding-bottom: 40px;

                    .store_infos {
                        line-height: 21px;
                    }

                    .storeAddress {
                        padding-bottom: 6px;
                    }

                    .store_direction  {
                        width: 229px;
                        text-align: center;
                        font-size: 16px;
                        display: flex;
                        align-content: flex-end;
                        flex-wrap: wrap;
                        justify-content: center;

                        #go_to_store {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            width: 100%;
                            min-width: 11.5rem;
                            height: 41px;
                            margin-bottom: 17px;
                            text-decoration: none;
                        }

                        .btn_send_info {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 11px;
                            background-color: $white;
                            color: $black;
                            border: none;
                            text-decoration: underline;
                        }
                    }

                    .store_details_schedule {
                        display: inline;
                        line-height: 0;
                        padding-top: 16px;
                        margin-bottom: 10px;
                        font-size: 16px;
                        color: $black !important;
                        font-weight: 100;

                        .open,
                        .store_close {
                            &::before {
                                position: relative;
                                top: -1px;
                                margin-right: 5px;
                                display: inline-block;
                                content: '•';
                                font-family: sans-serif;
                                font-size: 30px;
                                vertical-align: middle;
                            }
                        }
                    
                        .open,
                        .open:before {
                            color: $green;
                        }

                        .store_close,
                        .store_close:before {
                            color: $red;
                        }
                    }
                }

                .content_descrip,
                .content_hor {
                    padding: 40px 0;
                    font-size: 16px;
                    font-weight: $light;
                    border-bottom: 1px solid $black;

                    .bloc_title {
                        font-weight: $light;
                        font-size: 16px;
                        padding-bottom: 10px;
                    }
                }
                
                .content_hor  {
                    .time_table {
                        display: flex;
                        flex-direction: column;
                        row-gap: 5px;

                        .date_time {
                            display: flex;
                            justify-content: flex-start;
    
                            .day {
                                width: 97px;
                            }
                        }
                    }

                    .hor_right {
                        .horraire_spe {
                            color: $alert;
                            padding-top: 5px;
                        }
                    }
                }
            }

            .right_bloc {
                width: 55.7%;
                margin-left: auto;

                #store_detail_map {
                    width: 100%;
                    height: 500px;
                }

                .image {
                    height: auto;
                    margin-top: 20px;

                    img {
                        width: 100%;
                    }
                }

                .store_img {
                    height: auto;
                    margin-top: 20px;

                    .swiper-slide img {
                        width: 100%;
                    }
                }

                .gm-style-iw-a {
                    .gm-style-iw-c {
                        width: 309px;
                        min-height: 110px;
                        border-radius: 0;
                        border: 1px solid $black;
                        padding: 20px 40px 0px 20px !important;
                        transform: translate(calc(-100% + 25px), calc(-100% + 149px)) !important;
                    }

                    a {
                        color: $black;
                    }

                    .poi-info-window.gm-style {
                        text-align: left;
                        font-family: $primaryFont;
                        font-size: 16px;

                        .transit-container .gm-title,
                        .address {
                            font-size: 16px;
                            line-height: 20px;
                        }

                        .transit-container {
                            padding-bottom: 2px;

                            .gm-title {
                                font-weight: $bold;
                            }
                        }

                        .view-link {
                            display: none;
                        }

                        .transit-title {
                            font-size: 14px;
                            font-weight: 700;
                            text-transform: uppercase;

                            a {
                                text-decoration: none;
                            }
                        }
                    }
                }

                .gm-style-iw-tc {
                    display: none;
                }
            }
        }
    }

    #ligthbox_send_store_information {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2.5rem;
        width: 500px;
        height: 250px;
        text-align: left;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        display: none;

        .header_lightbox {
            font-family: $primaryFont;
            font-weight: $light;
            padding-bottom: 15px;
        }

        .w-form.w-sendshop-form {
            .w-form-line {
                margin-bottom: 15px;

                &.w-submit {
                    max-width: 100%;
                    padding-top: 15px;
                }
            }
        }

        &.actif {
            display: flex;
        }
    }

    .home-module-body.image-image {
        margin-top: 3.5rem;
    }
}
