/* SITE COLORS */
$white          : #ffffff;
$black          : #000000;

$primaryColor   : #000000;
$secondaryColor : rgba(0,0,0,0.5);
$grey           : #F0F0F0;

$red            : #F00000;
$red2           : #FF0000;
$alert          : $red;
$green          : #04AC6C;
$orange         : #FF9900;

$bgPremium      : #E5E5E5;
$thirdColor     : #CFCFCF;

$bgFairlyMade   : #f0f0f0;