#scroll_items {
    margin-bottom: 40px;

    #list_item {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 0 20px;
        gap: 13px;
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;

        & > .item {
            position: relative;
            max-width: 612px;
            width: 100%;

            &.push {
                display: none;

                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }

            .productVisualMulti {
                display: block;
                position: relative;
                background-color: $grey;
                height: 0;
                padding-bottom: calc((765 / 612) * 100%);

                .no_lazy,
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: auto;
                }

                .no_lazy {
                    & + .no_lazy {
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        z-index: -1;
                        transition: opacity 0.35s ease-in-out;
                    }
                }

                .img,
                .ill_img,
                .imgProd {
                    width: 100%;
                    height: auto;

                    & + .img,
                    & + .ill_img,
                    & + .imgProd {
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        z-index: 1;
                        transition: opacity 0.35s ease-in-out;
                    }
                }

                & > .block_lnk {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    margin: auto;
                    opacity: 0;
                    animation: show_block_lnk 1.5s 0.75s ease-in-out forwards;

                    .swiper-slide {
                        .ill_img {
                            display: block;
                            width: 100%;

                            img {
                                display: block;
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
            }

            &:hover {
                .productVisualMulti {
                    .img,
                    .ill_img,
                    .imgProd {
                        & + .img,
                        & + .ill_img,
                        & + .imgProd {
                            opacity: 1;
                            z-index: 1;
                            transition: opacity 0.35s ease-in-out;
                        }
                    }
                }
            }
        }

        &.bySix,
        &.viewbySix {
            grid-template-columns: repeat(6, 1fr);
            gap: 20px;

            .item {
                max-width: 296px;

                .eclat_top,
                .eclat_rect {
                    display: none;
                }

                &.push {
                    display: block;
                    grid-row: auto / span 2;
                    grid-column: auto / span 2;
                    max-width: 614px;

                    .video_container {
                        display: block;
                        width: 100%;
                        height: 0;
                        padding-bottom: calc((760 / 614) * 100%);
                        position: relative;
                        background-color: $black;

                        iframe {
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background: $black;
                            width: 100%;
                            height: 100%;
                            z-index: 0;
                            border: none;
                        }

                        .block_redirect {
                            display: block;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            z-index: 1;
                        }
                    }
                }

                .description_color_size_wrapper {
                    display: none;
                }

                .productVisualMulti {
                    .swiper-slide:before {
                        display: none;
                    }
                }
            }
        }
    }
}

.btnAutoLoad {
    display: none;
}

.wrap_flitre_pager {
    position: relative;

    .loader_scroll {
        position: absolute;
        left: 0;
        right: 0;
        top: calc(100% - 9px);
        width: 150px;
        height: 9px;
        background-size: 40%;
        margin: 0 auto;

        &.loader {
            background: transparent !important;
            border: 1px solid $black;
            opacity: 1;
            z-index: 2;
            overflow: hidden;

            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 9px;
                position: absolute;
                left: 0;
                bottom: 0;
                top: 0;
                background-color: $black;
            }

            &.loading {
                &:before {
                    animation: scroll_infini_animation .75s infinite;
                }
            }
        }
    }
}

@keyframes scroll_infini_animation {
    0% {
        width: 0;
        left: -20%;
    }

    25% {
        left: 0;
        opacity: 0.5;
    }

    100% {
        left: 100%;
        width: 100%;
        background-color: rgba($black,0.35);
        opacity: 1;
    }
}

/* Filtres */
.main_wrapper_bloc {
    width: 100%;

    .topSearchWrapper {
        width: 100%;
        padding: 0 20px;

        .total_articles {
            text-align: center;
            width: 100%;

            .searchTitle {
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding: 40px 0 20px;
            }

            .nbResults {
                display: none;
            }
        }

        .sub_categ_wrapper {
            position: relative;
            display: flex;
            justify-content: center;
            width: auto;
            max-width: 1024px;
            padding: 0 20px;
            margin: 0 auto;

            #slideCateg {
                display: inline-block;
                width: auto;

                .swiper-slide {
                    position: relative;
                    display: inline-block;
                    width: auto;

                    label {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        padding-right: 50px;
                        transition: all ease-in-out 0.5s;
                    }

                    input {
                        position: absolute;
                        width: 100%;
                        opacity: 0;
                        cursor: pointer;
                    }

                    &:last-of-type {
                        label {
                            padding-right: 0;
                        }
                    }

                    &:hover label,
                    & > [type=checkbox]:checked + label {
                        -webkit-text-stroke-width: 0.75px;
                    }
                }

                &:not(.swiper-initialized) {
                    .swiper-wrapper {
                        justify-content: center;
                    }
                }
            }

            .slide_next,
            .slide_prev {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: auto;
                display: block;
                width: 20px;
                height: 30px;
                margin: auto;
                cursor: pointer;
                background: $white;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    display: block;
                    width: 18px;
                    height: 18px;
                    background-color: $white;
                    background-image: url(../svg/spritesheet.svg);
                    @include bgIconCoord(5,8,5);
                    margin: auto;
                    transform: rotate(90deg);
                }

                &.disabled,
                &.swiper-button-lock,
                &.swiper-button-disabled {
                    opacity: 0;
                    display: block;
                    pointer-events: auto;
                }
            }

            .slide_next {
                transform: rotate(180deg);
                right: 0;
                left: auto;
            }


            &:has(.swiper-initialized) {
                .slide_next,
                .slide_prev {
                    &:not(.swiper-button-disabled) {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

#rayon_sticky_block {
    position: relative;
    display: block;
    width: 100%;

    #filter_sticky {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        column-gap: 70px;
        width: 100%;
        height: 0;
        padding: 20px;
        background-color: $white;
        border-top: 1px solid $black;
        opacity: 0;
        z-index: -1;
        overflow: hidden;
        transition: all ease-in-out 0.5s;

        @media only screen and (max-width: 1440px) {
            column-gap: 30px;
        }

        .filter_wrapper {
            border: none;

            .wrapper_filtre {
                .filter_name {
                    margin-bottom: 20px;
                }

                .selected_options {
                    display: flex;
                    align-items: flex-start;
                    align-content: flex-start;
                    flex-wrap: wrap;
                    width: auto;
                    max-width: 140px;
                    height: 270px;
                    overflow: auto;

                    &.colorOptions {
                        width: auto;
                        max-width: 515px;

                        .form_itm {
                            width: 240px;
                        }

                        @media only screen and (max-width: 1440px) {
                            max-width: 455px;

                            .form_itm {
                                width: 210px;
                            }
                        }
                    }

                    .form_itm {
                        position: relative;
                        display: inline-flex;
                        align-items: center;
                        width: 120px;
                        height: 44px;
                        padding: 14px 20px;
                        border: 1px solid $grey;
                        margin-right: 10px;
                        margin-bottom: 10px;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            width: 0px;
                            height: 100%;
                            background-color: $grey;
                            margin: auto;
                            transition: width ease-in-out 0.5s;
                        }

                        input {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                            z-index: 1;
                            cursor: pointer;
                        }

                        .picto {
                            position: relative;
                            z-index: 1;
                            display: block;
                            width: 12px;
                            height: 12px;
                            border-radius: 100%;
                            margin-right: 10px;
                            border: 1px solid rgba($black, 0.1);
                            cursor: pointer;
                        }

                        label {
                            position: relative;
                            z-index: 1;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                            color: $black;
                            cursor: pointer;
                            text-transform: lowercase;

                            &:first-letter {
                                text-transform: capitalize;
                            }
                        }

                        &:hover {
                            &:before {
                                width: 100%;
                            }
                        }

                        &:has([type=checkbox]:checked) {
                            &:before {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .btn_container_wrapper {
            .form_submit {
                position: absolute;

                &.margBot {
                    top: 20px;
                    right: 20px;

                    button.button {
                        width: 16px;
                        height: 16px;
                        font-size: 0;
                        background-image: url(../svg/spritesheet.svg);
                        @include bgIconCoord(22,8,5);
                        background-color: $white;
                        border: none;
                        cursor: pointer;

                        span {
                            font-size: 0;
                        }
                    }
                }

                &.reinitialiser {
                    bottom: 20px;
                    right: 20px;

                    .button {
                        display: inline-block;
                        height: auto;
                        width: auto;
                        color: $black;
                        background-color: $white;
                        border: none;
                        text-decoration: underline;
                    }
                }
            }
        }

        &.actif {
            opacity: 1;
            height: auto;
            z-index: 32;
        }
    }

    #product_price_order {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 20px 20px 20px 75px;
        background-color: $white;
        border-top: 1px solid $black;
        opacity: 0;
        z-index: -1;
        transition: all ease-in-out 0.5s;

        [type=radio]:checked + label {
            -webkit-text-stroke-width: 0.75px;
        }

        .tri_price {
            display: inline-block;
            width: auto;
            color: $black;
            margin-bottom: 12px;
            text-decoration: none;
            -webkit-text-stroke-width: 0px;
            transition: all ease-in-out 0.5s;

            &:hover,
            &.actif {
                -webkit-text-stroke-width: 0.75px;
            }
        }

        .btn_container_wrapper {
            position: initial;

            .form_submit {
                position: absolute;

                &.margBot {
                    top: 20px;
                    right: 20px;

                    button.button {
                        width: 16px;
                        height: 16px;
                        background-image: url(../svg/spritesheet.svg);
                        @include bgIconCoord(22,8,5);
                        background-color: $white;
                        border: none;
                        cursor: pointer;

                        span {
                            font-size: 0;
                        }
                    }
                }
            }
        }

        .filters_cta {
            position: absolute;
            top: 20px;
            right: 20px;

            .applyFilters {
                button.button {
                    width: 16px;
                    height: 16px;
                    font-size: 0;
                    background-image: url(../svg/spritesheet.svg);
                    @include bgIconCoord(22,8,5);
                    background-color: $white;
                    border: none;
                    cursor: pointer;
                }
            }
        }

        & > div {
            position: relative;
            display: inline-block;
            width: auto;

            input {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }
        }

        &.actif {
            opacity: 1;
            z-index: 32;
        }
    }
}

.top_rayon_wrapper {
    position: relative;
    background-color: $white;

    &.filter_visible {
        z-index: 33;
    }

    .bloc_title {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin-bottom: 1.5rem;
    }

    .bloc_sub_categ {
        .sub_categ {
            .bloc_itm {
                position: relative;
                display: flex;
                justify-content: center;
                width: auto;
                max-width: 1024px;
                padding: 0 20px;
                margin: 0 auto;
                box-sizing: border-box;

                .slideCateg,
                .swiper-container {
                    &:not(.swiper-initialized) {
                        .swiper-wrapper {
                            justify-content: center;

                            .swiper-slide {
                                display: inline-block;
                                width: auto;
                                padding: 0 1rem;
                            }
                        }
                    }

                    .swiper-wrapper {
                        .swiper-slide {
                            display: inline-block;
                            width: auto;
                            padding: 0 1rem;
                            -webkit-text-stroke-width: 0px;
                            transition: all ease-in-out 0.5s;

                            &.active {
                                a {
                                    span {
                                       border-bottom: 1px solid $black;
                                    }
                                }
                            }

                            h1 {
                                font-size: 16px;
                                line-height: normal;
                            }

                            a {
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: normal;
                                color: $black;
                                text-decoration: none;

                                span {
                                    border-bottom: 1px solid $white;
                                }

                                @media only screen and (max-width: 1360px) {
                                    font-size: 15px;
                                }
                            }

                            &:last-of-type {
                                a {
                                    padding-right: 0;
                                }
                            }

                            &:hover {
                                -webkit-text-stroke-width: 0.75px;
                            }
                        }
                    }
                }

                .swiper-button-next,
                .swiper-button-prev {
                    position: absolute;
                    top: 0;
                    left: -30px;
                    bottom: 0;
                    right: auto;
                    display: none;
                    width: 20px;
                    height: 30px;
                    margin: auto;
                    cursor: pointer;
                    background: $white;
                    
                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        display: block;
                        width: 18px;
                        height: 18px;
                        background-color: $white;
                        background-image: url(../svg/spritesheet.svg);
                        @include bgIconCoord(5,8,5);
                        margin: auto;
                        transform: rotate(90deg);
                    }

                    &.disabled,
                    &.swiper-button-lock,
                    &.swiper-button-disabled {
                        opacity: 0;
                        display: block;
                        pointer-events: auto;
                    }
                }

                .swiper-button-next {
                    transform: rotate(180deg);
                    right: -30px;
                    left: auto;
                }

                &:has(.swiper-initialized) {
                    .swiper-button-next,
                    .swiper-button-prev {
                        display: block;
                    }
                }
            }
        }
    }
}

.filters_views_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    .filtre_trier_par {
        display: flex;
        font-family: $primaryFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        color: $black;

        #trigger_filtre {
            line-height: 1;
            margin-right: 20px;
            cursor: pointer;

            span {
                line-height: 1;
                border-bottom: 1px solid transparent;
                transition: border ease-in-out 0.5s;
            }

            .filter_length {
                display: none;
            }

            &.actif {
                span {
                    border-bottom: 1px solid $black;
                }

                .filter_length {
                    display: inline;
                }
            }
        }

        #filter_trier {
            position: relative;
            padding: 0;
            line-height: 1;
            border: none;
            border-bottom: 1px solid transparent;
            cursor: pointer;
            transition: border ease-in-out 0.5s;

            .filter_name {
                &.actif {
                    border-bottom: 1px solid $black;
                }
            }
        }
    }

    .wrap_actions_view {
        display: flex;
        align-items: center;
        gap: 1rem;

        .nb_products {
            display: flex;
            align-items: center;
            gap: 1rem;
            text-transform: capitalize;

            &:after {
                content: '';
                position: relative;
                width: 1px;
                height: 19px;
                background-color: $black;
            }
        }

        #edit_view_changer {
            display: flex;
            align-items: center;
            gap: 0.8rem;
        }

        #viewChanger {
            display: flex;
            gap: 0.8rem;

            span {
                display: inline-block;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 1;
                cursor: pointer;
                border-bottom: 1px solid transparent;
                transition: all ease-in-out 0.5s;

                &.actif {
                    border-bottom: 1px solid $black;
                }
            }
        }
    }
}

/* Sans Resultat */
.no_results {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 70px 20px;

    .search_reminder {
        display: inline-block;
        width: auto;
        margin-bottom: 70px;

        .results {
            display: none;
        }

        .no_results_txt_container {
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin-bottom: 30px;
        }

        .no_results_txt_list {
            .list {
                position: relative;
                font-size: 16px;
                padding-left: 24px;

                &:before {
                    content: '';
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    background-color: $black;
                    border-radius: 100%;
                    top: 4px;
                    left: 8px;
                }
            }
        }
    }

    .home-module-body.push-product-module {
        padding: 0;

        .pushModuleTitle {
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin-bottom: 10px;
        }
    }
}

.nbProdDisplayed {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.loadedGauge {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    height: 9px;
    width: 150px;
    margin: 0 auto;
    background-color: $white;
    border: 1px solid $black;
    margin-bottom: 40px;

    span {
        display: block;
        background-color: $black;
        margin: 0;
    }

    &.loading {
        overflow: hidden;
        background-color: $white;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 9px;
            width: 150px;
            animation: scroll_infini_animation .75s infinite;
        }
    }
}

.loader_scroll_wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: 10px;
    margin: 0 auto;

    .loader_scroll {
        .loader {
            display: block;
            width: 100%;
            height: 10px;
        }
    }
}

/* Pagination */
.pager_wrapper {
    padding-top: 40px;
    text-align: center;

    .see_all_product {
        text-align: center;
    }
}

/* Text SEO */
.textSeo {
    text-align: center;
    display: block;
    width: 100%;

    .textSeo_bloc_title {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 20px;
    }

    .textSeo_bloc_liens {
        list-style-type: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin-bottom: 15px;
        column-gap: 50px;

        li {
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;

            a {
                color: $black;
                text-decoration: none;
                -webkit-text-stroke-width: 0;
                transition: all ease-in-out 0.5s;

                br {
                    display: none;
                }

                &:hover {
                    -webkit-text-stroke-width: 0.75px;
                }
            }
        }
    }

    .textSeo_bloc_description {
        display: block;
        width: 100%;
        max-width: 475px;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin: auto;

        .content_seo_txt {
            text-align: center;
            color: $black;
            overflow: hidden;
            transition: all ease-in-out 0.5s;

            &.full_text {
                height: 0;
            }
        }

        .btn_see_text {
            display: inline-block;
            width: auto;
            height: auto;
            padding: 0;
            margin-top: 20px;
            border: none;

            span {
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                color: $black;
                line-height: normal;
                text-decoration-line: underline;

                &.full_text {
                    display: none;
                }
            }

            &:hover,
            &:focus {
                background-color: transparent;
            }
        }

        &.see_more {
            .content_seo_txt {
                &.small_text {
                    height: 0;
                }

                &.full_text {
                    height: auto;
                }
            }

            .btn_see_text {
                span {
                    &.small_text {
                        display: none;
                    }

                    &.full_text {
                        display: block;
                    }
                }
            }
        }
    }
}

@keyframes show_block_lnk {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}