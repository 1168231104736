.home-module-body {
    margin-bottom: 1.2rem;
    padding: 0 1.2rem 0;

    /* SIGNUP MODULE */
    &.images-signup-module {
        display: flex;
        justify-content: center;
        margin: 40px 0;

        .home-columns-container {
            width: 644px;
            border: 1px solid #000;
            padding: 50px;
    
            .home-column {
                font-size: $primaryFont;
    
                .home-module-title {
                    text-align: center;
                    padding-bottom: 40px;
                    font-weight: $light;
                }
    
                .home-module-text > div {
                    margin-bottom: 9px;

                    div > div {
                        margin-bottom: 20px;
                    }
                }
    
                .w-form.w-newsletter-form {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .w-form-line {

                        &:first-of-type{ 
                            margin-bottom: 1.79rem;
                        }
                        
                        .w-input-container {
                            .w-email-input {
                                margin-top: 0.5rem;

                                input {
                                    border: none;
                                    border-bottom: 1px solid $black;
                                    border-radius: 0;
                                    padding: 0;
                                    color: $black;
                                    font-family: $primaryFont;
                                    font-size: 16px;
                                }

                                &.w-nonempty .w-input-label {
                                    top: 28%;
                                    font-size: 13px;
                                }
                                
                                .w-input-label {
                                    left: 0;
                                    top: 50%;
                                    font-size: 16px;

                                    span {
                                        font-family: $primaryFont;
                                    }
                                }

                                .w-input-element {
                                    height: 2.2em;

                                    &:focus {
                                        box-shadow: none;
                                    }
                                }

                                &.w-has-error:not(.w-dropdown) input[type=email].w-input-element {
                                    box-shadow: none;
                                }
                            }

                        }

                        &.w-submit {
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 41px;
                            width: 234px;
                            font-size: 16px;
                            cursor: pointer;
    
                            .w-submit-button {
                                width: 234px;
                                height: 41px;
    
                                span {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 100%;
                                    height: 100%;

                                    &:hover {
                                        background-color: $white !important;
                                        color: $black !important;
                                        transition: all ease-in-out .5s;
                                    }
                                }

                            }
                        }
                    } 
                }
            }
        }
    }

    /* 2 IMAGES FULL */
    &.image-image {
        width: 100%;

        .home-columns-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            column-gap: 1.2rem;
            
            .home-column {
                width: 49.5%;
                position: relative;

                .home-module-contents {
                    max-width: 58.125rem;
                    max-height: 58.125rem;

                    img {
                        width: 100%;
                        height: auto;
                    }

                    &:last-child {
                        position: absolute;
                        top: calc(100% - 52.5%);
                        left: 0;
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .home-module-cta {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 231px;
                            height: 41px;

                            &:hover {
                                background-color: $white !important;
                                border: 1px solid $black;
                                color: $black !important;
                                transition: all ease-in-out .5s;
                            }
                        }
                    }

                    .slide_video {
                        display: flex;
                        position: relative;
                        height: 0;
                        width: 100%;
                        padding-bottom: 100%;

                        iframe {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border: none;
                        }
                    }
                }
            }
        }
    }

    /* COVER MODULE */
    &.cover-module {
        width: 100%;
        padding: 0;

        .home-column {
            .container_video {
                display: block;
                width: 100%;
                height: 100%;
            }
            .slide_video {
                display: flex;
                position: relative;
                height: 0;
                width: 100%;
                padding-bottom: calc((980 / 1920)* 100%);
                
                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: none;
                    max-width: 1920px;
                    max-height: 980px;
                }

                .container_video:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }
            }

            img {
                width: 100%;
                height: auto;
            }

            .module-contents_wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                position: relative;
                height: 0;
                padding-bottom: calc((980 / 1920)* 100%);
    
            }

            .home-module-contents {
                position: absolute;
                bottom: 23%;
                left: 0;
                width: 100%;
                height: auto;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                padding-bottom: 0;
                column-gap: 4.8rem;

                a {
                    cursor: pointer;
                }
    
                .home-module-title {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid $black;
                    width: 231px;
                    height: 41px;
                    cursor: pointer;
                    text-decoration: none;
    
                    span {
                        font-size: 16px;
                    }

                    &:hover {
                        background-color: $black !important;
                        color: $white !important;
                        transition: all ease-in-out .5s;
                    }
                }
            }
        }

        #cover_module_swiper {
            opacity: 0;
            animation: show_cover 1s 0.75s ease-in-out forwards;
        }

        .swiper-pagination {
            bottom: 2.37rem;

            .swiper-pagination-bullet {
                width: 11px;
                height: 11px;
                background-color: $white;
                transition: all 0.5s ease-out;
                opacity: 1;

                &.swiper-pagination-bullet-active {
                    background-color: $white;
                    width: 26px;
                    height: 11px;
                    border-radius: 20px 20px;
                    transition-duration: 500ms;
                }
            }
        }
    }

    /* 1 FULL IMAGE */
    &.image-module {
        width: 100%;

        .home-column {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            position: relative;
            height: 0;
            padding-bottom: calc((931 / 1880)* 100%);

            .home-module-contents {
                position: absolute;
                max-width: 1880px;
                max-height: 931px;
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                }
            
                &:last-of-type {
                    position: absolute;
                    bottom: calc(100% - 52.5%);
                    left: 0;
                    width: 100%;
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    column-gap: 4.8rem;
                    

                    a {
                        height: 100%;
                        width: 100%;
                    }

                    .home-module-cta {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid $black;
                        width: 231px;
                        height: 41px;

                        span {
                            font-size: 16px;
                            cursor: pointer;
                        }

                        &:hover {
                            background-color: $white!important;
                            border: 1px solid $black;
                            color: $black !important;
                            transition: all ease-in-out .5s;
                        }
                    }
                }
            }

            .slide_video {
                display: flex;
                position: relative;
                height: 0;
                padding-bottom: calc((931 / 1880)* 100%);
                width: 100%;
                
                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    border: none;
                    width: 100%;
                }
            }

            img {
                width: auto;
                height: 100%;
            }
        }
    }

    /* TG MODULE */
    &.push-product-module {
        width: 100%;

        .pushModuleTitle,
        .pushModuleSubtitle {
            &:empty {
                display: none;
            }
        }

        .pushModuleTitle {
            font-size: 32px;
            font-weight: $bold;
        }
        
        .pushModuleSubtitle {
            font-size: 16px;
            padding: 1.2rem 0;
        }

        .pushProductHome.swiper-container {
            padding-bottom: 35px;
        }

        .swiper-wrapper {
            .item.gondole {
                width: calc((100% / 4) - 14px) !important;
                max-width: 465px;
                margin-right: 19px;

                .productVisualMulti_home {
                    max-width: 456px;
                    max-height: 570px;
                }

                .productVisualMulti.tg-module,
                .productVisualMulti_home.tg-module {
                    .block_lnk.swiper-container {
                        .ill_img {
                            width: 100%;
                            height: auto;
                            display: block;
        
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }

                .description_color_size_wrapper {
                    .item_title h3 {
                        font-size: 16px;
                    }

                    .wrap_rolloverproduit {
                        margin-top: 9px;
                        width: 125px;

                        .productSizeFieldset {
                            bottom: 4.5rem;
                            top: auto;
                            left: 34px;
                            
                            .choices_list {
                                flex-wrap: wrap;
                                row-gap: 10px;
                            }
                        }
                    }
                }

                @media only screen and (max-width: 1440px) {
                    width: calc((100% / 3) - 12.5px) !important;
                }
            }
        }
        
        .swiper-pagination {
            display: flex;
            justify-content: center;
            column-gap: 7px;

            .swiper-pagination-bullet {
                width: 8px;
                height: 8px;
                background-color: $thirdColor ;
                opacity: 1;

                &.swiper-pagination-bullet-active {
                    background-color: $black;
                }
            }
        }
    }
}

body.homepage  {
    #copyright {
        margin-top: 47px;
    }
}

@keyframes show_cover {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}