body.cms_page_new {
    overflow-x: hidden;

    .cms_page {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 1024px;
    
        #cms_page_ariane #breadcrumbs {
            padding: 2.49rem 0 1.7rem;

            .spcovid {
                text-decoration: underline;
                pointer-events: none;

                &::after {
                    display: none;
                }
            }
    
            .current {
                display: none;
            }
        }
    
        #cms_page_btn_back {
            display: none;
        }
    
        #cms_page_title h1 {
            font-size:  2rem;
            padding-top: 30px;
            padding-bottom: 40px;
            text-transform: capitalize;
        }

        // If ariane is present, no need for padding top
        #cms_page_ariane ~ #cms_page_title h1 {
            padding-top: 0;
        }
    
        .cms_page_content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            row-gap: 50px;
            width: 100%;
    
            .cms-page-module {
                width: 65%;
    
                @media screen and(max-width: 1400px) {
                    width: 70%;
                }
    
                @media screen and(max-width: 1270px) {
                    width: 75%;
                }
            }
        }
    }
}

.cms-page-module {
    &.text-image-1 {
        display: flex;
        width: 65%;
        
        @media screen and(max-width: 1400px) {
            width: 70%;
        }

        @media screen and(max-width: 1270px) {
            width: 75%;
        }

        .left_part {
            width: 50%;
            max-width: 39rem;

            a {
                display: block;
                height: 100%;

                img {
                    width: 100%;
                }
            }

            .slide_video {
                display: flex;
                position: relative;
                height: 0;
                width: 100%;
                padding-bottom: 88.638%;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: none;
                }
            }
        }

        .right_part {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 1.5rem 0 50px;

            .home-column-title {
                font-size:  2rem;
                font-weight: $bold;
                padding-bottom: 20px;
            }

            .home-column-subtitle {
                padding-bottom: 20px;
                font-size: 16px;
            }

            .home-column-txt {
                h1 {
                    font-size: 2em;
                }

                h2 {
                    font-size: 1.5em;
                }

                h3 {
                    font-size: 1.17em;
                }
            }

            .content_2 {
                margin-top: 30px;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 270px;
                    height: 41px;
                    text-decoration: none;

                        span {
                        font-size: 1rem;
                    }

                    &:hover {
                        background-color: $white !important;
                        color: $black !important;
                        transition: all ease-in-out .5s;
                        border: 1px solid $black;
    
                        span {
                            color: $black !important;
                        }
                    }
                }
            }
        }
    }

    &.image-text-1 {
        display: flex;
        width: 65%;
        
        @media screen and(max-width: 1400px) {
            width: 70%;
        }

        @media screen and(max-width: 1270px) {
            width: 75%;
        }


        .right_part_inverse {
            width: 50%;
            max-width: 39rem;

            a {
                display: block;
                height: 100%;
                width: 100%;

                img {
                    width: 100%;
                }
            }

            .slide_video {
                display: flex;
                position: relative;
                height: 0;
                width: 100%;
                padding-bottom: 88.638%;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: none;
                }
            }
        }

        .left_part_inverse {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 50px 0 0.4rem;

            .home-column-title {
                font-size:  2rem;
                font-weight: $bold;
                padding-bottom: 20px;
            }

            .home-column-subtitle {
                padding-bottom: 20px;
                font-size: 16px;
            }
            
            .home-column-txt {
                h1 {
                    font-size: 2em;
                }

                h2 {
                    font-size: 1.5em;
                }

                h3 {
                    font-size: 1.17em;
                }
            }

            .content_2 {
                margin-top: 30px;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 270px;
                    height: 41px;
                    text-decoration: none;

                        span {
                        font-size: 1rem;
                    }

                    &:hover {
                        background-color: $white !important;
                        color: $black !important;
                        transition: all ease-in-out .5s;
                        border: 1px solid $black;
    
                        span {
                            color: $black !important;
                        }
                    }
                }
            }

            .slide_video {
                display: flex;
                position: relative;
                height: 0;
                width: 100%;
                padding-bottom: 88.638%;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: none;
                }
            }
        }
    }
}

/* 2 IMAGES FULL */
.cms-page-module-body.two-images-text {
    width: 100%;
    margin: 10px 1.2rem 0;

    .cms-page-columns-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 1.2rem;
        
        .cms-page-column {
            width: 49.5%;
            position: relative;

            .picture-container {
                max-width: 59.125rem;
                max-height: 59.125rem;

                img {
                    width: 100%;
                    height: auto;
                }

                .cms-page-module-cta {
                    position: absolute;
                    top: calc(100% - 52.5%);
                    left: 0;
                    width: 100%;
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 231px;
                        height: 41px;
                        text-decoration: none;

                        &:hover {
                            background-color: $white !important;
                            border: 1px solid $black;
                            color: $black !important;
                            transition: all ease-in-out .5s;
                        }
                    }
                }

                .slide_video {
                    display: flex;
                    position: relative;
                    height: 0;
                    width: 100%;
                    padding-bottom: 100%;

                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border: none;
                    }
                }

                .cms-page-module-text {
                    padding-top: 20px;
                }
            }
        }
    }
}