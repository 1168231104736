/* Plan du site */
body.footer_sitemap {
    .page_satellite {
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;

        .siteMap {
            width: 66.8%;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            row-gap: 4rem;
            column-gap: 3.5%;
            justify-content: flex-start;
            margin-top: 2.5rem;
            margin-bottom: 3.8rem;
            
            @media screen 
            and (min-device-width: 768px) 
            and (max-device-width: 1024px)
            and (max-width:1050px) 
            and (orientation: portrait) {
                width: 70%;
                column-gap: 15px;
            }

            .sitemap_section {
                &:empty {
                    display: none;
                }

                h3 {
                    font-size: 16px;
                    color: $primaryColor;
                    font-family: $primaryFont;
                    text-transform: capitalize;
                    margin-bottom: 1.2rem;
                    text-decoration: underline;
                }

                .article {
                    margin-bottom: 0.62rem;

                    .plan_titre_subcateg {
                        color: $black;
                        font-weight: $light;
                        text-decoration: none;
                    }
                }

                &.column {
                    .two_columns {
                        display: grid;
                        grid-template-columns: auto auto;
                        grid-template-rows: repeat(8, 1fr);
                        column-gap: 25px;
                        grid-auto-flow: column;
                    }
                }
            }
        }
    }
}

// contact faq
.wrapperContentFaqForm {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

    .block_img {
        img {
            width: 100%;
        }
    }

    #contact_form {
        margin: 0rem 3.5rem 0rem 6.5rem;
        width: 39.1%;
        min-width: 365px;

        .titre_contact {
            font-weight: 700;
            font-family: $primaryFont;
            color: $black;
            font-size: 32px;
        }

        .wrapperErrors {
            color: $red;
        }

        #message_mail {
            margin: 1rem 0 1rem 0;
            
            span {
                color: $green;
            }
        }

        #form_contact1 {
            margin-top: 1rem;  
            display: flex;
            flex-direction: column;

            #divContactNumCommande {
                order: 3;
            }

            .file-input-row {
                order: 6;
                margin: 0;

                label {
                    border: 0 !important;
                    text-decoration: underline;
                    justify-content: flex-start;
                    text-transform: none;
                    color: $black;
                }
            }

            .w-form-line {
                margin-bottom: 1.25rem;

                &:nth-of-type(1) {
                    order: 1;
                }

                &:nth-of-type(2) {
                    order: 3;
                }

                &:nth-of-type(3) {
                    order: 2;
                }

                &:nth-of-type(4) {
                    order: 4;
                }

                &:nth-of-type(5) {
                    order: 5;
                }

                &:nth-of-type(6) {
                    order: 6;

                    .w-input-container {
                        .w-input {
                            .w-input-label {
                                padding: 0;
                            }
                        }
                    }
                }
                
                &.w-has-tel {    
                    span.errormsgadd {
                        display: none;
                    }

                    .w-input {
                        .w-input-label {
                            font-family: $primaryFont;
                            color: rgba(0, 0, 0, 0.5);
                            font-size: 16px;
                        }

                        .selected-flag  {
                            border: 0;
                            padding: 0;
                            background-color: transparent;
                            top: 61%;

                            &:after {
                                border: solid $black;
                                border-width: 0 1px 1px 0;
                                display: inline-block;
                                padding: 2px;
                                transform: rotate(45deg);
                                -webkit-transform: rotate(45deg);
                                margin-left: 10px;
                            }
                        }

                        &.w-has-error {
                            &:not(.w-dropdown) {
                                input[type=text].w-input-element {
                                    border-color: $black;
                                    box-shadow: initial;
                                }
                            }
                        }

                        &:not(.w-dropdown) {
                            .w-input-element {
                                &:not([type=radio]):not([type=checkbox]):focus {
                                    box-shadow: initial;
                                    padding-top: 17.5px;
                                }
                            }
                        }
                    }
                }

                .w-input-container {
                    .w-input-element {
                        border-bottom: 1px solid $black !important;
                        border: 0;
                        border-radius: 0;

                        :focus {
                            box-shadow: initial;
                        }
                    }

                    .w-input {
                        &.w-dropdown {
                            border: 0;
                            border-bottom: 1px solid $black !important;
                            border-radius: 0;

                            &.w-focused {
                                box-shadow: initial;
                            }

                            .w-input-label {
                                left: 0;
                                font-size: 16px;
                                font-family: $primaryFont;
                                color: rgba(0, 0, 0, 0.5);
                            }

                            &.w-has-error {
                                border-color: initial;
                                box-shadow: initial;
                            }

                            &.w-nonempty {
                                .w-value {
                                    padding: 1.14286em 0 0;
                                    font-family: $primaryFont;
                                    color: $black;
                                    font-size: 16px;
                                }
                            }
                        }

                        &:not(.w-dropdown) {
                            input[type=text].w-input-element:focus,
                            input[type=email].w-input-element:focus,
                            textarea.w-input-element:focus {
                                box-shadow: initial;
                                border: 0;
                                padding-top: 17.5px;
                            }
                        }

                        &.w-has-error {
                            &:not(.w-dropdown) {
                                .w-input-element, input[type=text].w-input-element {
                                    border-color: $black;
                                    box-shadow: initial;
                                    padding-top: 17.5px;
                                }
                            }
                        }

                        &.w-text-input,
                        &.w-email-input,
                        &.w-textarea {
                            .w-input-label {
                                left: 0;
                                font-size: 16px;
                                font-family: $primaryFont;
                                color: rgba(0, 0, 0, 0.5);
                            }

                            .w-input-element {
                                padding: 0;
                                padding-top: 17.5px;
                            }
                        }

                        &.w-textarea {   
                            &.w-nonempty {
                                .w-input-label {
                                    top: -5px;
                                }
                            }

                            .w-input-element {
                                height: 6.9rem;
                            }

                            .w-input-label {
                                top: -8px;
                            }
                        }

                        &.w-nonempty {
                            .w-input-label {
                                font-size: 12px;
                                line-height: 0.8;
                            }

                            .w-input-element {
                                padding-top: 17.5px;
                            }
                        }

                        .w-input-label {
                            padding-top: 17px;
                        }

                        .w-text-input {
                            .w-input-label {
                                padding-top: 17px;
                            }
                        }
                    }
                }

                &.w-submit {
                    max-width: 17.5rem;
                    order: 26
                }
            }

            .file-input-row {
                order: 6;

                .w-form-line {
                    margin-bottom: 0;
                }
            }

            .file-list {
                order: 6;

                .rowList {
                    .closePj {
                        border: 0;
                    }
                }
            }

            .champ-requis {
                display: none;
            }

            .w-submit-button {
                text-transform: capitalize;
                height: 2.7rem;
            }

            #recaptcha {
                order: 25;
                margin: 0;
                transform: scale(0.925);
                transform-origin: 0 0;
                margin-top: 1.25rem;
                margin-bottom: 0;
            }
        }

        .champ-requis {
            display: none;
        }
    }
}