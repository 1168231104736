footer#copyright {
    margin-top: 70px;

    .reinsurance {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 35px;
        border-bottom: 1px solid rgba($black, 0.2);

        .rea_footer {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            color: $black;
            text-decoration: none;

            .bgSprite {
                &:before {
                    content: '';
                    display: block;
                    width: 16px;
                    height: 16px;
                    background-image: url(../svg/spritesheet.svg);
                    margin-right: 18px;
                }

                &:after {
                    content: '';
                    display: block;
                    width: 1px;
                    height: 22px;
                    background-color: rgba($black, 0.2);
                    margin: 0 60px;
                }
            }

            &.footer_contact {
                .bgSprite {
                    &:before {
                        @include bgIconCoord(17,8,5);
                    }
                }
            }

            &.footer_faq {
                .bgSprite {
                    &:before {
                        width: 18px;
                        height: 18px;
                        @include bgIconCoord(18,8,5);
                    }
                }
            }

            &.footer_paiement {
                .bgSprite {
                    &:before {
                        @include bgIconCoord(19,8,5);
                    }
                }
            }

            &.footer_second_hand {
                .bgSprite {
                    &:before {
                        width: 22px;
                        height: 22px;
                        @include bgIconCoord(34,8,5);
                    }
                }
            }

            &:last-child .bgSprite::after {
                display: none;
            }
        }
    }

    .footer_container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;
        padding: 36px 30px;

        .wrapperSeo {
            .footer_content {
                display: inline-flex;
                width: auto;

                .col {
                    display: inline-block;
                    flex-direction: column;
                    width: auto;
                    margin-right: 100px;

                    @media only screen and (max-width: 1560px) {
                        margin-right: 80px;
                    }

                    @media only screen and (max-width: 1360px) {
                        margin-right: 60px;
                    }

                     @media only screen and (max-width: 1280px) {
                        margin-right: 35px;
                     }

                    @media only screen and (max-width: 1041px) {
                        margin-right: 100px;
                    }

                    .col_title {
                        display: inline-block;
                        width: auto;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 1;
                        margin-bottom: 18px;
                        text-decoration: none;
                        border-bottom: 1px solid $black;
                    }

                    .col_content {
                        display: flex;
                        flex-direction: column;
                        row-gap: 0.5rem;

                        .col_links {
                            font-family: $primaryFont;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                            color: $black;
                            text-decoration: none;
                            transition: all ease-in-out 0.5s;
                        }
                    }
                }
            }

            @media only screen and (max-width: 1041px) {
                width: 100%;
                margin-bottom: 40px;
            }
        }

        .evaluation {
            display: block;
            width: 215px;
            margin-right: 13.3vw;

            @media only screen and (max-width: 1560px) {
                margin-right: 7vw;
            }

            @media only screen and (max-width: 1457px) {
                margin-right: 7.29vw;
            }

            @media only screen and (max-width: 1360px) {
                margin-right: 3.5vw;
            }

            @media only screen and (max-width: 1280px) {
                margin-right: 2.5vw;
            }

            @media only screen and (max-width: 1041px) {
                margin-right: 96px;
            }

            .media_title {
                display: inline-block;
                width: auto;
                font-family: $primaryFont;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 1;
                border-bottom: 1px solid $black;
                margin-bottom: 18px;
            }

            a {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                font-family: $primaryFont;
                font-size: 15px;
                font-style: normal;
                font-weight: 300;
                color: $black;
                text-decoration: none;

                .rating_stars {
                    display: inline-flex;
                    justify-content: space-between;
                    width: auto;
                    height: 24px;
                    margin-right: 6px;

                    .star {
                        position: relative;
                        display: block;
                        width: 20px;
                        height: 20px;
                        background-image: url(../svg/spritesheet.svg);
                        @include bgIconCoord(26,8,5);
                        opacity: 1;
                    }

                    &.one {
                        .star:nth-child(n+2) {
                            opacity: 0.2;
                        }
                    }

                    &.two {
                        .star:nth-child(n+3) {
                            opacity: 0.2;
                        }
                    }

                    &.three {
                        .star:nth-child(n+4) {
                            opacity: 0.2;
                        }
                    }

                    &.four {
                        .star:nth-child(n+5) {
                            opacity: 0.2;
                        }
                    }
                }

                .nombre_evaluations  {
                    width: 100%;
                }

                ul {
                    display: block;
                    width: 100%;
                    padding: 0;
                    margin-top: 6px;
                    list-style-type: none;

                    li {
                        font-family: $primaryFont;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        color: $black;

                        span {
                            &.site_name {
                                display: block;
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }

            .evaluation_rating {
                span {
                    font-family: $primaryFont;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    color: $black;
                }
            }
        }

        .wrapperNewsletter {
            overflow: hidden;
            width: 330px;

            @media only screen and (max-width: 1210px) {
                width: 250px;
            }

            @media only screen and (max-width: 1041px) {
                width: 330px;
            }

            .title {
                display: inline-block;
                width: auto;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 1;
                color: $black;
                margin-bottom: 19px;
                border-bottom: 1px solid $black;
            }

            .sub_title {
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                color: $black;
            }

            .w-newsletter-form {
                margin-top: 1rem;
                width: 100%;

                .w-form-line {
                    margin-bottom: 26px;

                    .w-input-container {
                        .w-input {
                            .w-input-label {
                                top: 50%;
                                left: 0;
                                font-family: $primaryFont;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: normal;
                                color: $black;
                                opacity: 0.35;
                            }

                            .w-input-element {
                                width: 100%;
                                height: 1.65rem;
                                font-family: $primaryFont;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: normal;
                                padding: 0;
                                border-radius: 0;
                                border: none;
                                border-bottom: 1px solid $black;

                                &:active,
                                &:focus,
                                &:focus-visible {
                                    border: none;
                                    border-bottom: 1px solid $black;
                                    box-shadow: none;
                                }
                            }

                            &.w-has-error {
                                .w-input-element {
                                    border-color: $red;
                                    box-shadow: inherit !important
                                }
                            }

                            &.w-nonempty {
                                .w-input-label {
                                    transform: translateY(-150%);
                                }
                            }
                        }

                        .w-input-note {
                            text-align: left;

                            &.w-input-error {
                                color: $red;
                            }
                        }
                    }

                    &.w-submit {
                        display: inline-block;
                        width: auto;
                        height: auto;

                        .w-btn-wrapper {
                            .w-submit-button {
                                display: inline-block;
                                width: auto;
                                height: auto;
                                background-color: transparent;
                                border: none;
                                text-transform: none;

                                span {
                                    font-family: $primaryFont;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 300;
                                    line-height: normal;
                                    color: $black;
                                }

                                &.w-loader {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .rs_wrapper {
                display: flex;
                gap:1rem;

                a {
                    position: relative;
                    display: block;
                    width: 20px;
                    height: 20px;
                    background-image: url(../svg/spritesheet.svg);

                    &.facebook {
                        top: 2px;
                        width: 16px;
                        height: 16px;
                        @include bgIconCoord(1,8,5);
                    }

                    &.instagram {
                        @include bgIconCoord(2,8,5);
                    }

                    &.linkedin {
                        @include bgIconCoord(3,8,5);
                    }

                    &.tiktok {
                        @include bgIconCoord(4,8,5);
                    }
                }
            }
        }
    }

    .legalMentions {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $primaryFont;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-align: center;
        padding-bottom: 26px;

        a {
            display: flex;
            align-items: center;
            margin-left: 3px;

            img {
                width: 50px;
                height: auto;
            }
        }
    }

    #mention_legales_popup_wrapper {
        display: none;
        width: 100%;
        padding: 1rem 2rem;
        text-align: center;
        background-color: $grey;
        border-top: 1px solid rgba($black, 0.2);

        a {
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            color: $black;
            text-decoration: none;
        }

        &:has(.link_popup) {
            display: block;
        }
    }
}