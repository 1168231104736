/* GLOBAL STYLES */
.cache,
[v-cloak] {
    display: none;
}

* {
    box-sizing: border-box;
    margin: 0;
}

body {
    width: 100%;
    min-width: 1007px;
    min-height: 100vh;
    min-height: 100dvh;
    font-family: $primaryFont;
    font-weight: 300;
    color: $black;
    background-color: $white;
    margin: 0 auto;
    padding-top: 56px;

    #appContainer {
        width: 100%;
        min-width: 1007px;

        @media screen and (max-width: 1006px) {
            min-width: 100%;
        }
    }

    &:has(.promo_swiper) {
        padding-top: 100px;
    }

    &:has(.promo_swiper):has(#FreeShippingBanner) {
        padding-top: 92px;
    }

    &:has(.categ_item.actif) {
        #site_head_wrap {
            z-index: 38;

            .wrap_search_field.actif {
                opacity: 0 !important;
                z-index: -1 !important;
            }
        }

        #shad_menu.actif {
            z-index: 37;
        }
    }

    &:has(#sizeguideBox.actif),
    &:has(#selection-offer.actif),
    &:has(#availabilityInStore.actif) {
        #shad.actif {
            z-index: 34;
        }
    }

    &:has(#sizeguideBox.actif) {
        #shad.actif {
            z-index: 34;
        }
    }

    &:has(#abox.actif) {
        #shad_abox {
            z-index: 34;
        }
    }

    &.fixed {
        position: fixed;
        padding-right: 17px;
    }

    img {
        max-width: 100%;
    }

    @media screen and (max-width: 1006px) {
        min-width: 100%;
    }
}

main.page_wrapper {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
}

/* shade */
.shad {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: rgba($black, 0.2);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    &.actif {
        display: block !important;
        height: 100%;
        z-index: 33;
        opacity: 1;
        transition: opacity 0.5s ease-in-out;

        &.header_visible,
        &.for_search,
        &#shad_menu,
        &.for_menu {
            z-index: 30;
        }
    }

    &#shad_menu {
        height: 100%;
    }

    &.forFairlyMade,
    &.popup_wishlist {
        z-index: 34;
    }
}

#site_global_wrap {
    min-height: 46.25vh;
    min-height: 46.25dvh;
}

/* CTA */
button,
.button,
.w-submit-button,
button.w-submit-button,
button.w-submit-button:not(.w-loader) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 41px;
    font-family: $primaryFont;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    color: $white;
    background-color: $black;
    border: 1px solid $black;
    transition: background ease-in-out 0.5s, color ease-in-out 0.5s;
    cursor: pointer;
    text-transform: inherit;
    text-decoration: none;

    &:hover {
        color: $black;
        background-color: $white;
    }

    &:focus {
        background-color: $black;
        color: $white;

        &:hover {
            color: $black;
            background-color: $white;
        }
    }
}

/* Loader */
.loader,
.w-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 41px;
    font-size: 0;
    background: $black url(../svg/three_dots.svg) no-repeat center !important;
    background-size: 15% auto !important;
    opacity: 0;
    z-index: -1;

    span {
        font-size: 0;
    }

    &.loading {
        opacity: 1;
        z-index: 1;
    }
}

.loading {
    .loader {
        opacity: 1;
        z-index: 1;
    }
}

button,
input,
textarea {
    background-color: $white;
    border-radius: 0;
    box-sizing: border-box;

    &:active,
    &:focus {
        outline: none;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
    box-shadow: 0 0 0 30px $white inset !important;
}

/* Prix */
.wrapper_price {
    display: flex;
}

.pricetag,
.price_tag,
.price {
    position: relative;
    font-family: $primaryFont;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    color: $black;

    .no_cents {
        display: none;
    }

    &+.pricetag,
    &+.price_tag,
    &+.price {
        margin-left: 6px;

        &:before {
            content: '';
            position: absolute;
            top: 40%;
            width: 100%;
            height: 1px;
            background-color: $black;
            margin: auto;
        }
    }
}