#breadcrumbs {
    &.main_ariane {
        width: 100%;
        padding: 1.5rem 0;
        margin: 0 auto;
    }

    .breadcrumbs {
        display: flex;
        align-items: center;
        justify-content: center;

        .breadcrumb {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: $black;
            line-height: 1;
            border-bottom: 1px solid transparent;

            a {
                color: $black;
                text-decoration: none;
                font-family: $primaryFont;
            }

            &.current {
                color: $black;
                font-family: $primaryFont;
                text-decoration: underline;
                text-underline-offset: 4px;
            }

            &:after {
                content: "\203A";
                display: block;
                margin: 0 1.3rem;
                font-size: 16px;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
}