.tunnel_track {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 39px 0;
    height: 24px;

    .tunnel_step {
        position: relative;
        color: $black;
        text-decoration: none;

        &.on {
            span {
                border-bottom: 1px solid $black;
                line-height: 1;
            }
        }

        &:after {
            display: block;
            margin: 0 1.3rem;
            content: "\203A";
            font-size: 16px;
            text-decoration: none;
        }

        &:before {
            display: none;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

.main_wrapper {
    #main_cart_wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 0 68px;
        width: 95%;
        max-width: 1803px;

        .left_side  {
            display: flex;
            flex-direction: column;
            margin-top: -4px;
            border-right: 1px solid rgba($black, 0.2);
            width: 73%;

            .precoBasket,
            .product_sfs_warning_txt {
                display: flex;
                justify-content: center;
                align-items: center;
                order: 1;
                margin-bottom: 20px;
                background-color: $grey;
                width: 93.5%;
                height: 30px;
                font-size: 12px;
                text-align: center;

                & + .product_sfs_warning_txt {
                    display: none;
                }
            }

            .cart_main_title {
                display: flex;
                order: 5;
                margin-bottom: 8px;
                font-weight: 300;
                font-size: 16px;

                &.wishlist {
                    display: flex !important;
                    order: 6;
                }

                .nb_products {
                    display: flex;
                    margin-left: 5px;
                }
            }
            
            .cart_main_table {
                .cart_product_modify {
                    .button {
                        justify-content: flex-start;
                    }
                }
            }
            
            .basket_contents {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                order: 3;
                margin-bottom: 41px;
                border-bottom: 1px solid rgba($black, 0.2);
                padding-bottom: 10px;

                .dropdown_cart {
                    display: none;
                    margin-bottom: 10px;
                    width: 100%;

                    &.activPreorder {
                        display: block !important;

                        &:has([data-dropdown="exp-2"]) ~ .cart_product_line {
                            .cart_product_sizecol.quantity {
                                display: none;
                            }
                        }
                    }

                    .dropdown_cart_link {
                        display: flex;
                        align-items: center;
                        height: 22px;
                        color: $black;
                        text-decoration: none;

                        h3 {
                            display: flex;
                            align-items: center;
                            border-right: 1px solid;
                            padding-right: 26px;
                            height: 23px;
                            font-weight: 400;
                            font-size: 16px;
                        }

                        .precoSubtitle {
                            display: flex;
                            align-items: center;
                            margin-right: 19px;
                            border-right: 1px solid;
                            padding: 0 19px;
                            height: 23px;
                        }
                    }
                }

                .product_line_basket {
                    display: flex;
                    margin-bottom: 24px;
                    padding-right: 20px;
                    width: 50%;

                    &.out_of_stock {
                        .cart_product_pic {
                            position: relative;

                            &::after {
                                display: flex;
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                height: 100%;
                                content: 'Bientôt disponible';
                            }

                            a img {
                                opacity: 0.5;
                            }
                        }
                    }

                    .cart_product_pic {
                        @media screen and (max-width: 1441px) {
                            width: 50%;
                        }
                    }

                    .cart_product_desc {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin-left: 30px;

                        @media screen and (max-width: 1441px) {
                            width: 50%;
                        }

                        .cart_product_modify {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;

                            .product_towish {
                                display: initial;
                                flex-direction: column;

                                span {
                                    border-bottom: 1px solid $black;
                                }
                            }

                            .product_del {
                                display: initial;
                                margin-top: 11px;

                                span {
                                    border-bottom: 1px solid $black;
                                }
                            }

                            .product_towish,
                            .product_del {
                                color: $black;
                                text-decoration: none;
                            }

                            .product_quantity_change {
                                display: none;
                            }
                        }

                        .wrapper_cart_product_desc {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;

                            .wrapper_cart_product_title {
                                .product_mod {
                                    color: $black;
                                    text-decoration: none;
                                    .cart_product_title {
                                        font-weight: 600;
                                        font-size: 20px;
                                    }
                                }
                            }

                            .line_product_desc_preorder,
                            .line_product_desc_preorder_infos {
                                display: none;
                            }

                            .cart_product_sizecol {
                                .qte_selector {
                                    .selector {
                                        border: 0;
                                        padding: 0;

                                        .qteWrapper {
                                            position: relative;

                                            label {
                                                display: none;
                                            }

                                            &:after {
                                                display: block;
                                                position: absolute;
                                                top: 10px;
                                                right: 0;
                                                bottom: 0;
                                                left: 25px;
                                                background-image: url('../svg/spritesheet.svg');
                                                background-color: $white;
                                                width: 11px;
                                                height: 11px;
                                                content: '';
                                                @include bgIconCoord(5, 8, 5);
                                                z-index: -1;
                                            }

                                            .qnt_prod {
                                                position: relative;
                                                appearance: none;
                                                cursor: pointer;
                                                border: 1px solid $black;
                                                border-radius: 0;
                                                background: transparent;
                                                padding: 7px 0px;
                                                padding-left: 2px;
                                                width: 41px;
                                                font-size: 16px;
                                                font-family: $primaryFont;
                                                text-align: left;

                                                &:focus-visible {
                                                    outline: initial;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .wrapper_cart_product_price {
                                display: flex;
                                margin-top: 10px;
                            }
                        }
                    }

                    .cart_product_pic {
                        .eclat_rect {
                            position: relative;

                            img {
                                display: block;
                                position: absolute;
                                margin: 8px;
                                width: auto;
                                height: 28px;
                            }
                        }
                    }

                    .dragOverlay {
                        display: none;
                    }

                    .product_sfs_warning_txt {
                        display: none;
                    }
                }

                .dropdown_cart {
                    display: none;
                }
            }

            .cart_main_table {
                order: 6;

                .wishlist_persis_info {
                    margin-bottom: 20px;
                    width: 100%;
                    
                    a {
                        color: $black;
                        text-decoration: none;
                    }
                }

                &.wishlist {
                    display: flex !important;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: flex-start;
                    width: 100%;

                    @media (max-width: 1024px) {
                        flex-direction: column;
                      }

                    .cart_product_line {
                        display: flex;
                        padding-bottom: 14px;
                        width: calc((100% - 47px) / 3);

                        &:nth-child(3n+2) {
                            margin: 0 1.7%;
                        }

                        .cart_product_pic {
                            position: relative;

                            p.tunnel_wish_nostock {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: rgba(255, 255, 255, .5);
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                left: 0;
                                top: 0;
                                text-align: center;
                                z-index: 1;
                            }
                        }

                        .cart_product_desc {
                            display: flex;
                            margin-left: 8px;

                            .wrapper_cart_product_desc {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                margin-left: 20px;

                                .cart_product_modify {
                                    .button {
                                        border: none;
                                        background: transparent;
                                        color: black;
                                        text-decoration: underline;
                                        text-underline-offset: 3px;
                                    }

                                    .tunnel_wish_nostock {
                                        margin-bottom: 8px;

                                        span {
                                            border-bottom: 1px solid $black;
                                            line-height: 1;
                                        }
                                    }

                                    .remove_wish {
                                        display: initial;
                                        color: $black;
                                        text-decoration: none;

                                        span {
                                            border-bottom: 1px solid $black;
                                            line-height: 1;
                                        }
                                    }
                                }

                                .wrap_titre_prix {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 10px;

                                    .product_mod {
                                        order: 1;
                                        color: $black;
                                        text-decoration: none;

                                        .cart_product_brand,
                                        .cart_product_subtitle {
                                            display: none;
                                        }

                                        .cart_product_title {
                                            font-weight: 600;
                                            font-size: 20px;
                                        }
                                    }

                                    .cart_product_sizecol {
                                        font-size: 16px;

                                        label {
                                            font-size: 0px;
                                        }

                                        &.color {
                                            order: 2;
                                        }

                                        &.size {
                                            order: 3;
                                        }
                                    }

                                    .wrapper_cart_product_price {
                                        order: 4;

                                        .pricetag {
                                            &.is_discounted.was[ge-data-converted-price] {
                                                text-decoration: line-through;
                                                margin-left: 0;

                                                &:before {
                                                    display: none;
                                                }
                                            }

                                            &.now[ge-data-converted-price] {
                                                margin-right: 6px;
                                            }
                                        }
                                    }
                                }

                                form {
                                    margin-bottom: 8px;
                                    
                                    .warn_availability {
                                        display: flex !important;
                                        text-decoration: underline;
                                        cursor: pointer;
                                        border: none;
                                        background-color: $white;
                                        color: $black;
                                        margin-bottom: 8px;
                                    }
        
                                    .warn_availability.none-important {
                                        display: none !important;
                                    }
                                    
                                    .expressAlertStock {
                                        display: none;
                                        position: relative;
        
                                        .bloc_add_alert_form {
                                            .w-form-line {
                                                position: relative;
                                                width: 100%;
                                            }
        
                                            .w-input-element {
                                                width: 100%;
                                                height: 32px;
                                                font-family: $primaryFont;
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: 1;
                                                color: $black;
                                                padding: 0;
                                                padding-right: 24px;
                                                border: none;
                                                border-bottom: 1px solid $black;
                                                border-radius: 0;
        
                                                &:focus {
                                                    box-shadow: none;
                                                }
        
                                                &.inputErr {
                                                    border-bottom: 1px solid $red;
                                                }
                                            }
        
                                            .form_submit {
                                                position: absolute;
                                                right: 0;
                                                top: 5px;
        
                                                button { 
                                                    height: 22px !important;
                                                    width: 22px;
                                                    border: none;
        
                                                    &:focus {
                                                        background-color: transparent;
                                                    }
        
                                                    span {
                                                        color: $black;
                                                    }
                                                }
                                            }
                                        }
        
                                        .alert_return {
                                            position: absolute;
                                            top: -14px;
                                            right: 0;
                                            width: 12px;
                                            height: 12px;
                                            cursor: pointer;
                                            background-image: url(../svg/spritesheet.svg);
                                            background-size: 800% auto;
                                            background-position: 71.42857% 50%;
                                            opacity: 1;
                
                                            span {
                                                display: none;
                                            }
                                        }
        
                                        .inputErr1 {
                                            button {
                                                background-color: transparent;
                                            }
                                        }
                                    }
        
                                    .bloc_add_alert_erreur {
                                        display: none;
                                    }
        
                                    .noSizeAvailable {
                                        display: flex !important;
                                        text-decoration: underline;
                                        cursor: not-allowed;
                                        border: none;
                                        background-color: $white;
                                        color: $black;
                                        margin-bottom: 8px;
        
                                        .alert_return {
                                            display: none;
                                        }
                                    }
        
                                }
                            }
                        }

                        .cart_product_pic {
                            .product_mod {
                                display: block;
                                width: 208px;
                            }
                        }

                        .dragOverlay {
                            display: none;
                        }
                    }
                    
                    @media screen and(max-width: 1600px) {
                        .cart_product_line {
                            width: calc((100% - 47px) / 2);

                            &:nth-child(3n+2) {
                                margin: initial;
                            }

                            &:nth-of-type(1),
                            &:nth-of-type(3) {
                                margin-right: 20px;
                            }
                        }
                    }

                    @media (max-width: 1024px) {
                        .cart_product_line {
                            width: 100%;
                        }
                    }
                }
            }

            .wrapper_delivery_choice {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: 29px;

                .delivery_choice_btn {
                    display: flex;
                    position: relative;
                    justify-content: center;
                    align-items: flex-end;
                    transition: all .3s ease-in-out;
                    cursor: pointer;
                    margin-right: 18px;
                    margin-bottom: 10px;
                    border: 1px solid $black;
                    background-color: $white;
                    width: 151px;
                    height: 50px;
                    color: $primaryColor;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 18px;
                    letter-spacing: .07em;
                    text-transform: none;

                    &:hover,
                    &.selected {
                        background-color: $primaryColor;
                        color: $white;

                        &:before {
                            filter: invert(1);
                        }
                    }

                    span {
                        position: relative;
                        margin-bottom: 15px;
                        margin-left: 21px;
                        font-weight: 300;
                        font-size: 16px;
                        font-family: $primaryFont;
                        letter-spacing: 0;
                    }

                    &.home_delivery_btn {
                        &:before {
                            background-image: url('../svg/spritesheet.svg');
                            content: '';
                            @include bgIconCoord(15,8,5);
                            position: absolute;
                            top: 30%;
                            left: 20%;
                            width: 20px;
                            height: 20px;
                        }
                    }

                    &.store_delivery_btn {
                        &:before {
                            background-image: url('../svg/spritesheet.svg');
                            content: '';
                            @include bgIconCoord(16,8,5);
                            position: absolute;
                            top: 30%;
                            left: 20%;
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }

            .delivery_choice {
                display: none;
                position: relative;
                justify-content: space-between;
                align-items: center;
                opacity: 0;
                transition: all .3s ease-in-out;
                cursor: pointer;
                border-bottom: 1px solid $black;
                padding: 30px 27px 30px 39px;

                .transporteur_infos {
                    .transporteur_type,
                    .transporteur_id {
                        font-size: 16px;
                        text-transform: none !important;
                    }
                }

                input.choix_livraison {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    z-index: 1;
                    cursor: pointer;
                    width: 100%;
                    height: 100%;
                }

                .checkbox {
                    position: absolute;
                    top: 45%;
                    left: 0px;
                    transform: translateY(-50%);
                    box-shadow: inset 0 0 0 10px $white;
                    border: 1px solid $primaryColor;
                    border-radius: 50%;
                    background-color: $primaryColor;
                    width: 15px;
                    height: 15px;
                }

                &.selected {
                    display: flex;
                    opacity: 1;
                    transition: all .3s ease-in-out;
                }

                &:hover,
                &.active {
                    border-color: $primaryColor;

                    .checkbox {
                        box-shadow: inset 0 0 0 2px $white;
                    }
                }

                .transporteur_type,
                .transporteur_id {
                    font-size: 13px;
                    text-transform: uppercase;
                }

                .transporteur_delay {
                    font-weight: 300;
                    font-size: 13px;
                    line-height: 15px;
                    font-family: $primaryFont;
                }

                .warning_point_relais {
                    margin: 2px 0 6px;
                    font-weight: 300;
                    font-size: 15px;
                    font-family: $primaryFont;
                }

                .shop_name {
                    margin-top: 14px;
                    font-size: 15px;
                    font-family: $primaryFont;
                    letter-spacing: .07em;
                    text-transform: uppercase;

                    +.adresse {
                        text-transform: capitalize;
                    }
                }

                .adresse {
                    margin: -2px 0 6px;
                    font-weight: 300;
                    font-size: 15px;
                    font-family: $primaryFont;
                }

                .show_delivery_info {
                    position: relative;
                    z-index: 2;
                    margin-top: 4px;
                    border-bottom: 1px solid $black;
                    font-weight: 300;
                    font-size: 15px;
                    font-family: $primaryFont;

                    &:hover {
                        text-decoration: none;
                    }
                }

                .price_tag,
                .fdp_offered {
                    font-size: 15px;
                    text-transform: uppercase;
                }

                .delivery_lightbox {
                    transition: none;
                    
                    &.lightbox {
                        &.actif {
                            top: 0;
                            right: 0;
                            padding: 42px 25px 10px 31px;
                            width: 26%;
                            height: 100vh;
                            height: 100dvh;

                            .lightbox_delivery_title {
                                margin-bottom: 12px;
                                text-align: center;
                            }

                            .wrapper_select_adr {
                                .address {
                                    display: flex;
                                    position: relative;
                                    align-items: center;
                                    margin: 24px 0;
                                    border-bottom: 1px solid $black;
                                    padding-bottom: 17px;

                                    &:nth-last-child(1) {
                                        border: 0;
                                        text-align: center;

                                        .infos {
                                            .addr_line {
                                                &.full_address {
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    transition: background ease-in-out 0.5s, color ease-in-out 0.5s;
                                                    cursor: pointer;
                                                    border: 1px solid $black;
                                                    background-color: $black;
                                                    width: 100%;
                                                    height: 41px;
                                                    color: $white;
                                                    line-height: normal;
                                                    text-decoration: none;
                                                    text-transform: inherit;

                                                    &:hover {
                                                        background-color: $white;
                                                        color: $black;
                                                    }

                                                    &:focus {
                                                        background-color: $black;
                                                        color: $white;

                                                        &:hover {
                                                            background-color: $white;
                                                            color: $black;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .infos {
                                        position: relative;
                                        width: 100%;

                                        .addr_line {
                                            &.full_address {
                                                width: 60%;
                                            }

                                            &.full_name {
                                                margin-bottom: 12px;
                                                text-transform: capitalize;
                                            }
                                        }
                                    }

                                    .add_addr {
                                        display: block;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                        height: 100%;
                                    }

                                    .modlinks_wrapper {
                                        a {
                                            color: $black;
                                        }
                                    }

                                    .bgSprite {
                                        display: none;
                                    }
                                }
                            }
                        }

                        &.store {
                            &.actif {
                                padding: 43px 45px 50px 29px;
                            }

                            .lightbox_delivery_title {
                                margin-bottom: 24px;
                            }

                            .wrapper_locate_relais {
                                .delivery_form_title {
                                    display: none;
                                }

                                .wrapper_relais_top {
                                    margin-bottom: 39px;
                                    width: 104.5%;
                                }

                                .displayed_result {
                                    .map_canvas {
                                        margin-bottom: 12px;
                                        width: 104%;
                                        height: 181px;

                                        div {
                                            height: 181px;

                                            .gm-style {
                                                div {
                                                    a img,
                                                    span,
                                                    .gmnoprint,
                                                    button {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .sidebar-right {
                                        padding-right: 2%;
                                        width: 107.5%;
                                        height: calc(100vh - 400px);
                                        overflow-y: scroll;

                                        &::-webkit-scrollbar {
                                            width: 1px;
                                        }

                                        &::-webkit-scrollbar-thumb {
                                            border-radius: 6px;
                                            background: $black; 
                                        }

                                        .details {
                                            width: 100%;

                                            .kp {
                                                border-bottom: 1px solid $black;
                                                padding-top: 25px;
                                                padding-bottom: 24px;

                                                .phone,
                                                .relayHours,
                                                .locationhint {
                                                    display: none;
                                                }

                                                .name {
                                                    margin-bottom: 10px;
                                                    text-transform: capitalize;
                                                }

                                                .address {
                                                    display: flex;
                                                    margin-bottom: 6px;

                                                    .zipcode {
                                                        &:before {
                                                            margin: 0 3px;
                                                            content: "-";
                                                        }
                                                    }
                                                }

                                                .current_schedule {
                                                    margin-bottom: 12px;

                                                    &:before {
                                                        margin-right: 5px;
                                                        content: '●';
                                                        font-size: 16px;
                                                        font-family: sans-serif;
                                                    }

                                                    &.puce_store_open {
                                                        color: $green;

                                                        .open {
                                                            color: $green;
                                                        }
                                                    }

                                                    &.puce_store_closed {
                                                        color: $red;
                                                    }

                                                    .open {
                                                        &:after {
                                                            content: '';
                                                            color: $black;
                                                        }
                                                    }
                                                }

                                                .btn_container {
                                                    a {
                                                        color: $black;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .w-form-line {
                                    margin-bottom: 0;

                                    .w-input-container {
                                        .w-input {
                                            .w-input-element {
                                                border: 0;
                                                border-bottom: 1px solid $black !important;
                                                border-radius: 0;
                                                padding: 0;
                                                padding-top: 27px;  
                                                padding-bottom: 8px;
                                                color: $black;
                                                font-size: 16px;
                                                font-family: $primaryFont;
                                            }

                                            &.w-text-input {
                                                position: relative;

                                                &:after {
                                                    display: block;
                                                    position: absolute;
                                                    top: 48%;
                                                    right: 0;
                                                    bottom: 0;
                                                    background-image: url('../svg/spritesheet.svg');
                                                    background-color: $white;
                                                    width: 23px;
                                                    height: 23px;
                                                    content: '';
                                                    @include bgIconCoord(25, 8, 5);
                                                }
                                            }

                                            .w-input-label {
                                                padding-top: 27px;
                                                color: rgba(0, 0, 0, 0.5);
                                                font-family: $primaryFont;
                                            }

                                            &.w-focused {
                                                .w-input-element {
                                                    &:focus {
                                                        outline: initial;
                                                        box-shadow: none;
                                                        padding: 0;
                                                        padding-top: 19px;
                                                    }
                                                }
                                            }

                                            &.w-has-error {
                                                .w-input-element {
                                                    box-shadow: none;
                                                }
                                            }

                                            .w-input-label {
                                                left: 0;
                                                padding-bottom: 8px;
                                                color: $black;
                                                font-size: 16px;
                                                font-family: $primaryFont;
                                            }

                                            &.w-nonempty {
                                                .w-input-label {
                                                    margin-top: 8px;
                                                    padding-top: 0;
                                                    font-size: 12px;
                                                }

                                                .w-input-element {
                                                    padding-top: 27px;
                                                }
                                            }

                                            .w-visibility-toggle {
                                                border: 0;
                                                background-color: transparent;
                                            }
                                        }

                                        .w-input-note {
                                            margin: 0;
                                        }
                                    }

                                    &.w-submit {
                                        margin-top: 29px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .delivery_section_title {
                margin-bottom: 14px;
                width: 100%;

                &.delivery_mode {
                    margin-bottom: 13px;
                }

                .title {
                    display: block;
                    margin-bottom: 7px;
                }
            }

            .wrapper_form_adresse {
                &.tunnel_form {
                    .w-form {
                        .w-form-line {
                            &.dropdown_address {
                                display: none;
                            }
                        }

                        .elem_global {
                            .w-input-group {
                                margin: 0;
                                border: 0;
                                padding: 0;
                            }

                            .w-form-line {
                                margin-bottom: 10px;

                                .w-input-container {
                                    .w-input {
                                        .w-input-element {
                                            border: 0;
                                            border-bottom: 1px solid $black;
                                            border-radius: 0;
                                        }

                                        .w-input-label {
                                            left: 0;
                                            color: $black;
                                            font-size: 16px;
                                            font-family: $primaryFont;
                                        }

                                        &.w-nonempty {
                                            .w-input-label {
                                                padding-bottom: 0;
                                                color: $black;
                                                font-size: 12px;
                                                font-family: $primaryFont;
                                            }
                                        }

                                        &.w-dropdown {
                                            border: 0;
                                            border-bottom: 1px solid $black;
                                            border-radius: 0;

                                            .w-value {
                                                padding: 0;
                                            }

                                            &:before {
                                                width: 2.375rem;
                                            }

                                            &:after {
                                                -webkit-transform: rotate(45deg);
                                                display: inline-block;
                                                right: 1.2rem;
                                                transform: rotate(45deg);
                                                cursor: pointer;
                                                border: solid black;
                                                border-width: 0 1px 1px 0;
                                                padding: 3px;
                                            }
                                        }

                                        &.w-tel-input {
                                            .selected-flag  {
                                                border: 0;
                                                background-color: transparent;
                                                padding: 0;
                                                z-index: 5;
                                                height: 100%;

                                                &:before {
                                                    width: 2.375rem;
                                                } 

                                                &:after {
                                                    -webkit-transform: rotate(45deg);
                                                    display: inline-block;
                                                    right: 1.2rem;
                                                    transform: rotate(45deg);
                                                    cursor: pointer;
                                                    border: solid black;
                                                    border-width: 0 1px 1px 0;
                                                    padding: 3px;
                                                }
                                            }

                                            &.w-nonempty,
                                            &.w-focused {
                                                .w-input-element {
                                                    padding-left: 3.8rem;
                                                }
                                            }

                                            &.w-nonempty {
                                                .w-input-label {
                                                    padding-bottom: 4px;
                                                }
                                            }
                                            
                                            .w-input-label {
                                                padding-left: 3.8rem;
                                            }

                                            &.w-focused,
                                            &.w-has-error {
                                                .w-input-element {
                                                    box-shadow: none;
                                                }
                                            }
                                        }

                                        &.w-nonempty,
                                        &.w-focused,
                                        &.w-has-error {
                                            box-shadow: none;

                                            .w-input-element {
                                                box-shadow: none;
                                                border: 0;
                                                border-bottom: 1px solid $black;
                                                padding: 0;
                                            }
                                        }
                                    }

                                    .w-input-note {
                                        &.w-input-error {
                                            text-align: left;
                                        }
                                    }

                                    .errormsgadd {
                                        display: none;
                                    }
                                }

                                &.notice {
                                    display: none;
                                }

                                &.last-is-hidden {
                                    margin-bottom: 0;
                                }
                            }
                        }

                        .w-form-line {
                            &.w-submit {
                                margin-top: 26px;
                                width: 100%;
                                max-width: initial;
                            }
                        }

                        &.w-newuser-form-v2 {
                            .w-input-group {
                                margin: 0;
                                border: 0;
                                padding: 0;

                                .w-form-line {
                                    margin-bottom: 11px;

                                    .w-input-container {
                                        .w-input {
                                            .w-input-element {
                                                border: 0;
                                                border-bottom: 1px solid $black !important;
                                                border-radius: 0;
                                                padding: 0;
                                                padding-top: 27px;
                                                color: $black;
                                                font-size: 16px;
                                                font-family: $primaryFont;  
                                            }

                                            .w-input-label {
                                                padding-top: 27px;
                                                color: rgba(0, 0, 0, 0.5);
                                                font-family: $primaryFont;
                                            }

                                            &.w-focused {
                                                .w-input-element {
                                                    &:focus {
                                                        outline: initial;
                                                        box-shadow: none;
                                                        padding: 0;
                                                        padding-top: 27px;
                                                    }
                                                }
                                            }

                                            &.w-has-error {
                                                .w-input-element {
                                                    box-shadow: none;
                                                }
                                            }

                                            .w-input-label {
                                                left: 0;
                                                color: rgba(0, 0, 0, 0.5);
                                                font-size: 16px;
                                                font-family: $primaryFont;
                                            }

                                            &.w-nonempty {
                                                .w-input-label {
                                                    padding-top: 0;
                                                    font-size: 12px;
                                                }

                                                .w-input-element {
                                                    padding-top: 27px;
                                                }

                                                .noaddr {
                                                    top: 41%;
                                                    font-size: 12px;
                                                }
                                            }
                                        }
                                    }

                                    .noaddr {
                                        top: 70%;
                                        right: 0;
                                        color: $red;
                                        font-weight: 300;
                                        font-size: 16px;
                                        font-family: $primaryFont;
                                    }

                                    .w-input {
                                        &.w-nonempty {
                                            .w-input-label {
                                                margin-top: 5px;
                                            }
                                        }
                                    }
                                }

                                &.accountCreate {
                                    .w-input-container {
                                        .w-input {
                                            &.w-checkbox-input {
                                                .w-input-element {
                                                    position: absolute;
                                                    top: 0;
                                                    left: 0;
                                                    opacity: 0;
                                                    width: 1em;
                                                    height: 1em;

                                                    &:checked {
                                                        & + .w-input-label {
                                                            &:after {
                                                                left: 0.8em;
                                                            }

                                                            &:before {
                                                                background-color: $primaryColor;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            .w-input-label {
                                                display: flex;
                                                padding-top: 0;
                                                color: $black;
                                                font-weight: 300;
                                                font-size: 16px;
                                                font-family: $primaryFont;

                                                &::before {
                                                    display: block;
                                                    position: relative;
                                                    transition: .2s ease-out;
                                                    transition-property: background-color, box-shadow;
                                                    box-shadow: inset 0 0 0 0 $white;
                                                    border-radius: 26px;
                                                    background-color: $thirdColor;
                                                    width: 26px;
                                                    height: 14px;
                                                    content: '';
                                                }

                                                &::after {
                                                    display: block;
                                                    position: absolute;
                                                    top: 50%;
                                                    left: 2px;
                                                    transform: translate(0, -50%);
                                                    transition: .2s ease-out;
                                                    border-radius: 50%;
                                                    background-color: $white;
                                                    width: 13px;
                                                    height: 13px;
                                                    content: '';
                                                }
                                            }

                                            &.w-password-input {
                                                .w-input-label {
                                                    &::after,
                                                    &::before {
                                                        content: none;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                &:nth-of-type(2) {                          
                                    .delivery_section_title {
                                        display: none;
                                    }
                                }
                            }

                            .elem_global {
                                margin-bottom: 16px;

                                .w-input-group {
                                    .w-form-line {
                                        .w-input-container {
                                            .w-input {
                                                &.w-nonempty {
                                                    .w-value {
                                                        padding-top: 15px;
                                                    }
                                                }

                                                .w-input-label {
                                                    margin-top: 0;
                                                    padding-top: 0;
                                                }

                                                &.w-focused {
                                                    .w-input-element {
                                                        padding: 0;
                                                        padding-top: 16px;
                                                    }
                                                }
                                            }

                                            &.delivery-country {
                                                .w-input {
                                                    &.w-dropdown {
                                                        height: 3.45em !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .w-form-line { 
                                    margin-bottom: 11px;

                                    &:nth-of-type(4) {
                                        margin-bottom: 17px;
                                    }

                                    &:nth-of-type(5) {
                                        margin-bottom: 0px;

                                        .w-input-container {
                                            margin-bottom: 16px;

                                            & + .w-input-container {
                                                height: 61px;
                                            }
                                        }
                                    }

                                    &.add-complementary-line {
                                        margin-top: 27px;

                                        .w-input-container {
                                            .w-input {
                                                &.w-checkbox-input {
                                                    .w-input-element {
                                                        position: absolute;
                                                        top: 0;
                                                        left: 0;
                                                        opacity: 0;
                                                        width: 1em;
                                                        height: 1em;
                                                    }
                                                }

                                                .w-input-label {
                                                    color: $black;
                                                    font-weight: 300;
                                                    font-size: 16px;
                                                    font-family: $primaryFont;

                                                    &::before {
                                                        background-image: url('../svg/spritesheet.svg');
                                                        width: 14px; 
                                                        height: 14px;
                                                        content: "";
                                                        @include bgIconCoord(21,8,5);
                                                        position: absolute;
                                                        box-shadow: none;
                                                        border: 0;
                                                        background-color: transparent;
                                                    }

                                                    &::after {
                                                        position: absolute;
                                                        border-bottom: 1px solid $black;
                                                        width: 100%;
                                                        height: 14px;
                                                        content: "";
                                                    }

                                                    span {
                                                        margin-left: 17px;
                                                    }
                                                }

                                                .w-input-element:checked {
                                                    & + .w-input-label:before {
                                                        @include bgIconCoord(20,8,5);
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    &.w-has-tel {
                                        .w-input-container {
                                            margin-bottom: 18px;
                                            margin-left: 0;

                                            & + .w-input-container {
                                                margin-left: 15px;
                                            }

                                            .w-input {
                                                &.w-date-input {
                                                    .w-input-label {
                                                        font-size: 12px;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .w-input-container {
                                        .w-input {
                                            &.w-nonempty {
                                                .w-value {
                                                    padding-top: 24px;
                                                    color: $black;
                                                    font-size: 16px;
                                                    font-family: $primaryFont;
                                                }

                                                .w-input-element {
                                                    padding-top: 30px;
                                                }
                                            }

                                            .w-input-label {
                                                margin-top: 2px;
                                                color: rgba(0, 0, 0, 0.5);
                                            }

                                            .w-input-element {
                                                padding-top: 0;
                                                color: $black;
                                                font-size: 16px;
                                                font-family: $primaryFont;
                                            }

                                            &.w-date-input {
                                                .w-input-element {
                                                    padding: 24px 0 0;
                                                }
                                            }
                                        }
                                    }

                                    &.sameBillAddress {
                                        margin-bottom: 0;

                                        .w-input-container {
                                            .w-input {
                                                &.w-checkbox-input {
                                                    .w-input-element {
                                                        position: absolute;
                                                        top: 0;
                                                        left: 0;
                                                        opacity: 0;
                                                        width: 1em;
                                                        height: 1em;

                                                        &:checked {
                                                            & + .w-input-label {
                                                                &:after {
                                                                    left: 0.8em;
                                                                }

                                                                &:before {
                                                                    background-color: $primaryColor;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                .w-input-label {
                                                    color: $black;
                                                    font-weight: 300;
                                                    font-size: 16px;
                                                    font-family: $primaryFont;

                                                    &::before {
                                                        display: block;
                                                        position: relative;
                                                        transition: .2s ease-out;
                                                        transition-property: background-color, box-shadow;
                                                        box-shadow: inset 0 0 0 0 $white;
                                                        border-radius: 26px;
                                                        background-color: $thirdColor;
                                                        width: 26px;
                                                        height: 14px;
                                                        content: '';
                                                    }

                                                    &::after {
                                                        display: block;
                                                        position: absolute;
                                                        top: 50%;
                                                        left: 2px;
                                                        transform: translate(0, -50%);
                                                        transition: .2s ease-out;
                                                        border-radius: 50%;
                                                        background-color: $white;
                                                        width: 13px;
                                                        height: 13px;
                                                        content: '';
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    &.notice {
                                        display: block;
                                        margin-bottom: 31px;
                                        font-size: 12px;
                                    }
                                }
                            }

                            .w-form-line {
                                .w-submit-button {
                                    width: 278px;
                                }
                            }
                        }
                    }
                }
            }
        }

        #tunnel_right_col {
            display: flex;
            flex-direction: column;
            padding: 0px 19px 0px 40px;
            width: 27%;

            .tunnel_sticky {
                #bandeau_basket_premium {
                    color: $black;
                    font-size: 16px;
                    text-align: center;
                    text-transform: capitalize;
                }

                .main_table  {
                    .recap_cart_title {
                        font-weight: 300;
                        font-size: 16px;
                        text-decoration: underline;
                    }

                    .bill_line {
                        display: flex;
                        justify-content: space-between;

                        &.coupon {
                            #formPromo {
                                .w-form-line {
                                    .w-input-container {
                                        .w-input {
                                            .w-input-element {
                                                border: 0;
                                                border-bottom: 1px solid $black;
                                                border-radius: 0;

                                                &:focus {
                                                    box-shadow: none;
                                                }
                                            }

                                            .w-input-label {
                                                left: 0;
                                                font-size: 16px;
                                                font-family: $primaryFont;
                                            }

                                            &.w-focused {
                                                .w-input-element {
                                                    padding: 0;
                                                }
                                            }

                                            &.w-has-error {
                                                .w-input-element {
                                                    box-shadow: none;
                                                }
                                            }

                                            &.w-nonempty {
                                                .w-input-element {
                                                    padding: 0;
                                                }

                                                .w-input-label {
                                                    padding-bottom: 4px;
                                                }
                                            }
                                        }
                                    }

                                    &.w-submit {
                                        .w-submit-button {
                                            border: 0;
                                            background: transparent;
                                            width: initial;
                                            height: initial;
                                            color: black;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .wrapper_informations {
                        position: relative;
                        margin-bottom: 28px;

                        .date_livraison {
                            margin-bottom: 12px;
                            margin-left: 37px;

                            &:before {
                                background-image: url('../svg/spritesheet.svg');
                                content: '';
                                @include bgIconCoord(23,8,5);
                                position: absolute;
                                left: -3px;
                                width: 24px;
                                height: 24px;
                            }
                        }

                        .back_order {
                            margin-left: 38px;

                            &:before {
                                background-image: url('../svg/spritesheet.svg');
                                content: '';
                                @include bgIconCoord(24,8,5);
                                position: absolute;
                                left: 0;
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }

                    .upselling {
                        display: block;

                        strong {
                            font-weight: normal;
                        }

                        progress {
                            width: 100%;
                        }

                        progress {
                            border: 1px solid $black;
                            background-color: $white;
                            height: 9px;

                            &::-webkit-progress-bar,
                            &::-moz-progress-bar {
                                background-color: $white;
                            }
                        }

                        progress::-webkit-progress-bar {
                            background-color: $white;
                        }

                        progress::-moz-progress-bar {
                            background-color: $white;
                        }

                        progress::-webkit-progress-value {
                            background-color: $black;
                        }
                    }

                    .formPanier_container {
                        #formPanier {
                            .form_submit {
                                .button {
                                    background-color: $white;
                                    color: $black;

                                    span {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 100%;
                                        height: 100%;
                                    }

                                    &:hover {
                                        background-color: $black;
                                        color: $white;
                                    }

                                    &:focus {
                                        background-color: $black;
                                        color: $white;

                                        &:hover {
                                            background-color: $white;
                                            color: $black;
                                        }
                                    }

                                    &.loading {
                                        & + .cartLoader {
                                            opacity: 1;
                                            z-index: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .addresses_recap {
                order: 2;

                .address {
                    display: flex;
                    position: relative;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 1rem;

                    a {
                        cursor: pointer;
                        text-decoration: none;

                        .addr_label,
                        .infos,
                        .modlink {
                            color: $black;
                            font-weight: 300;
                            font-size: 16px;
                            font-family: $primaryFont;
                        }

                        .modlink  {
                            border-bottom: 1px solid $black;
                            line-height: 1;
                        }

                        .addr_label {
                            text-decoration: none;
                        } 
                    }

                    .modlinks_wrapper {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        border-bottom: 1px solid $black;
                        line-height: 1;
                    }

                    .addr_label {
                        font-weight: 300;
                        font-size: 16px;
                        font-family: $primaryFont;
                    }

                    .infos {
                        padding-right: 66px;

                        .addr_line {
                            &.full_name, 
                            &.society {
                                display: none;
                            }
                        }
                    }

                    .bgSprite {
                        display: none;
                    }

                    &.bill {
                        margin-bottom: 10px;

                        a {
                            display: flex;
                            position: relative;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            align-items: center;

                            .modlink {
                                position: absolute;
                                top: 50%;
                                right: 0;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
            }

            .deconnection_tunnel {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                order: 1;

                .button {
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                    transition: all ease-in-out .5s;
                    cursor: pointer;
                    border: 0;
                    background-color: transparent;
                    padding: 0;
                    width: initial;
                    color: $black;
                    font-size: 16px;
                    font-family: $primaryFont;
                    text-transform: inherit;

                    span {
                        border-bottom: 1px solid $black;
                        line-height: 1;
                    }
                }
            }

            .main_table {
                display: flex;
                flex-direction: column;
                order: 3;
                gap: 20px;

                .recap_cart_title {
                    display: none;
                }

                .bill_line {
                    display: flex;
                    justify-content: space-between;

                     &.total_produit_dropdown {
                        display: none;
                        flex-direction: column;

                        .product_line_basket {
                            display: flex;
                            margin-bottom: 14px;
                            width: 100%;

                            .cart_product_desc {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                margin-left: 30px;

                                .cart_product_modify {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;

                                    .product_towish {
                                        display: initial;
                                        flex-direction: column;
                                    }

                                    .product_del {
                                        display: initial;
                                        margin-top: 11px;
                                    }

                                    .product_towish,
                                    .product_del {
                                        color: $black;
                                    }

                                    .product_quantity_change {
                                        display: none;
                                    }
                                }

                                .wrapper_cart_product_desc {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 10px;

                                    .line_product_desc_preorder,
                                    .line_product_desc_preorder_infos {
                                        display: none;
                                    }

                                    .cart_product_sizecol {
                                        &.quantity {
                                            display: none;
                                        }
                                        
                                        .qte_selector {
                                            .selector {
                                                border: 0;
                                                padding: 0;

                                                .qteWrapper {
                                                    position: relative;

                                                    label {
                                                        display: none;
                                                    }

                                                    &:after {
                                                        display: block;
                                                        position: absolute;
                                                        top: 10px;
                                                        right: 0;
                                                        bottom: 0;
                                                        left: 25px;
                                                        background-image: url('../svg/spritesheet.svg');
                                                        background-color: $white;
                                                        width: 11px;
                                                        height: 11px;
                                                        content: '';
                                                        @include bgIconCoord(5, 8, 5);
                                                        z-index: -1;
                                                    }

                                                    .qnt_prod {
                                                        position: relative;
                                                        appearance: none;
                                                        cursor: pointer;
                                                        border: 1px solid $black;
                                                        background: transparent;
                                                        padding: 7px 0px;
                                                        width: 41px;
                                                        font-family: $primaryFont;
                                                        text-align: start;
                                                        text-indent: 3px;

                                                        &:focus-visible {
                                                            outline: initial;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .wrapper_cart_product_price {
                                        margin-top: 0 !important;
                                    }

                                    .wrapper_cart_product_price {
                                        display: flex;
                                        margin-top: 10px;
                                    }
                                }
                            }

                            .dragOverlay {
                                display: none;
                            }

                            .cart_product_pic {
                                width: 53%;
                            }
                        }
                    }

                    &.total_produit {
                        &.under_dropdown {
                            display: flex !important;
                            margin-bottom: 0.5rem;
                        }

                        &.toggle {
                            display: none;
                            position: relative;
                            cursor: pointer;
                            margin-bottom: 19px;

                            &:after {
                                background-image: url('../svg/spritesheet.svg');
                                width: 14px; 
                                height: 14px;
                                content: "";
                                @include bgIconCoord(5,8,5);
                                position: absolute;
                                position: absolute;
                                right: 0;
                                cursor: pointer;
                                box-shadow: none;
                                border: 0;
                                background-color: transparent;
                            }

                            &.open:after {
                                transform: rotate(180deg);
                            }

                            label {
                                display: flex;
                                position: relative;

                                &:before {
                                    display: block;
                                    position: absolute;
                                    bottom: 1px;
                                    left: 0;
                                    background-color: $black;
                                    width: 100%;
                                    height: 1px;
                                    content: "";
                                }

                                .nb_products {
                                    display: flex;
                                    margin-left: 4px;
                                }
                            }

                            .price {
                                display: none;
                            }
                        }
                    }

                    &.discount {
                        margin-bottom: 0.5rem;
                    }

                    &.total {
                        margin-bottom: 0.5rem;
                    }
                }

                .formPanier_container {
                    .w-input-container {
                        margin: 15px 0 0 0;
                        height: 23px;

                        .w-input-label {
                            align-items: flex-start;
                            color: $black;
                            font-weight: 300;
                            font-size: 12px;
                            font-family: $primaryFont;

                            &:before {
                                box-shadow: inset 0 0 0 .15em $white;
                                border-color: $black;
                                width: 15px;
                                height: 13px;
                            }

                            a {
                                color: $black;
                                text-decoration: none;
                            }
                        }
                    }

                    #err_cgv_compulsory {
                        margin: 4px 0 29px 22px;
                        color: $red;
                        font-size: 12px;
                    }

                    .form_submit {
                        position: relative;
                        margin-top: 15px;
                    }
                }
            }
            
            .addresses_recap,
            .deconnection_tunnel {

                .button {
                    padding: 0;
                }
            }

            .share_cart {
                display: flex;
                position: relative;
                flex-direction: column-reverse;
                gap: 35px;
                margin-bottom: 29px;

                .share_cart_header {
                    position: relative;
                    padding-left: 29px;
                    color: $black;
                    text-decoration: underline;
                    text-underline-offset: 3px;

                    &:before {
                        background-image: url('../svg/spritesheet.svg');
                        content: '';
                        @include bgIconCoord(27,8,5);
                        position: absolute;
                        left: 0;
                        width: 16px;
                        height: 16px;
                    }
                }

                .share_cart_icon {
                    position: absolute;
                    border: 0;
                    background-color: transparent;
                    height: 20px;
                }
            }

            .tunnel_sticky {
                .main_table {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .bill_line {
                        &.coupon {
                            margin-bottom: 22px;
                        }

                        &.used_cp {
                            .code_promo {
                                a {
                                    color: $black;
                                    text-decoration: none;
                                }
                            }
                        }
                    }

                    .formPanier_container {
                        margin-bottom: 20px;
                    }
                }
            }

            .wrap_bottom_rightcol {
                order: 4;
                margin-top: 41px;
                
                .bloc_question {
                    .title_question {
                        margin-bottom: 19px;
                        font-weight: 300;
                        font-size: 16px;
                    }

                    .faq_questions {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        .intitule_question {
                            cursor: pointer;
                            font-weight: 300;
                            font-size: 14px;
                        }

                        .texte_question {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        /* TG MODULE */
        .basket_gondole {
            width: 100%;

            .swiper.swiper-container {
                padding-bottom: 35px;

                .swiper-wrapper {
                    .item.gondole {
                        .productVisualMulti.tg-module {
                            .block_lnk.swiper-container {
                                .ill_img {
                                    display: block;
                                    width: 100%;
                                    height: auto;
                
                                    img {
                                        width: 100%;
                                        height: auto;
                                    }
                                }
                            }
                        }

                        .description_color_size_wrapper {
                            .item_title h3 {
                                font-size: 16px;
                            }

                            .wrap_rolloverproduit {
                                margin-top: 9px;

                                .productSizeFieldset {
                                    top: auto;
                                    bottom: 4.5rem;
                                    left: 34px;
                                    
                                    .choices_list {
                                        row-gap: 10px;
                                        flex-wrap: wrap;
                                    }
                                }
                            }
                        }
                    }            
                }
                
                .swiper-pagination {
                    display: flex;
                    column-gap: 7px;
                    justify-content: center;

                    .swiper-pagination-bullet {
                        opacity: 1;
                        background-color: $thirdColor ;
                        width: 8px;
                        height: 8px;

                        &.swiper-pagination-bullet-active {
                            background-color: $black;
                        }
                    }
                }
            }
        }
    }

    .basket_gondole {
        margin: 0 auto;
        margin-top: 42px;
        width: 98%;

        .title_gondole {
            margin-bottom: 10px;
        }
    }

    .thanksWrapper {
        display: flex;
        margin: 0 auto;
        width: 95%;
        max-width: 1283px;

        .bankThanksLeft {
            width: 58.6%;

            h1 {
                display: none;
            }

            #around_me_map {
                width: 96%;
                height: 546px;

                .gm-style-iw {
                    -webkit-box-shadow: none;
                    box-shadow: none;
                    border: 1px solid $black;
                    border-radius: 0;
                    width: 310px;

                    .gm-style-iw-chr {
                        position: absolute;
                        right: 0;
                    }

                    .gm-style-iw-d {
                        padding-top: 37px;
                        overflow: hidden !important;

                        .infoWindowContent {
                            padding-bottom: 34px;
                            padding-left: 17px;
                            color: $black;
                            font-weight: 300;
                            font-size: 16px;

                            .title {
                                margin-bottom: 10px;
                            }

                            br {
                                display: none;
                            }

                            strong {
                                color: $black;
                                font-weight: 300;
                            }
                        }
                    }
                }

                .gm-control-active,
                .gmnoprint,
                .gm-style-iw-tc,
                .gm-style-cc {
                    display: none;
                }

                .gm-ui-hover-effect {
                    span {
                        mask-image: none !important;
                        background-color: transparent;

                        &:after {
                            position: absolute;
                            right: 0;
                            margin: 5px 18px 0px 0px;
                            background-image: url('../img/maps/marker2.png');
                            background-color: $white;
                            width: 17px; 
                            height: 24px;
                            content: "";
                        }
                    }
                }
            }

            .thanks_order_bot {
                display: none;
            }

            .pushBasketWrapper {
                display: none;
            }
        }

        .bankThanksRight {
            flex-direction: column;
            justify-content: initial;
            width: 41.4%;
            
            h1 {
                margin-bottom: 19px;
                font-weight: normal;
                font-size: 16px;

                span {
                    .title {
                        display: block;
                        margin-top: -2px;
                        margin-bottom: 39px;
                        text-align: center;
                    }
                }
            }

            .conf_order_subtitle_2 {
                a {
                    color: $black;
                    text-decoration: none;
                }
            }

            .transaction_recap {
                display: flex;
                flex-direction: column;
                gap: 6px;
                margin-top: 28px;
                margin-bottom: 29px;
                border: 1px solid;
                padding: 30px 128px 27px 128px;
                text-align: left;

                .trader_name,
                .trader_address,
                .authorization_id,
                .txt_thanks_transaction {
                    display: none;
                }

                .transaction_date,
                .main_ref_transaction,
                .main_amount_transaction,
                .number_card {
                    margin-bottom: 5px;
                }

                .main_amount_transaction {
                    font-size: 16px;

                    label {
                        b {
                            font-weight: normal;
                        }
                    }
                }

                br {
                    display: none;
                }
            }

            .createPwdTxt {
                margin-bottom: 2px;
                text-align: left;
            }

            .w-form {
                margin-top: 3px;
                margin-bottom: 31px;

                .w-form-line {
                    margin-bottom: 0;

                    .w-input-container {
                        .w-input {
                            .w-input-element {
                                border: 0;
                                border-bottom: 1px solid $black !important;
                                border-radius: 0;
                                padding: 0;
                                padding-top: 27px;  
                                padding-bottom: 8px;
                                color: $black;
                                font-size: 16px;
                                font-family: $primaryFont;
                            }

                            .w-input-label {
                                padding-top: 27px;
                                color: rgba(0, 0, 0, 0.5);
                                font-family: $primaryFont;
                            }

                            &.w-focused {
                                .w-input-element {
                                    &:focus {
                                        outline: initial;
                                        box-shadow: none;
                                        padding: 0;
                                        padding-top: 17px;
                                    }
                                }
                            }

                            &.w-has-error {
                                .w-input-element {
                                    box-shadow: none;
                                }
                            }

                            .w-input-label {
                                left: 0;
                                padding-bottom: 8px;
                                color: $black;
                                font-size: 16px;
                                font-family: $primaryFont;
                            }

                            &.w-nonempty {
                                .w-input-label {
                                    margin-top: 12px;
                                    padding-top: 0;
                                    font-size: 12px;
                                }

                                .w-input-element {
                                    padding-top: 27px;
                                }
                            }

                            .w-visibility-toggle {
                                border: 0;
                                background-color: transparent;
                            }
                        }

                        .w-input-note {
                            margin: 0;
                        }
                    }

                    &.w-submit {
                        margin-top: 29px;
                    }
                }
            }

            .thanksLeft {
                width: 100%;

                .thanksLeftWrapper {
                    display: flex;
                    flex-direction: column-reverse;

                    .thanksTrust {
                        margin: 0;
                        margin-bottom: 37px !important;
                        text-align: center;
                    }
                    
                    .seeOrderEvo {
                        margin-bottom: 28px;

                        a {
                            color: $black;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}

body {
    &.cart {
        .main_wrapper {
            .main_ariane {
                display: none;
            }

            .wrapper_panier {
                #tunnel_right_col {
                    #bandeau_basket_premium {
                        display: flex;
                        position: relative;
                        right: 0;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 25px;
                        height: 43px;
                        color: $black;
                        font-size: 16px;
                        text-transform: capitalize;
                    }

                    .main_table  {
                        .bill_line {
                            &.total_produit {
                                &.under_dropdown {
                                    display: flex !important;
                                    margin-bottom: initial !important;
                                }

                                &.toggle {
                                    margin-bottom: initial !important;
                                }
                            }

                            &.discount {
                                margin-bottom: initial !important;
                            }

                            &.total {
                                margin-bottom: initial !important;
                            }
                        }
                    }
                }

                .basket_gondole {
                    display: none;
                }
            }
        }

        &.step_2,
        &.step_2_0 {
            #main_cart_wrapper {
                margin: 0 auto;
                width: 95%;
                max-width: 1291px;

                .left_side {
                    border: 0;
                    padding-left: 8px;
                    width: 63%;

                    .delivery_section_title {
                        margin-top: 3px;
                        margin-bottom: 27px;

                        &.delivery_mode {
                            margin-bottom: 4px;
                        }
                    }

                    .delivery_choice {
                        width: 102%;

                        &.transporteur_livraison-domicile {
                            .transporteur_id {
                                display: none;
                            }
                        }
                    }

                    .bloc_pack_illimitee {
                        display: none;
                    }
                }

                #tunnel_right_col {
                    padding: 0 0 0 87px;
                    width: 37%;

                    .main_table {
                        .upselling {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &.checkout {
        &.in_tunnel {
            .wrapper_tunnel {
                flex-direction: column;

                #outsite_ue_notice {
                    display: none;
                }

                #main_cart_wrapper {
                    display: flex;
                    justify-content: center;
                    margin: 0 auto;
                    width: 95%;
                    max-width: 1315px;

                    .bank_left_side {
                        padding-left: 18px;
                        width: 64%;

                        .method_wrapper,
                        .txt_recommencer_paiement {
                            margin-bottom: 15px;
                        }

                        .displayNone {
                            display: none;
                        }

                        .selectPaymentModeTitle {
                            margin-bottom: 1rem;
                            width: 100%;
                        }

                        .wrapper_bloc_banque {
                            display: none;
                            margin: 0;
                            margin-bottom: 24px;
                            padding: 0;

                            &.actif {
                                display: block !important;
                            }

                            .bank_title {
                                margin-bottom: 9px;
                                font-weight: 300;
                                font-size: 16px;
                            }

                            .bank_subtitle {
                                strong {
                                    font-weight: normal;
                                }
                            }

                            .cards-wrapper {
                                display: none;
                            }

                            .title_wrapper_fold {
                                margin-bottom: 7px;

                                .bank_title {
                                    display: none;
                                }

                                .bank_subtitle {
                                    .title {
                                        display: block;
                                        margin-bottom: 8px;
                                    }

                                    strong {
                                        font-weight: normal;
                                    }
                                }

                                .confirmPaiement {
                                    width: 278px;
                                }

                                .mode_sms {
                                    .info_sms {
                                        margin: 20px 0;

                                        .top {
                                            .mode_title {
                                                p {
                                                    margin-bottom: 8px;
                                                }
                                            }

                                            .w-input {
                                                .selected-flag {
                                                    bottom: 13%;
                                                    background: transparent;
                                                    padding: 0;
                                                }

                                                .tel_input {
                                                    border: 0;
                                                    border-bottom: 1px solid $black;
                                                    padding-bottom: 10px;
                                                    width: 100%;
                                                }
                                            }
                                        }
                                    }
                                }

                                .top {
                                     .w-input {
                                        .w-input-element {
                                            border: 0;
                                            border-bottom: 1px solid $black !important;
                                            border-radius: 0;
                                            padding: 0;
                                            padding-top: 27px;  
                                            padding-bottom: 8px;
                                            color: $black;
                                            font-size: 16px;
                                            font-family: $primaryFont;

                                            &:focus {
                                                box-shadow: none;
                                            }
                                        }

                                        .w-input-label {
                                            padding-top: 27px;
                                            color: rgba(0, 0, 0, 0.5);
                                            font-family: $primaryFont;
                                        }

                                        &.w-focused {
                                            .w-input-element {
                                                &:focus {
                                                    outline: initial;
                                                    box-shadow: none;
                                                    padding: 0;
                                                    padding-top: 18px;
                                                }
                                            }
                                        }

                                        &.w-has-error {
                                            .w-input-element {
                                                box-shadow: none;
                                            }
                                        }

                                        .w-input-label {
                                            left: 0;
                                            padding-bottom: 9px;
                                            color: $black;
                                            font-size: 16px;
                                            font-family: $primaryFont;
                                        }

                                        &.w-nonempty {
                                            .w-input-label {
                                                margin-top: 12px;
                                                padding-top: 0;
                                                font-size: 12px;
                                            }

                                            .w-input-element {
                                                padding-top: 26px;
                                            }
                                        }
                                    }
                                }
                            }

                            .alias_list {
                                margin: 0;
                                padding: 0;
                                padding-bottom: 16px;
                                
                                form {
                                    justify-content: flex-start;
                                    margin-left: 39px;
                                }

                                li {
                                    position: relative;
                                    border-bottom: 1px solid $black;
                                    padding: 20px 0;
                                    list-style: none;

                                    &:before {
                                        position: absolute;
                                        top: 50%;
                                        left: -40px;
                                        transform: translateY(-50%);
                                        margin-left: 38px;
                                        box-shadow: inset 0 0 0 10px $white;
                                        border: 1px solid $primaryColor;
                                        border-radius: 50%;
                                        background-color: $primaryColor;
                                        width: 14px;
                                        height: 14px;
                                        content: '';
                                    }
                                }

                                .selected {
                                    &:before {
                                        box-shadow: inset 0 0 0 2px $white;
                                    }
                                }
                                
                                .alias {
                                    .btnAliasWrapper {
                                        align-items: flex-end;

                                        .use_card {
                                            display: none;
                                        }

                                        .delete_card {
                                            margin-right: 23px;

                                            .delete {
                                                position: relative;
                                                top: 50%;
                                                transform: translateY(-50%);
                                                cursor: pointer;
                                                border: none;
                                                background-color: transparent;
                                                width: initial;
                                                height: 31px;
                                                font-size: 0;

                                                &:after {
                                                    background: url('../svg/spritesheet.svg') no-repeat center;
                                                    width: 14px;
                                                    height: 14px;
                                                    content: '';
                                                    @include bgIconCoord(22,8,5);
                                                    display: block;
                                                    position: absolute;
                                                }
                                            }
                                        }
                                    }

                                    .card_type {
                                        margin: 3px 0 0 14px;
                                        width: 43px;
                                    }

                                    .card_name {
                                        flex: initial;
                                        margin-left: 69px;
                                        width: 168px;
                                        text-transform: none;
                                    }

                                    .card_numbers {
                                        display: flex;
                                        flex: initial;
                                        margin-left: 49px;
                                        padding: 0;
                                        width: 182px;

                                        span {
                                            display: block !important;
                                        }
                                    }

                                    .card_expiration {
                                        display: block !important;
                                    }
                                }
                            }

                            #hipay-3-form {
                                padding: 0;

                                .hipay-3-row {
                                    margin-bottom: 3px;

                                    .hipay-3-field-container-half {
                                        width: 100%;
                                    }
                                }

                                .hipay-3-elements-container {
                                    .form_line {
                                        &.saveAlias {
                                            margin: 24px 0 30px 0;
                                        }

                                        #save_alias_hipay {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            opacity: 0;
                                            width: 1em;
                                            height: 1em;
                                        }

                                        label {
                                            position: relative;
                                            font-weight: 300;
                                            font-size: 16px;
                                            line-height: 1.7944rem;
                                            font-family: $primaryFont;
                            
                                            strong {
                                                font-weight: 300;
                                            }
                            
                                            span {
                                                font-size: 0;
                                            }
                            
                                            &::before {
                                                display: block;
                                                position: relative;
                                                transition: .2s ease-out;
                                                transition-property: background-color, box-shadow;
                                                margin-right: 15px;
                                                box-shadow: inset 0 0 0 0 $white;
                                                border-radius: 26px;
                                                background-color: $thirdColor;
                                                width: 26px;
                                                height: 14px;
                                                content: '';
                                            }

                                            &::after {
                                                display: block;
                                                position: absolute;
                                                top: 47%;
                                                left: 1px;
                                                transform: translate(0, -50%);
                                                transition: .2s ease-out;
                                                border-radius: 50%;
                                                background-color: $white;
                                                width: 13px;
                                                height: 13px;
                                                content: '';
                                            }
                                        }
                            
                                        input[type=checkbox]:checked {
                                            & + label::before {
                                                background-color: $black;
                                            }
                                        }
                            
                                        input[type=checkbox]:checked {
                                            & + label::after {
                                                left: 12px;
                                            }
                                        }
                                    }

                                    .button {
                                        width: 261px;
                                        font-weight: 300;
                                        font-size: 16px;
                                        font-family: $primaryFont;
                                        text-transform: none;
                                    }
                                }

                                .hipay-3-field-container {
                                    .hipay-3-label {
                                        position: relative;
                                        top: 8px;
                                    }

                                    .form_line {
                                        &.is_default {
                                            margin-bottom: 2rem;
                                        }
                                    }
                                }
                            }

                            .hipay-3-elements-container {
                                .form_line {
                                    &.is_default {
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;

                                        input[type="checkbox"] {
                                            display: none;

                                            + label {
                                                position: relative;
                                                color: $black;
                                                font-size: 16px;
                                                font-family: $primaryFont;

                                                &:before {
                                                    transition: none;
                                                    margin-right: 1em;
                                                    box-shadow: none;
                                                    border: 0;
                                                    border-radius: 48px;
                                                    background-color: $white;
                                                    width: 26px;
                                                    height: 15px;
                                                    content: '';
                                                }

                                                &:after {
                                                    position: absolute;
                                                    top: 2px;
                                                    left: 1px;
                                                    border-radius: 50%;
                                                    background-color: $white;
                                                    width: 13px;
                                                    height: 13px;
                                                    content: '';
                                                }
                                            }

                                            &:checked + label {
                                                &:before {
                                                    background-color: $primaryColor;
                                                }

                                                &:after {
                                                    left: 12px;
                                                }
                                            }
                                        }
                                    }
                                }

                                .button {
                                    cursor: pointer;
                                    margin-top: 28px;
                                    border: 1px solid $black;
                                    background: $black;
                                    font-size: 16px;
                                    font-family: $primaryFont;
                                    text-transform: none;

                                    &:hover {
                                        background-color: $white;
                                        color: $black;
                                    }

                                    &:focus {
                                        background-color: $black;
                                        color: $white;

                                        &:hover {
                                            background-color: $white;
                                            color: $black;
                                        }
                                    }
                                }

                                .hipay-loader {
                                    margin-top: 28px;
                                }
                            }

                            &.paypal_hipay {
                                display: none;
                            }

                            &.bancontact  {
                                display: none;
                            }

                            &.gift_card {
                                .w-form {
                                    &.w-giftcard-form {
                                        display: flex;
                                        flex-direction: column;
                                        width: 100%;

                                        .giftcard_desc {
                                            font-size: 16px;
                                        }

                                        .w-form-line {
                                            margin-bottom: 0;

                                            &.giftcard-intro {
                                                display: flex;
                                                flex-direction: column;
                                                align-items: flex-start;

                                                .w-submit-button {
                                                    display: none;
                                                }
                                            }

                                            .w-input-container {
                                                .w-input {
                                                    .w-input-element {
                                                        border: 0;
                                                        border-bottom: 1px solid $black !important;
                                                        border-radius: 0;
                                                        padding: 0;
                                                        padding-top: 27px;  
                                                        padding-bottom: 8px;
                                                        color: $black;
                                                        font-size: 16px;
                                                        font-family: $primaryFont;
                                                    }

                                                    .w-input-label {
                                                        padding-top: 27px;
                                                        color: rgba(0, 0, 0, 0.5);
                                                        font-family: $primaryFont;
                                                    }

                                                    &.w-focused {
                                                        .w-input-element {
                                                            &:focus {
                                                                outline: initial;
                                                                box-shadow: none;
                                                                padding: 0;
                                                                padding-top: 18px;
                                                            }
                                                        }
                                                    }

                                                    &.w-has-error {
                                                        .w-input-element {
                                                            box-shadow: none;
                                                        }
                                                    }

                                                    .w-input-label {
                                                        left: 0;
                                                        padding-bottom: 9px;
                                                        color: $black;
                                                        font-size: 16px;
                                                        font-family: $primaryFont;
                                                    }

                                                    &.w-nonempty {
                                                        .w-input-label {
                                                            margin-top: 12px;
                                                            padding-top: 0;
                                                            font-size: 12px;
                                                        }

                                                        .w-input-element {
                                                            padding-top: 26px;
                                                        }
                                                    }
                                                }

                                                .w-input-note {
                                                    margin: 0;
                                                }
                                            }
                                        }

                                        .giftcard_desc {
                                            width: 100%;
                                        }
                                    }
                                }
                            }

                            &.alma {
                                .bank_subtitle {
                                    display: none;
                                }
                            }

                            &.qr_code {
                                &.actif {
                                    .mode_qr_code {
                                        width: 25%;

                                        img {
                                            border: 1px solid;
                                        }
                                    }
                                }
                            }

                            &.applepay {
                                #hipay-apple-pay-button {
                                    position: relative;
                                    margin: 0;
                                    padding: 0;
                                    max-width: 18rem;
                                    height: 3rem;
                                    overflow: hidden;

                                    iframe {
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        bottom: 0;
                                        left: 0;
                                        margin: auto;
                                        border: 1px solid $black;
                                        width: 100%;
                                        height: 2rem;
                                    }
                                }
                            }

                            .bloc_bank_contents {
                                #inputPAYPAL {
                                    display: none;
                                }
                            }
                        }

                        #bloc_payment_cancel {
                            width: 278px;
                        }

                        .wrapper_payment_mode {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 1rem;
                            margin-bottom: 2rem;

                            .payment_mode_btn {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                transition: all .3s ease-in-out;
                                cursor: pointer;
                                border: 1px solid $black;
                                background-color: $white;
                                padding: 0 24px;
                                min-width: 150px;
                                height: 50px;
                                color: $primaryColor;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 18px;
                                text-transform: none;

                                &:hover,
                                &.actif {
                                    border: 1px solid $primaryColor;
                                    background-color: $primaryColor;

                                    span {
                                        color: $white;
                                    }
                                }

                                span {
                                    display: flex;
                                    position: relative;
                                    margin-left: 0px;
                                    font-weight: 300;
                                    font-size: 16px;
                                    font-family: $primaryFont;
                                                
                                    &:before {
                                        display: block;
                                        position: relative;
                                        top: auto;
                                        right: auto;
                                        bottom: auto;
                                        left: auto;
                                        content: '';
                                    }
                                }

                                &[data-payment-mode="hosted_hipay"] {
                                    span:before {
                                        filter: invert;
                                        margin-right: 10px;
                                        background: transparent url(../svg/credit-card.svg) no-repeat center;
                                        background-size: contain;
                                        width: 21px;
                                    }

                                    &:hover span:before,
                                    &.actif span:before {
                                        margin-right: 10px;
                                        background: transparent url(../svg/credit-card-w.svg) no-repeat center;
                                        background-size: contain;
                                        width: 21px;
                                    }
                                }

                                &[data-payment-mode="paypal_hipay"] {
                                    span:before {
                                        margin-right: 5px;
                                        background: transparent url(../svg/paypal.svg) no-repeat center;
                                        width: 23px;
                                    }
                                }

                                &[data-payment-mode="applepay_hipay"] {
                                    span:before {
                                        margin-right: 5px;
                                        background: transparent url(../svg/apple-pay.svg) no-repeat center;
                                        width: 45px;
                                    }

                                    &:hover span:before,
                                    &.actif span:before {
                                        background: transparent url(../svg/apple-pay-w.svg) no-repeat center;
                                        width: 56px;
                                    }
                                }

                                &[data-payment-mode="bancontact_hipay"] {
                                    span:before {
                                        margin-top: 5px;
                                        margin-right: 10px;
                                        background: transparent url(../svg/bancontact.svg) no-repeat center;
                                        width: 25.35px;
                                        height: 10px;
                                    }
                                }

                                &[data-payment-mode="alma_inpage_payment_2"], 
                                &[data-payment-mode="alma_inpage_payment_3"] {
                                    span:before {
                                        margin-right: 8px;
                                        background: transparent url(../svg/alma.svg) no-repeat center;
                                        width: 40px;
                                    }

                                    &:hover span:before,
                                    &.actif span:before {
                                        background: transparent url(../svg/alma-w.svg) no-repeat center;
                                        width: 40px;
                                    }
                                }

                                &[data-payment-mode="alma_inpage_payment_4"], 
                                &[data-payment-mode="alma_inpage_payment_3"] {
                                    span:before {
                                        margin-right: 8px;
                                        background: transparent url(../svg/alma.svg) no-repeat center;
                                        width: 40px;
                                    }

                                    &:hover span:before,
                                    &.actif span:before {
                                        background: transparent url(../svg/alma-w.svg) no-repeat center;
                                        width: 40px;
                                    }
                                }

                                &[data-payment-mode="payment_gift_card"] {
                                    order: 1;
                                    
                                    span:before {
                                        background: url(../svg/carte-cadeau.svg) no-repeat center;
                                        width: 27px;
                                    }

                                    &:hover span:before,
                                    &.actif span:before {
                                        background: url(../svg/carte-cadeau-w.svg) no-repeat center;
                                        width: 27px;
                                    }
                                }
                            }
                        }

                        #addAliasListElem {
                            cursor: pointer;
                            margin-bottom: 29px;
                        }

                        .wrapperSelectAlias {
                            width: 262px;
                        }

                        &:has(.alias_list_wrapper.actif) {
                            .credit_card {
                                .title_wrapper_fold {
                                    display: none;
                                }
                            }
                        }

                        #paymentLinkSent {
                            p {
                                margin-bottom: 20px;
                            }

                            .title_wrapper_fold {
                                width: 278px;
                            }
                        }
                    }

                    #tunnel_right_col {
                        padding: 0 14px 0 69px;
                        width: 36%;

                        .main_table  {
                            gap: initial;

                            .recap_cart_title {
                                display: none;
                            }

                            .wrapper_payment {
                                display: flex;
                                flex-direction: column;
                                gap: 1rem;
                                margin-bottom: 2rem;

                                .payment_addr_wrapper {
                                    position: relative;

                                    a {
                                        position: absolute;
                                        top: 50%;
                                        right: 0;
                                        transform: translateY(-50%);
                                        color: $black;
                                    }

                                    .address {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: space-between;
                                        padding-right: 70px;

                                        a {
                                            color: $black;
                                        }

                                        .address_mod {
                                            line-height: 1;
                                            text-decoration: none;
                                        }
                                    }

                                    .address_mod {
                                        border-bottom: 1px solid $black;
                                        line-height: 1;
                                        text-decoration: none;
                                    }

                                    &.conveyor_infos {
                                        .title {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            .bill_line {
                                .product_line_basket {
                                    .product_mod {
                                        color: $black;
                                        text-decoration: none;
                                    }
                                }
                            }

                            .upselling {
                                strong {
                                    font-weight: normal;
                                }

                                progress {
                                    border: 1px solid $black;
                                    background-color: $white;
                                    width: 100%;
                                    height: 9px;

                                    &::-webkit-progress-bar,
                                    &::-moz-progress-bar {
                                        background-color: $white;
                                    }
                                }

                                progress::-webkit-progress-bar {
                                    background-color: $white;
                                }

                                progress::-moz-progress-bar {
                                    background-color: $white;
                                }

                                progress::-webkit-progress-value {
                                    background-color: $black;
                                }
                            }
                        }

                        .wrap_bottom_rightcol {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &.en {
        .product_line_basket {
            &.out_of_stock {
                .cart_product_pic {
                    &::after {
                        content: 'Available Soon';
                    }
                }
            }
        }
    }

    // IPAD AIR
    @media only screen and (max-width: 820px) {
        &.cart {
            &.step_1 {
                .main_wrapper {
                    #main_cart_wrapper {
                        margin: 1px 0 0 40px;
    
                        .left_side {
                            width: 68%;

                            .cart_product_title {
                                font-size: 18px !important;
                            }
                        }
    
                        #tunnel_right_col {
                            width: 32%;
                            padding: 0 15px;
                        }
                    }
                }
            }

            &.step_2 {
                #main_cart_wrapper {
                    .left_side {
                        width: 60%;
                    }

                    #tunnel_right_col {
                        padding: 0 0 0 41px;
                        width: 40%;
    
                        .deconnection_tunnel {
                            p {
                                margin-right: 6px;
                            }
                        }
                    }
                }
            }

            &.step_2_0 {
                #main_cart_wrapper {
                    .left_side {
                        width: 58%;
                    }

                    #tunnel_right_col {
                        padding: 0 0 0 25px;
                        width: 42%;
                    }
                }
            }
        }

        &.checkout.in_tunnel {
            .wrapper_tunnel {
                #main_cart_wrapper {
                    #tunnel_right_col {
                        padding: 0 15px;
    
                        .deconnection_tunnel {
                            p {
                                margin-right: 6px;
                            }
                        }
                    }
                }
            }

            .thanksWrapper {
                .transaction_recap {
                    padding: 30px 82px 27px;
                }
            }
        }
    }
}

.copied-text-notification {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    animation: slideIn 0.3s forwards;
    transition: transform 0.3s ease-in-out;
    margin: 10px auto;
    background-color: $primaryColor;
    padding: 10px 0;
    width: fit-content;
    min-width: 30ch;
    color: $white;
    font-weight: 300;
    font-size: 16px;
    font-family: $primaryFont;
    text-align: center;

    &.slide-out {
        animation: slideOut 0.3s forwards;
    }
}

.w-input-element {
    font-weight: 200 !important;
}

.formPanier_container:has(#applepay_express_checkout) {
    #applepay_express_checkout {
        --apple-pay-button-width: 100%;
        --apple-pay-button-height: 38px;
        --apple-pay-button-border-radius: 0;
        margin-top: 1rem;
        width: calc(100% - 2px);
    }
}

#hipay_applepay_bloc {
    padding: 1.5rem 2.5rem;

    .validateButtonWrapper, .title_wrapper_fold .total_transaction {
        display: none;
    }

    .bank_subtitle {
        font-family: $primaryFont;
        letter-spacing: 0;
        text-align: center;
        text-decoration: underline;
        
        strong {
            font-size: 13px;
        }
    }

    &.actif {
        .bank_subtitle {
            display: none;
        }

        .title_wrapper_fold {
            padding: 0;

            .total_transaction {
                display: block;
            }
        }

        .validateButtonWrapper {
            display: block;
            margin-top: 1rem;
    
            .validateButton {
                border-radius: 5px;
                background-color: $primaryColor;
                width: 100%;
                height: 40px;
                color: $white;
                font-weight: 700;
                font-family: $primaryFont;
            }
        }
    }
}