@import '../../_app/_src/scss/desktop/mixins/typeFace';

$futura_lt_pro: (
    normal: (light, medium, bold)
);

@include typeFace('futura_lt_pro', $futura_lt_pro);

$primaryFont: 'futura_lt_pro', sans-serif;

$light: 300;
$medium: 500;
$bold: 700;