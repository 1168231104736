
.faq {
    h1.title {
        text-align: center;
        font-family: $primaryFont;
        font-size: 32px;
        font-weight: 700;
    }

    .faq_page_search {
        .w-input-container {
            display: flex;
    
            .w-input-element {
                height: 29px;
                border: initial;
                border-bottom: 1px solid;
                border-radius: initial;
                padding: initial;
    
                &:focus {
                    border: 0 none!important;
                    border-bottom: 1px solid!important;
                    box-shadow: none!important;
                }
    
                &:focus-visible {
                    outline: none;
                }
            }

            .w-input {
                #faq_search {
                    &::placeholder {
                        color: rgba(0, 0, 0, 0.5);
                    }
                }

                .w-input-element {
                    font-size: 16px;
                    font-family: $primaryFont;
                    color: $black;
                }
            }
    
            &:after {
                content: "";
                width: 14px; 
                height: 14px;
                background-image: url('../svg/spritesheet.svg');
                @include bgIconCoord(6,8,5);
                position: absolute;
                right: 0;
            }
        }
    }

    .site_search {
        width: 100%;
        margin: 1.7rem auto;
        position: relative;

        #faq_search {
            &::placeholder {
                font-family: $primaryFont;
                font-weight: 300;
                color: $black;
                opacity: 50;
                font-size: 16px;
            }
        }

        .search_results {
            display: none;
            background: $white;
            position: relative;
            width: 100%;
            z-index: 2;
            font-size: 16px;
            letter-spacing: 0.07rem;
            transition: all 0.2s ease-out;
            padding-top: 1rem;

            ul {
                padding: 0;

                li {
                    min-height: 2.5rem;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    cursor: pointer;
                }
            }
        }
    }

    .theme_page {
        overflow: hidden;
        transition: opacity 0.5s ease-in;
        width: 100%;
        margin: auto;

        &.actif {
            height: auto;
            opacity: 1;
        }

        .theme_detail .faq_title,
        .theme_detail .faq_question {
            cursor: pointer;
        }

        .theme_detail {
            width: 100%;
            margin-bottom: 1.875rem;

            .faq_element.active {
                .faq_title {
                    &:after {
                        background: url('../svg/spritesheet.svg');
                        @include bgIconCoord(20,8,5);
                    }

                    border: 0;
                }

                .faq_questions {
                    padding-top: 0.2rem;
                }
            }
            
            .faq_element {
                border-bottom: 1px solid rgba($black, 0.2);

                .faq_questions {
                    padding-left: 1rem;
                }
            }

            .faq_title {
                display: block;
                position: relative;
                cursor: pointer;
                padding: 0;
                color: $black;
                font-family: $primaryFont;
                font-size: 16px;
                outline: none;
                height: 100%;
                font-weight: 300;
                line-height: 19.2px;

                &:after {
                    content: '';
                    position: absolute;
                    top: 15%;
                    right: 0;
                    width: 14px;
                    height: 14px;
                    background: url('../svg/spritesheet.svg') no-repeat center;
                    @include bgIconCoord(21,8,5);
                }

                & h2,
                & h3 {
                    text-align: left;
                    color: $black;
                    font-family: $primaryFont;
                    font-size: 16px;
                    height: 26px;
                    font-weight: 300;
                    line-height: 19.2px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin: 15.5px 0;
                }
            }

            &.active {
                .item_title {
                    &:after {
                        transform: rotate(0);
                    }
                }

                .block_question {
                    display: block;
                }
            }

            div.faq_questions {
                .faq-reponse {
                    text-align: left;
                }

                .block_item_question {
                    position: relative;
                    margin-bottom: 1rem;
                    font-size: 16px;
                }

                .faq_question {
                    color: $black;
                    font-family: $primaryFont;
                    outline: 0;
                    cursor: pointer;
                    display: block;
                    width: 100%;
                    font-size: 16px;
                    font-weight: 300;
                    height: 23px;

                    &.active {
                        text-decoration: underline;

                        &:after {
                            top: 6%;
                            transform: rotate(0);
                        }
                    }
                }

                .faq_reponse {
                    display: none;
                    color: $black;
                    font-size: 16px;
                    font-family: $primaryFont;
                    font-weight: 300;
                    padding-top: 0.45rem;
                    line-height: 1.6;
                    padding-left: 1.5rem;

                    li {
                        line-height: 2;
                    }

                    a {
                        color: $black;
                    }

                    table {
                        border: 1px solid $black;
                        border-spacing: 0;
                    }

                    strong {
                        -webkit-text-stroke-width: 0.75px;
                        font-weight: 300;
                    }
                }
            }

            .other_question {
                display: none;
                color: $primaryColor;
                padding-top: 42px;
                font-size: 14px;
                letter-spacing: 0.03rem;
                line-height: 1.4;

                span {
                    font-weight: 600;
                }

                p:first-of-type {
                    font-size: 0.75rem;
                    text-transform: uppercase;
                    font-weight: bold;
                    margin-bottom: 13px;
                }
            }
        }
    }

    .message_mail,
    .wrapperErrors {
        text-align: left;
    }

    
    .faq_content {
        width: 95%;
        max-width: 1284px;
        margin: 0 auto;

        .contact_faq {
            padding-top: 0.5rem;    
        }
    }   
}

body {
    &.faq {
        .faq_wrapper {
            margin-top: 2.8rem;
        }
    }
}