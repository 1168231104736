.bgSprite {
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
        content: '';
        width: 100%;
        height: 100%;
    }

    @include spriter('../svg/spritesheet.svg', 8, 5, (
            1:  'facebook',
            2:  'instagram',
            3:  'linkedin',
            4:  'tiktok',
            5:  'arrow_down',
            6:  'search',
            7:  'locator',
            8:  'locator_full',
            9:  'wishlist',
            10: 'wishlist_full',
            11: 'account',
            12: 'account_full',
            13: 'cart',
            14: 'cart_full',
            15: 'livraison_domicile',
            16: 'livraison_boutique',
            17: 'phone',
            18: 'question',
            19: 'paiement',
            20: 'moins',
            21: 'plus',
            22: 'close',
            23: 'delivery',
            24: 'return',
            25: 'target',
            26: 'star',
            27: 'share',
            28: 'search_full',
            29: 'swith_view',
            30: 'swith_view_full',
            31: 'data_status_3',
            32: 'data_status_2',
            33: 'data_status_1'
        )
    );
}