#site_head_wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 34;
    background-color: $white;
    border-bottom: 1px solid $thirdColor;

    #GlobaleFreeShippingBannerContainer {
        height: 44px;
    }

    .promo_bar {
        position: relative;
        width: 100%;

        .promo_swiper {
            display: block;
            width: 100%;
            background-color: $grey;
            text-align: center;

            .swiper-wrapper {
                .swiper-slide {
                    height: 44px;
                    font-family: $primaryFont;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    color: $black;
                    border-bottom: 1px solid $grey;

                    a {
                        color: $black;
                    }

                    &>div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-wrap: wrap;
                        height: 100%;
                        width: 100%;

                        a>span {
                            text-decoration: underline;
                        }
                    }

                    p {
                        margin: 0 auto;
                    }
                }
            }
        }

        .close_promo {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 1rem;
            display: block;
            width: 16px;
            height: 16px;
            font-size: 0;
            background-image: url(../svg/spritesheet.svg);
            @include bgIconCoord(22, 8, 5);
            margin: auto;
            z-index: 2;
            cursor: pointer;
        }
    }

    &:not(.sticky) {
        #GlobaleFreeShippingBannerContainer:not(:empty) {
            & + .promo_bar:not(:empty) {
                display: block !important;
            }
        }
    }

    .headerContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 1920px;
        background-color: $white;
        margin: 0 auto;
        padding: 0 20px 0 15px;

        .logo_lang_wrapper {
            display: flex;
            align-items: center;
            min-width: 240px;

            .headerLogo {
                display: block;
                width: 81px;
                height: 56px;
                background-image: url(../svg/zapa.svg);
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                margin-right: 1rem;

                h1,
                .alt_logo {
                    font-size: 0;
                }

                @media only screen and (max-width: 1457px) {
                    margin-right: 32px;
                }

                @media only screen and (max-width: 1270px) {
                    margin-right: 20px;
                }
            }

            .backdropArea {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 57px;
                height: 56px;

                .switchLang {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    font-family: $primaryFont;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;

                    .switchLangBtn {
                        width: 100%;

                        .switchLangWrapper {
                            width: 100%;
                        }
                    }

                    .active_lang {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        cursor: pointer;
                        z-index: 3;
                        background-color: $white;

                        &:after {
                            content: '';
                            position: relative;
                            display: block;
                            width: 14px;
                            height: 14px;
                            background-image: url(../svg/spritesheet.svg);
                            @include bgIconCoord(5, 8, 5);
                            margin-left: 8px;
                            transition: transform ease-in-out 0.5s;
                        }
                    }

                    &:hover {
                        .active_lang {
                            &:after {
                                transform: rotate(180deg);
                            }

                            &+.lang_itm {
                                top: 100%;
                                height: 51px;
                                z-index: 2;
                                border-color: rgba($black, 0.25);
                                transition: top linear 0.35s;
                                display: flex;
                            }
                        }
                    }
                }

                .lang_itm {
                    position: absolute;
                    top: 30%;
                    display: none;
                    align-items: center;
                    width: 57px;
                    height: 0;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    color: $black;
                    background-color: $white;
                    padding: 10px;
                    border: 1px solid $white;
                    border-top: 0;
                    text-decoration: none;
                    transition: top ease-in-out 0.5s;

                    span {
                        display: inline-block;
                        line-height: 1;
                        border-bottom: 1px solid $black;
                    }
                }
            }

            @media only screen and (max-width: 1160px) {
                margin-right: 0;
            }
        }

        #wrapper_top_menu {
            width: auto;
            max-width: 920px;

            @media only screen and (max-width: 1270px) {
                position: fixed;
                right: 0;
                display: block;
                width: 347px;
                height: calc(100vh - 56px);
                height: calc(100dvh - 56px);
                background-color: $white;
                padding: 40px 20px 40px 40px;
                border-top: 1px solid $black;
                border-left: 1px solid $black;
                border-bottom: 1px solid $black;
                transform: translateX(100%);
                transition: 
                    transform ease-in-out 0.5s,
                    top 0.5s ease-in-out;

                &.actif {
                    transform: translateX(0%);
                }
            }
        }

        .block_top_links {
            display: inline-flex;
            align-items: center;
            justify-content: flex-end;
            height: 56px;

            .headerRollover {
                display: flex;
                align-items: center;
                width: 15px;
                height: 56px;

                a.rolloverTxt {
                    position: relative;
                    height: 100%;
                    width: 24px;
                    cursor: pointer;

                    &:before {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 16px;
                        height: 16px;
                        background-image: url(../svg/spritesheet.svg);
                    }

                    .nbProd {
                        position: absolute;
                        bottom: 4px;
                        font-family: $primaryFont;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        color: $black;
                    }

                    &:focus-visible {
                        box-shadow: inherit;
                        border: none;
                        outline: none;
                    }
                }

                #show_top_cart,
                #topLogin,
                #show_top_wish {
                    position: fixed;
                    top: 100px;
                    right: 0;
                    display: block;
                    width: 618px;
                    height: calc(100vh - 56px);
                    height: calc(100dvh - 56px);
                    background-color: $white;
                    border-top: 1px solid rgba($black, 0.2);
                    border-left: 1px solid rgba($black, 0.2);
                    border-bottom: 1px solid rgba($black, 0.2);
                    transform: translateX(100%);
                    opacity: 0;
                    transition: all ease-in-out 0.5s;
                }

                /* Rollover Panier */
                #show_top_cart {
                    padding-top: 40px;
                    height: 100% !important;

                    .rollover_title,
                    .no_product {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        text-align: center;
                        margin-bottom: 40px;
                    }

                    .cart_list_wrapper {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 0 17px 10px 70px;
                        margin-bottom: 10px;

                        #cart_list {
                            display: block;
                            width: 100%;
                            height: calc(100vh - 320px);
                            height: calc(100dvh - 320px);
                            max-height: 540px;
                            padding: 0 18px 0 0;
                            overflow: hidden;
                            list-style-type: none;
                            -ms-overflow-style: none;
                            scrollbar-width: none;

                            .product-link {
                                display: flex;
                                margin-bottom: 15px;

                                .cart_thumb_holder {
                                    position: relative;
                                    display: block;
                                    width: 208px;
                                    height: 260px;
                                    background-color: $grey;
                                    margin-right: 30px;

                                    img {
                                        width: 100%;
                                        height: auto;
                                    }
                                }

                                .cart_detail_box {
                                    .cart_product {
                                        height: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: space-between;

                                        a {
                                            color: $black;
                                            text-decoration: none;
                                        }

                                        .wrap_top_info {
                                            display: flex;
                                            flex-direction: column;
                                            row-gap: 0.5rem;
                                            font-family: $primaryFont;
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 300;
                                            line-height: normal;
                                            color: $black;
                                            text-decoration: none;

                                            .title {
                                                font-size: 20px;
                                                font-weight: 600;
                                            }
                                        }

                                        .sub_quantity,
                                        .sub_couleur {
                                            display: none;
                                        }

                                        .bask_action {
                                            margin-top: 10px;

                                            a,
                                            .remove_prod,
                                            #topBasketToWish {
                                                display: block;
                                                text-align: left;
                                                cursor: pointer;
                                                line-height: 1;

                                                span {
                                                    border-bottom: 1px solid $black;
                                                    line-height: 1;
                                                }
                                            }

                                            .remove_prod {
                                                margin-top: 10px;
                                            }

                                            .wishlist,
                                            .cross.remove_prod {
                                                font-size: 0.9rem;

                                                span {
                                                    display: inline-block;
                                                }
                                            }
                                        }
                                    }
                                }

                                &:last-of-type,
                                &.last {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }

                    .wrap_tot_panier {
                        padding: 30px 70px 0;
                        border-top: 1px solid rgba($black, 0.2);

                        .cart_panier_total {
                            position: relative;
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            margin-bottom: 30px;
                        }

                        .form_submit {
                            position: relative;
                            display: block;
                            width: 100%;
                            max-width: 320px;
                            margin: auto;
                        }
                    }

                    .upselling {
                        display: flex !important;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        text-align: center;
                        margin-top: 15px;

                        #threshold_fdp_txt {
                            font-weight: 300;
                        }

                        progress {
                            width: 100%;
                            max-width: 320px;
                            appearance: none;
                            height: 9px;
                            vertical-align: top;
                            border: 1px solid $black;
                            background-color: $white;
                            margin-top: 10px;
                            overflow: hidden;
                        }

                        progress::-webkit-progress-bar {
                            background-color: $white;
                        }

                        progress::-webkit-progress-value {
                            background-color: $black;
                        }

                        progress::-moz-progress-bar {
                            background-color: $black;
                        }
                    }

                    &:has(.product-link:nth-child(2)) {
                        padding-top: 30px;

                        .rollover_title {
                            margin-bottom: 30px;
                        }
                    }

                    &:has(.upselling) {
                        .cart_list_wrapper {
                            #cart_list {
                                height: calc(100vh - 360px);
                                height: calc(100dvh - 360px);
                            }
                        }
                    }

                    &.open {
                        transform: translateX(0);
                        opacity: 1;
                    }
                }

                /* Rollover mon compte */
                #topLogin {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding-top: 40px;

                    .wrapper_menu_compte_container {
                        .account_name {
                            display: block;
                            width: 100%;
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                            text-align: center;
                            color: $black;
                            text-decoration: none;
                            margin-bottom: 40px;

                            span {
                                display: none;
                            }
                        }

                        .wrapper_menu_compte {
                            padding: 0 70px 30px;

                            a {
                                display: block;
                                width: 100%;
                                font-family: $primaryFont;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: normal;
                                color: $black;
                                text-decoration: none;
                                margin-bottom: 10px;
                                transition: all ease-in-out 0.5s;

                                &:hover {
                                    -webkit-text-stroke-width: 0.75px;
                                }
                            }
                        }

                        .btn_container {
                            position: relative;
                            width: 320px;
                            margin: 0 auto 57px;
                        }
                    }

                    .img_account {
                        img {
                            display: block;
                            width: 100%;
                            height: auto;
                        }
                    }
                }

                /* Rollover wishlist */
                #show_top_wish {
                    padding-top: 30px;

                    .rollover_title,
                    .no_product {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        text-align: center;
                        margin-bottom: 30px;

                        .wrap_title {
                            .title {
                                color: $black;
                                text-decoration: none;
                            }
                        }
                    }

                    .cart_list_wrapper {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 0 17px 10px 70px;
                        margin-bottom: 10px;

                        #cart_list_wish {
                            display: block;
                            width: 100%;
                            height: calc(100vh - 300px);
                            height: calc(100dvh - 300px);
                            max-height: 560px;
                            padding: 0 20px 0 0;
                            overflow: auto;
                            list-style-type: none;
                            -ms-overflow-style: none;
                            scrollbar-width: none;

                            .product-link {
                                display: flex;
                                margin-bottom: 20px;

                                .cart_thumb_holder {
                                    position: relative;
                                    display: block;
                                    width: 208px;
                                    height: 260px;
                                    background-color: $grey;
                                    margin-right: 30px;

                                    img {
                                        width: 100%;
                                        height: auto;
                                    }
                                }

                                .cart_detail_box {
                                    .cart_product {
                                        height: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: space-between;

                                        a {
                                            color: $black;
                                            text-decoration: none;
                                        }

                                        .wrap_top_info {
                                            display: flex;
                                            flex-direction: column;
                                            row-gap: 0.5rem;
                                            font-family: $primaryFont;
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 300;
                                            line-height: normal;
                                            color: $black;
                                            text-decoration: none;

                                            .title {
                                                font-size: 20px;
                                                font-weight: 600;
                                            }
                                        }

                                        .sub_quantity,
                                        .sub_couleur {
                                            display: none;
                                        }

                                        .bask_action {
                                            margin-top: 10px;

                                            a,
                                            .remove_top_wishlist,
                                            #topWishTobasket {
                                                display: block;
                                                text-align: left;
                                                text-decoration: underline;
                                                cursor: pointer;
                                            }

                                            .remove_top_wishlist {
                                                margin-top: 10px;
                                            }
                                        }
                                    }
                                }

                                &.last {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }

                    .wrap_tot_panier {
                        padding: 30px 70px 18px;
                        border-top: 1px solid rgba($black, 0.2);

                        .cart_panier_total {
                            position: relative;
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            margin-bottom: 30px;
                        }

                        .form_submit {
                            position: relative;
                            display: block;
                            width: 100%;
                            max-width: 320px;
                            margin: auto;

                            .button {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                height: 41px;
                                text-decoration: none;

                                span {
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 300;
                                    line-height: normal;
                                }

                                &+.loader {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                }
                            }

                            &+.form_submit {
                                text-align: center;

                                .button {
                                    display: inline-block;
                                    height: auto;
                                    width: auto;
                                    margin-top: 20px;
                                    color: $black;
                                    background-color: $white;
                                    border: none;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }

                &#distrib_top {
                    width: 12px;

                    a.rolloverTxt {
                        &:before {
                            width: 16px;
                            height: 16px;
                            @include bgIconCoord(7, 8, 5);
                        }
                    }

                    &:hover {
                        a.rolloverTxt {
                            &:before {
                                @include bgIconCoord(8, 8, 5);
                            }
                        }
                    }
                }

                &#wishlist_top {
                    margin-left: 16px;

                    a.rolloverTxt {
                        padding-bottom: 2px;

                        &:before {
                            width: 19px;
                            height: 16px;
                            @include bgIconCoord(9, 8, 5);
                        }
                    }

                    &:hover,
                    &.hover {
                        a.rolloverTxt {
                            &:before {
                                @include bgIconCoord(10, 8, 5);
                            }
                        }

                        #show_top_wish {
                            transform: translateX(0%);
                            opacity: 1;
                        }
                    }
                }

                &#account_top {
                    margin-left: 16px;

                    a.rolloverTxt {
                        &:before {
                            width: 16px;
                            height: 16px;
                            @include bgIconCoord(11, 8, 5);
                        }
                    }

                    &:hover {
                        a.rolloverTxt {
                            &:before {
                                @include bgIconCoord(12, 8, 5);
                            }
                        }

                        #topLogin {
                            transform: translateX(0%);
                            opacity: 1;
                        }
                    }
                }

                &#cart_top {
                    width: 12px;
                    margin-left: 16px;

                    a.rolloverTxt {
                        &:before {
                            width: 16px;
                            height: 16px;
                            @include bgIconCoord(13, 8, 5);
                        }
                    }

                    &:hover,
                    &.hover {
                        a.rolloverTxt {
                            &:before {
                                @include bgIconCoord(14, 8, 5);
                            }
                        }

                        #show_top_cart {
                            transform: translateX(0%);
                            opacity: 1;
                        }
                    }

                    @media only screen and (max-width: 1270px) {
                        margin-left: 18px;
                    }
                }
            }

            .menu_tablet {
                display: none;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;
                margin-left: 30px;

                span {
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: $black;
                    transition: transform ease-in-out 0.5s;
                }

                @media only screen and (max-width: 1270px) {
                    position: relative;
                    display: flex;
                    width: 25px;
                    height: 15px;
                    border: none;
                    cursor: pointer;
                }

                &.open {
                    span {
                        &:nth-child(1) {
                            transform: translateY(7px) rotate(45deg);
                        }

                        &:nth-child(2) {
                            opacity: 0;
                        }

                        &:nth-child(3) {
                            transform: translateY(-7px) rotate(-45deg);
                        }
                    }
                }
            }
        }

        #form_recherche {
            margin-right: 35px;

            #trigger_recherche {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                color: rgba($black, 0.5);
                cursor: pointer;

                &:before {
                    content: '';
                    display: block;
                    width: 16px;
                    height: 16px;
                    background-image: url(../svg/spritesheet.svg);
                    @include bgIconCoord(6, 8, 5);
                    margin-right: 14px;
                }
            }

            .wrap_search_field {
                position: fixed;
                right: 0;
                top: 56px;
                display: block;
                width: 100%;
                max-width: 345px;
                height: calc(100vh - 56px);
                height: calc(100dvh - 56px);
                background-color: $white;
                padding: 100px 66px;
                border-top: 1px solid $black;
                border-left: 1px solid $black;
                border-bottom: 1px solid $black;
                transform: translateX(100%);
                transition: transform ease-in-out 0.5s;

                &.actif {
                    transform: translateX(0%);
                }

                .wrap_field {
                    position: relative;
                    padding-top: 100px;

                    #search_home {
                        width: 100%;
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        color: $black;
                        padding: 10px 2px;
                        margin-bottom: 20px;
                        border: none;
                        border-bottom: 1px solid $black;
                        border-radius: 0;

                        &::placeholder {
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                            color: $black;
                        }
                    }

                    .search_ok {
                        display: none;
                    }

                    .closeBtn {
                        position: absolute;
                        width: 16px;
                        height: 16px;
                        top: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        background-image: url(../svg/spritesheet.svg);
                        @include bgIconCoord(22, 8, 5);
                        cursor: pointer;
                    }
                }

                .search_results_wrapper {
                    .results_list {
                        padding-left: 0;
                        list-style-type: none;

                        li {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            margin-bottom: 12px;
                            cursor: pointer;

                            span {
                                font-family: $primaryFont;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: normal;

                                strong {
                                    font-weight: 300;
                                }

                                &:nth-of-type(2) {
                                    text-transform: lowercase;
                                }
                            }

                            &:hover {
                                span:first-of-type {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }

            @media only screen and (max-width: 1270px) {
                margin-right: 14px;
            }
        }

        .menu_categ_wrapper {
            top: 56px;
        }
    }

    #GlobaleFreeShippingBannerContainer:empty {
        display: none !important;
    }

    #GlobaleFreeShippingBannerContainer:not(:empty) + .promo_bar:not(:empty) + .headerContainer {
        .block_top_links {
            .headerRollover {
                #show_top_cart,
                #topLogin,
                #show_top_wish {
                    top: 144px;
                }
            }
        }
    }

    &:has(.promo_swiper),
    &:has(#GlobaleFreeShippingBannerContainer) {
        .headerContainer {
            .block_top_links {
                .headerRollover {
                    #show_top_cart,
                    #topLogin,
                    #show_top_wish {
                        height: calc(100vh - 100px);
                        height: calc(100dvh - 100px);
                    }

                    #show_top_cart {
                        padding-top: 40px;

                        .cart_list_wrapper {
                            #cart_list {
                                height: calc(100vh - 360px);
                                height: calc(100dvh - 360px);
                            }
                        }

                        &:has(.upselling) {
                            .cart_list_wrapper {
                                #cart_list {
                                    height: calc(100vh - 410px);
                                    height: calc(100dvh - 410px);
                                }
                            }
                        }
                    }

                    #show_top_wish {
                        .cart_list_wrapper {
                            #cart_list_wish {
                                height: calc(100vh - 375px);
                                height: calc(100dvh - 375px);
                            }
                        }
                    }
                }

                #form_recherche {
                    .wrap_search_field {
                        top: 100px;
                        transition: all ease-in-out 0.35s;
                    }
                }
            }

            .menu_categ_wrapper {
                top: 100px;
            }
        }

        &.sticky {
            .headerContainer {
                .block_top_links {
                    .headerRollover {
                        #show_top_cart,
                        #topLogin,
                        #show_top_wish {
                            top: 56px;
                            height: calc(100vh - 56px);
                            height: calc(100dvh - 56px);
                        }
                    }
                }
            }
        }
    }

    &.sticky {
        .headerContainer {
            .block_top_links {
                .headerRollover {
                    #show_top_cart,
                    #topLogin,
                    #show_top_wish {
                        top: 56px;
                    }
                    
                    #show_top_cart {
                        padding-top: 40px;

                        .cart_list_wrapper {
                            #cart_list {
                                height: calc(100vh - 320px);
                                height: calc(100dvh - 320px);
                            }
                        }

                        &:has(.upselling) {
                            .cart_list_wrapper {
                                #cart_list {
                                    height: calc(100vh - 365px);
                                    height: calc(100dvh - 365px);
                                }
                            }
                        }

                        .rollover_title {
                            margin-bottom: 40px;
                        }
                    }

                    #show_top_wish {
                        padding-top: 40px;

                        .rollover_title {
                            margin-bottom: 40px;
                        }

                        .cart_list_wrapper {
                            #cart_list_wish {
                                height: calc(100vh - 360px);
                                height: calc(100dvh - 360px);
                            }
                        }
                    }
                }

                #form_recherche {
                    .wrap_search_field {
                        top: 56px;
                        transition: top ease-in-out 0.5s;
                    }
                }
            }

            .menu_categ_wrapper {
                top: 56px !important;
            }
        }

        #GlobaleFreeShippingBannerContainer:not(:empty) {
            & + .promo_bar {
                & + .headerContainer {
                    #wrapper_top_menu {
                        top: 56px;
                    }
                }
            }
        }     

        #GlobaleFreeShippingBannerContainer:not(:empty) + .promo_bar:not(:empty) + .headerContainer {
            .block_top_links {
                .headerRollover {
                    #show_top_cart,
                    #topLogin,
                    #show_top_wish {
                        top: 56px;
                    }
                }
            }
        }
    }
    
    @media only screen and (max-width: 1270px) {
        #wrapper_top_menu {
            top: 56px;
        }

        &:has(.promo_swiper):not(:empty),
        &:has(#GlobaleFreeShippingBannerContainer):not(:empty) {
            #wrapper_top_menu {
                top: 100px;
            }
        }

        #GlobaleFreeShippingBannerContainer:not(:empty) {
            & + .promo_bar {
                & + .headerContainer {
                    #wrapper_top_menu {
                        top: 144px;
                    }
                }
            }
        }

        &.sticky {
            &:has(.promo_swiper):not(:empty),
            &:has(#GlobaleFreeShippingBannerContainer):not(:empty) {
                #wrapper_top_menu {
                    top: 56px;
                }
            }

            #GlobaleFreeShippingBannerContainer:not(:empty) {
                & + .promo_bar {
                    & + .headerContainer {
                        #wrapper_top_menu {
                            top: 56px;
                        }
                    }
                }
            }
        }
    }
}

body {
    &.in_tunnel {
        .block_top_links {

            #form_recherche,
            #distrib_top,
            #wishlist_top,
            .menu_tablet {
                display: none !important;
            }
        }
    }
}