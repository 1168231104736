body.product_page {
    &.en {
        // Added as requested in ticket [SW-16177]
        .page_produit .product_main_wrapper .product_info_wrapper .product_info {
            .wrapper_informations {
                display: flex;
                justify-content: center;

                .date_livraison {
                    display: none;
                }

                .back_order {
                    border-left: none !important;
                }
            }
        }
    }

    .page_produit {
        .product_main_wrapper {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            padding: 0 20px;

            .image_product_wrapper {
                position: relative;
                width: 68.65%;
                max-width: 1252px;
                margin-right: 30px;

                .itm_views {
                    .thumbsFpContainer {
                        display: none;
                    }

                    .mainSlideFp {
                        position: static;

                        .swiper_wrapper {
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;

                            .swiper-slide {
                                position: relative;
                                width: calc(50% - 10px);
                                max-width: 616px;
                                background-color: $grey;
                                margin-right: 20px;
                                margin-bottom: 20px;
                                overflow: hidden;
                                cursor: url('../img/zoom.png'), auto;

                                &:nth-of-type(even) {
                                    margin-right: 0;
                                }

                                img {
                                    position: relative;
                                    display: block;
                                    width: 100%;
                                    height: auto;

                                    &.img_zoom {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: auto;
                                        height: auto;
                                        max-width: initial;
                                        opacity: 0;
                                        transition: inherit;

                                        &.active {
                                            opacity: 1;
                                            z-index: 1;
                                        }
                                    }
                                }

                                &.slide_video,
                                &.content_video {
                                    position: relative;
                                    display: block;
                                    height: 0 !important;
                                    padding-bottom: calc((770 / 616) * (50% - 10px));
                                    background-color: $grey;

                                    iframe {
                                        display: block;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        background: $black;
                                        width: 100%;
                                        height: 100%;
                                        z-index: 0;
                                    }
                                }

                                &:hover {
                                    .img_zoom {
                                        opacity: 1;
                                    }
                                }
                            }
                        }

                        .eclat_rect {
                            position: absolute;
                            top: 10px;
                            left: calc(50% + 20px);
                            height: 28px;
                            z-index: 2;

                            img {
                                display: block;
                                height: 28px;
                                width: auto;
                            }
                        }
                    }
                }
            }

            .product_info_wrapper {
                position: sticky;
                top: 100px;
                width: calc(33.4% - 30px);
                padding-bottom: 20px;

                .product_info {
                    margin-bottom: 1.5rem;
                    
                    .wrapper_name_price {
                        min-width: 390px;
                        
                        .itm_details {
                            margin-bottom: 0.5rem;
                            .item_name {
                                font-family: $primaryFont;
                                font-size: 32px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: normal;
                                width: calc(100% - 40px);
                                margin-bottom: 0.5rem;

                                @media only screen and (max-width: 1270px) {
                                    font-size: 28px;
                                }
                            }

                            .itm_subtitle {
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: normal;
                                margin-bottom: 0.5rem;
                            }
                        }

                        .price_and_alma_wrapper {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            gap: 1rem;

                            .price_container {
                                display: flex;
                                align-items: center;
                                gap: 0.5rem;   
                                margin-top: 10px;
                            }

                            .avis_alma {
                                .payez_en_3_fois_sans_frais {
                                    position: relative;
                                    cursor: pointer;
                                    line-height: 1;
                                    transition: all ease 0.2s;
                                    display: none;
                                    
                                    #alma-widget {
                                        background: $white;
                                        cursor: default;
                                           
                                        .alma-payment-plans-container {
                                            width: 244px;
                                            height: 100%;
                                            gap: 7px;
                                            border: 1px solid rgba($black, 0.3);
                                        }

                                        @media only screen and (max-width: 1345px) {
                                            width: 230px;
                                        }

                                        .alma-payment-plans-payment-info,
                                        .alma-payment-plans-eligibility-options > * {
                                            font-family: $primaryFont;
                                            font-weight: 300;
                                            padding: 4px 5px;
                                        }

                                        .alma-payment-plans-payment-info {
                                            display: flex;
                                            align-items: center;
                                            width: 100%;
                                            padding: 0;
                                            font-size: 12px;
                                            font-weight: 300;
                                            line-height: 1;

                                            &::after {
                                                content: ' ';
                                                display: block;
                                                margin-left: auto;
                                                width: 15px;
                                                height: 15px;
                                                border-radius: 100%;
                                                background-image: url(../svg/info.svg);
                                                background-size: contain;
                                                background-repeat: no-repeat;
                                                background-position: center;

                                            }
                                        }
                                    }

                                    &:has(._1lr-q) {
                                        cursor: default;
                                    }

                                    &:has(.alma-payment-plans-not-eligible-option) {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    .itm_form_group {
                        padding: 0;
                        border: none;

                        .ligne_form {
                            .prod_listes {
                                .titre_choices_list,
                                .size_title {
                                    display: none;
                                }

                                .choices_list {
                                    &.fp_color_swiper {
                                        display: inline-flex;
                                        align-items: center;
                                        width: auto;

                                        .swiper-wrapper {
                                            .swiper-slide {
                                                &.form_itm {
                                                    position: relative;
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    width: 28px;
                                                    height: 28px;
                                                    margin-left: 10px;

                                                    &:first-of-type {
                                                        margin-left: 0;
                                                    }

                                                    [type=radio] {
                                                        position: absolute;
                                                        left: -3px;
                                                        top: -3px;
                                                        width: 14px;
                                                        height: 14px;
                                                        z-index: 1;
                                                        opacity: 0;
                                                        cursor: pointer;
                                                    }

                                                    [type=radio]:not(:checked) + label,
                                                    [type=radio]:checked + label {
                                                        position: relative;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        background-position: center;
                                                        background-size: contain;
                                                        width: 18px;
                                                        height: 18px;
                                                        margin: 0;
                                                        transition: all .4s ease-in-out;
                                                        border: 1px solid $white;
                                                        box-shadow: inherit;
                                                        position: relative;
                                                        border-radius: 100%;
                                                        cursor: pointer;
                                                        z-index: 1;

                                                        &:before {
                                                            content: '';
                                                            position: absolute;
                                                            top: -3px;
                                                            left: -3px;
                                                            width: 20px;
                                                            height: 20px;
                                                            background-color: transparent;
                                                            border: 1px solid $grey;
                                                            border-radius: 100%;
                                                        }
                                                    }

                                                    [type=radio]:checked+label {
                                                        &:before {
                                                            border: 1px solid $black;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .fp_color_swiper_next_btn {
                                            display: none;
                                            width: 22px;
                                            height: 16px;
                                            background-image: url(../svg/spritesheet.svg);
                                            @include bgIconCoord(5,8,5);
                                            margin: auto;
                                            transform: rotate(-90deg);
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }

                            &.productColorFieldset {
                                margin-bottom: 0.5rem;
                            }

                            &.productSizeFieldset {
                                .size_wrapper {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;

                                    .sizesList {
                                        display: flex;
                                        flex-wrap: wrap;
                                        width: calc(100% - 120px);

                                        .form_itm {
                                            position: relative;
                                            display: inline-block;
                                            width: auto;
                                            height: 37px;
                                            min-width: 38px;
                                            padding: 9px;
                                            margin-right: 10px;
                                            margin-bottom: 10px;
                                            text-align: center;
                                            border: 1px solid $black;
                                            overflow: hidden;
                                            z-index: 1;
                                            transition: background ease-in-out 0.5s;

                                            &:last-of-type {
                                                margin-right: 0 !important;
                                            }

                                            [type=radio]:checked,
                                            [type=radio]:not(:checked) {
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                right: 0;
                                                width: 100%;
                                                height: 100%;
                                                z-index: 0;
                                                opacity: 0;
                                                cursor: pointer;

                                                & + label {
                                                    position: relative;
                                                    text-align: center;
                                                    font-family: $primaryFont;
                                                    font-size: 16px;
                                                    font-style: normal;
                                                    font-weight: 300;
                                                    line-height: normal;
                                                    color: $black;
                                                    cursor: pointer;
                                                    white-space: nowrap;
                                                    z-index: 1;

                                                    .border_size {
                                                        .alert_stock {
                                                            display: none;
                                                        }
                                                    }

                                                    &:before,
                                                    &:after{
                                                        display: none;
                                                    }
                                                }
                                            }

                                            &:has([type=radio]:checked),
                                            &:hover {
                                                background-color: $black;

                                                label {
                                                    color: $white !important;
                                                }
                                            }

                                            &:has(.disabled) {
                                                border-color: $secondaryColor;

                                                &:before {
                                                    content: '';
                                                    position: absolute;
                                                    top: 0;
                                                    left: -10px;
                                                    bottom: 0;
                                                    height: 1px;
                                                    width: calc(100% + 20px);
                                                    background-color: $secondaryColor;
                                                    transform: rotate(135deg);
                                                    margin: auto;
                                                    z-index: 1;
                                                }

                                                [type=radio]:checked,
                                                [type=radio]:not(:checked) {
                                                    border-color: $secondaryColor;

                                                    & + label {
                                                        color: $secondaryColor;
                                                    }
                                                }

                                                &:has([type=radio]:checked),
                                                &:hover {
                                                    background-color: $secondaryColor;

                                                    &:before {
                                                        background-color: $white;
                                                    }

                                                    label {
                                                        color: $white !important;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    @media only screen and (max-width: 1380px) {
                                        flex-direction: column;
                                        align-items: flex-start;

                                        .block_sizeguide {
                                            margin-top: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    #product_addcart {
                        padding: 0;
                        border: none;

                        #sizeerror {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 287px;
                            max-width: calc(50% - 5px);
                            height: 41px;
                            text-align: center;
                            color: $black;
                            background-color: $white;
                            border: 1px solid $black;
                            opacity: 0.5;
                            transition: background ease-in-out 0.5s, opacity ease-in-out 0.5s;

                            &.active {
                                background-color: $red;
                                color: $white;
                                animation: blink 1s infinite;
                            }
                        }

                        #bloc_add_basket {
                            position: relative;
                            width: 287px;
                            max-width: calc(50% - 5px);
                            display: none;

                            #btn_add_cart {
                                width: 100%;

                                &[data-type="add_basket_preco"] {
                                    color: $black;
                                    background-color: $white;

                                    &:hover {
                                        color: $white;
                                        background-color: $black;
                                    }
                                }
                            }

                            .pdvWrapper {
                                display: inline-block;
                                width: auto;

                                .btnFindRetailer {
                                    display: inline-block;
                                    width: auto;
                                    height: auto;
                                    color: $black;
                                    background-color: $white;
                                    padding: 0;
                                    margin-bottom: 36px;
                                    border: none;
                                    border-bottom: 1px solid $black;
                                }
                            }
                        }

                        #bloc_add_alert {
                            display: block !important;

                            &:disabled {
                                display: none !important;
                            }

                            &.inactiv,
                            &.cache {
                                display: none !important;
                            }
                            
                            #btn_add_alert {
                                width: 287px;
                                max-width: calc(50% - 5px);
                                margin-bottom: 20px;
                                background-color: $white;

                                span {
                                    color: $black;
                                }

                                &.cache {
                                    display: none;
                                }

                                &:hover {
                                    background-color: $black;

                                    span {
                                        color: $white;
                                    }
                                }
                            }

                            #btn_alert_stock {
                                width: 287px;
                                color: $black;
                                background-color: $white;

                                &:hover {
                                    color: $white;
                                    background-color: $black;
                                }
                            }

                            #bloc_add_alert_form {
                                &.w-form-line {
                                    width: 287px;
                                    margin-bottom: 20px;

                                    .w-input-container {
                                        .w-input {
                                            .w-input-label {
                                                top: 50%;
                                                left: 0;
                                                font-family: $primaryFont;
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 300;
                                                line-height: normal;
                                                color: $black;
                                                opacity: 0.35;
                                            }

                                            .w-input-element {
                                                width: 100%;
                                                height: 1.65rem;
                                                font-family: $primaryFont;
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 300;
                                                line-height: normal;
                                                padding: 0;
                                                border-radius: 0;
                                                border: none;
                                                border-bottom: 1px solid $black;

                                                &:active,
                                                &:focus,
                                                &:focus-visible {
                                                    border: none;
                                                    border-bottom: 1px solid $black;
                                                    box-shadow: none;
                                                }

                                                &.inputErr {
                                                    border-color: $red;
                                                }
                                            }

                                            &.w-has-error {
                                                .w-input-element {
                                                    border-color: $red;
                                                    box-shadow: inherit !important
                                                }
                                            }

                                            &.w-nonempty {
                                                .w-input-label {
                                                    transform: translateY(-150%);
                                                }
                                            }
                                        }

                                        .w-input-note {
                                            text-align: left;

                                            &.w-input-error {
                                                color: $red;
                                            }
                                        }
                                    }

                                    .form_submit {
                                        position: absolute;
                                        right: 0;
                                        display: inline-block;
                                        width: auto;
                                        height: auto;

                                        button {
                                            display: inline-block;
                                            width: auto;
                                            height: auto;
                                            background-color: transparent;
                                            padding-right: 0;
                                            border: none;
                                            text-transform: none;

                                            span {
                                                font-family: $primaryFont;
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 300;
                                                line-height: normal;
                                                color: $black;
                                            }
                                        }
                                    }
                                }
                            }

                            #keep_alive_stock {
                                width: 100%;
                                max-width: 287px;

                                .w-alertestock-form {
                                    position: relative;
                                    width: 100%;
                                    margin: 0;

                                    .w-form-line {
                                        .w-input-container {
                                            .w-input {
                                                &.w-text-input {
                                                    .w-input-element {
                                                        width: 100%;
                                                        height: 32px;
                                                        font-family: $primaryFont;
                                                        font-size: 16px;
                                                        font-style: normal;
                                                        font-weight: 400;
                                                        line-height: 1;
                                                        color: $black;
                                                        padding: 0;
                                                        border: none;
                                                        border-bottom: 1px solid $black;
                                                        border-radius: 0;

                                                        &:focus {
                                                            box-shadow: none;
                                                        }
                                                        
                                                        &:placeholder {
                                                            font-family: $primaryFont;
                                                            font-size: 14px;
                                                            font-style: normal;
                                                            font-weight: 400;
                                                            line-height: 1;
                                                            color: $secondaryColor;
                                                        }

                                                        &.inputErr {
                                                            border-color: $red;
                                                        }
                                                    }

                                                    .w-input-label {
                                                        left: 0;
                                                        top: 10px;
                                                    }

                                                    &.w-nonempty {
                                                        .w-input-label {
                                                            display: none;
                                                        }
                                                    }
                                                }

                                                &.w-has-error {
                                                    .w-input-element {
                                                        box-shadow: none;
                                                        border-color: $red;
                                                    }
                                                }
                                            }
                                        }

                                        &.w-submit {
                                            position: absolute;
                                            right: 0;
                                            top: 0;
                                            width: 22px;
                                            height: 22px;
                                            margin: 0;

                                            .w-submit-button {
                                                height: 22px;
                                                background-color: $white;
                                                border: none;

                                                &:focus {
                                                    background-color: $white;

                                                    span {
                                                        color: $black;
                                                    }
                                                }

                                                span {
                                                    font-size: 16px;
                                                    color: $black;
                                                }

                                                &.w-loader {
                                                    display: none !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    #addToWishlistButtonContainer {
                        position: absolute;
                        right: -5px;
                        top: -3px;
                        display: block;
                        width: 33px;
                        height: 33px;

                        #addToWishlistButton {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            background-image: url(../svg/spritesheet.svg);
                            @include bgIconCoord(9,8,5);

                            &.existToWishlistButton {
                                @include bgIconCoord(10,8,5);
                            }
                        }
                    }

                    .block_sizeguide {
                        .sizeguide {
                            display: inline-block;
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 1;
                            text-decoration: underline;
                            text-underline-offset: 4px;
                            color: $black;
                        }
                    }

                    .duo_btn {
                        margin-bottom: 1.5rem;

                        #product_selects {
                            margin-bottom: 1rem;
                        }

                        // For Fringuant
                        fringuant-button[data-pos='d1'] {
                            // Button itself
                            &::part(f_b_c_0) {
                                height: 18px;
                                margin-bottom: calc(1rem + 10px);
                            }

                            // Camera icon inside button
                            &::part(f_b_c_s_i_0) {
                                display: none;
                            }

                            // Text inside button
                            &::part(f_b_c_s_t_0) {
                                font-family: $primaryFont;
                                font-size: 16px;
                                font-weight: 300;
                                letter-spacing: normal;
                                line-height: normal;
                            }
                        }
                    }

                    #bloc_availability {
                        display: inline-block;
                        width: auto;
                        margin-bottom: 1.5rem;

                        .disponibility {
                            display: inline-block;
                            width: auto;
                            height: auto;
                            color: $black;
                            background-color: $white;
                            padding: 0;
                            border: none;
                            line-height: 1;

                            span {
                                text-decoration: underline;
                                text-underline-offset: 4px;
                                line-height: 1;
                            }
                        }
                    }

                    .wrapper_informations {
                        display: grid;
                        grid-template-columns: repeat(2, minmax(0, 1fr));
                        font-size: 15px;

                        .date_livraison {
                            .pdf_livre_date {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 0.5rem;
                                width: 100%;

                                .icon {
                                    position: relative;
                                    display: block;
                                    width: 22px;
                                    height: 16px;
                                    background-image: url(../svg/spritesheet.svg);
                                    @include bgIconCoord(23,8,5);
                                }
                            }
                        }

                        .date_livraison + .back_order {
                            border-left: 1px solid $black;

                            @media only screen and (max-width: 1440px) {
                                border-left: 0;
                            }
                        }

                        .back_order {
                            .pdf_back_order {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 0.5rem;
                                width: 100%;
                                color: $black;
                                text-decoration: none;

                                .icon {
                                    position: relative;
                                    display: block;
                                    width: 14px;
                                    height: 14px;
                                    min-width: 14px;
                                    background-image: url(../svg/spritesheet.svg);
                                    @include bgIconCoord(24,8,5);
                                }
                            }
                        }

                        @media only screen and (max-width: 1440px) {
                            grid-template-columns: repeat(1, minmax(0, 1fr));
                            gap: 0.5rem;

                            .back_order {
                                border-left: none;
                            }

                            .pdf_livre_date, .pdf_back_order {
                                justify-content: flex-start !important;
                            }
                        }
                    }

                    .bloc_add_alert_confirmation {
                        width: 100%;
                        margin-bottom: 20px;

                        .w-submit-button {
                            width: 100%;
                            max-width: 287px;
                            margin-top: 20px;
                        }
                    }

                    .restock_date,
                    .stock_delivery {
                        display: none;
                    }

                    .roll_preco {
                        margin-bottom: 20px;
                    }

                    &.sticky_info {
                        position: fixed;
                        top: 0;
                        left: 0;
                        right: 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        background-color: $white;
                        padding: 20px 100px 0;
                        margin: auto;
                        border-bottom: 1px solid rgba($black, 0.2);
                        z-index: 34;

                        .frznocache,
                        .restock_date,
                        div[itemprop="offers"],
                        .price_and_alma_wrapper,
                        #addToWishlistButtonContainer,
                        .block_sizeguide,
                        #bloc_availability,
                        .wrapper_informations,
                        .roll_preco,
                        fringuant-button {
                            display: none;
                        }

                        .frznocache,
                        .restock_date,
                        div[itemprop="offers"] {
                            order: 1;
                        }

                        .wrapper_name_price {
                            order: -1;

                            .itm_details {
                                margin-bottom: 0.5rem;

                                #itm_name {
                                    width: 100%;
                                    margin-bottom: 4px;
                                }
                            }
                        }

                        .duo_btn {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            width: calc(100% - 400px);

                            .itm_form_group {
                                .ligne_form {
                                    &.productColorFieldset {
                                        margin-bottom: 0;
                                    }

                                    &.productSizeFieldset {
                                        margin-left: 70px;

                                        .size_wrapper {
                                            margin-bottom: 0;

                                            .sizesList {
                                                width: 100%;

                                                .form_itm {
                                                    margin-right: 6px;
                                                    margin-bottom: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        #product_selects {
                            display: flex;
                            align-items: center;
                            order: 2;

                            .size_guide_size_wrapper {
                                margin: 0 0 0 70px;
                            }
                        }

                        #product_addcart {
                            width: 100%;
                            max-width: 287px;
                            margin: 0 0 0 70px;
                            order: 5;

                            .prod_btn_wrapper {
                                margin-bottom: 0;
                                
                                #sizeerror {
                                    max-width: 100%;
                                    margin-bottom: 0;
                                }

                                #bloc_add_basket,
                                #bloc_add_alert {
                                    max-width: 100%;

                                    #btn_add_cart,
                                    #btn_add_alert {
                                        max-width: 100%;
                                        margin-bottom: 0;
                                    }
                                }

                                #keep_alive_stock {
                                    width: 100%;
                                    max-width: 100% !important;
                                    margin-bottom: 0;

                                    .w-alertestock-form {
                                        width: 100%;
                                        margin-bottom: 0;

                                        .w-form-line {
                                            margin: 0;

                                            .w-input-container {
                                                .w-input.w-text-input {
                                                    #mail_alerte_stock {
                                                        border-bottom: 1px solid $black;
                                                    }

                                                    &.w-has-error {
                                                        #mail_alerte_stock {
                                                            box-shadow: inherit;
                                                            border-color: $red;
                                                        }
                                                    }

                                                    .w-input-label {
                                                        top: 18px !important;
                                                    }
                                                }
                                            }

                                            &.w-submit {
                                                top: 8px !important;
                                            }
                                        }
                                    }
                                }

                                .bloc_add_alert_confirmation {
                                    position: relative;
                                    margin-bottom: 0;

                                    .w-submit-button {
                                        position: absolute;
                                        top: 5px;
                                        right: 5px;
                                        width: 15px;
                                        height: 15px;
                                        background-color: $white;
                                        font-size: 0;
                                        background-image: url(../svg/spritesheet.svg);
                                        @include bgIconCoord(22,8,5);
                                        border: none;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }

                    // IPAD AIR
                    @media only screen and (max-width: 820px) {
                        .wrapper_name_price {
                            min-width: unset;

                            .price_and_alma_wrapper {
                                flex-direction: column;

                                .avis_alma {
                                    margin-bottom: 20px;

                                    .alma-payment-plans-container {
                                        width: 228px !important;
                                    }
                                }
                            }
                        }

                        .duo_btn {
                            #product_addcart {
                                #sizeerror,
                                #bloc_add_basket {
                                    width: fit-content;
                                    max-width: 190px;
                                    padding: 0 10px;
                                }
                            }

                            .productSizeFieldset {
                                .disabledBtnTooltip {
                                    min-width: 228px;
                                }
                            }
                        }
                    }
                }

                #description_produit {
                    .desc_pannel {
                        position: relative;

                        .toggle_carac_wrapper {
                            border-bottom: 1px solid $black;
                        }

                        .toggleCarac {
                            position: relative;
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                            color: $black;
                            padding: 20px 0;
                            cursor: pointer;

                            &:before {
                                content: '';
                                position: absolute;
                                right: 0;
                                width: 16px;
                                height: 16px;
                                background-image: url(../svg/spritesheet.svg);
                                @include bgIconCoord(21,8,5);
                            }

                            &.actif {

                                &:before {
                                    @include bgIconCoord(20,8,5);
                                }
                            }
                        }

                        .content {
                            display: none;
                            color: $black;
                            padding-bottom: 20px;

                            &#secondHandContent {
                                .second_hand_text {
                                    width: 20vw;
                                    max-width: 80%;

                                    @media screen and (min-width: 1921px) {
                                        width: 390px;
                                    }
                                }

                                #fairlymade {
                                    margin-top: 20px;

                                    #open-popup-fairlymade {
                                        width: 100%;
                                        display: flex;
                                        flex-wrap: nowrap;
                                        justify-content: space-between;
                                        align-items: center;
                
                                        .fairlymade_wording {
                                            display: flex;
                                            text-align: left;
                                            flex-direction: column;
                                            width: 24vw;
                                            max-width: 80%;
                
                                            &_clickable {
                                                cursor: pointer;
                                                width: min-content;
                                                white-space: nowrap;
                                                text-decoration: underline;
                                            }
                                        }
                
                                        .failrymade_logo {
                                            height: 2rem;
                                            width: 2rem;
                                            background-image: url(../svg/fairlymade/fairlymade_logo.svg);
                                            background-repeat: no-repeat;
                                            background-position: center;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }

                            ul {
                                padding-left: 5px;
                                margin: 10px 0;
                                list-style-type: none;

                                li {
                                    position: relative;
                                    line-height: 1;
                                    padding-left: 15px;
                                    margin-bottom: 5px;

                                    &:before {
                                        content: '';
                                        position: absolute;
                                        top: 4px;
                                        left: 0;
                                        display: block;
                                        width: 5px;
                                        height: 5px;
                                        background-color: $black;
                                        border-radius: 100%;
                                    }
                                }
                            }

                            .caract {
                                margin-bottom: 10px;
                            }

                            a {
                                color: $black;
                                text-decoration: underline;
                                text-underline-position: under;
                            }
                        }
                    }
                }

                &:has(.lightbox.actif) {
                    position: relative;
                }
            }

            &:has(.sticky_info) {
                .product_info_wrapper {
                    position: relative;
                }
            }
        }
    }

    .product_associations_wrapper,
    .autre_pdt_container {
        display: block;
        width: 100%;
        padding: 0 1.2rem;

        .autre_pdt_tab_list {
            padding: 0;
            list-style-type: none;
        }

        #derniers_articles_vus,
        .assocTitle {
            display: block;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin-bottom: 10px;
        }

        & > .swiper-container {
            & > .swiper-wrapper {
                & > .swiper-slide {
                    width: 24.3%;
                    margin-right: 19px;
                }

                .swiper-pagination {
                    display: flex;
                    justify-content: center;
                    column-gap: 7px;

                    .swiper-pagination-bullet {
                        width: 8px;
                        height: 8px;
                        background-color: $thirdColor ;
                        opacity: 1;

                        &.swiper-pagination-bullet-active {
                            background-color: $black;
                        }
                    }
                }
            }
        }

        .swiper_tg_container {
            .swiper-slide {
                img.itm_vis {
                    display: block;

                    & + img,
                    & + span {
                        position: absolute;
                        left: 0;
                        top: 0;
                        opacity: 0;
                        z-index: -1;
                        transition: opacity 0.35s ease-in-out;
                    }
                }

                &:hover {
                    img.itm_vis {
                         & + img,
                        & + span {
                            opacity: 1;
                            z-index: 1;
                        }
                    }
                }
            }
        }

        #assoc_product_slider {
            & > .swiper-wrapper {
                & > .swiper-slide {
                    width: 24.3%;
                    margin-right: 19px;
                }
            }

            .swiper-pagination {
                position: relative;
                display: flex;
                justify-content: center;
                column-gap: 7px;
                margin-top: 25px;

                .swiper-pagination-bullet {
                    width: 8px;
                    height: 8px;
                    background-color: $thirdColor ;
                    opacity: 1;

                    &.swiper-pagination-bullet-active {
                        background-color: $black;
                    }
                }
            }
        }
    }

    .product_cms_bloc {
        position: relative;
        width: 100%;
        padding: 0 20px;
        margin-bottom: 46px;

        .cms-page-module {
            margin: 0 auto 20px;

            .cms-page-module-img {
                display: block;
            }
        }
    }

    @media only screen and (max-width: 1440px) {
        .product_info.sticky_info {
            padding: 20px 20px !important;

            #product_addcart,
            #product_selects .size_guide_size_wrapper {
                margin-left: 20px !important;
            }

            .duo_btn {
                width: calc(100% - 300px) !important;

                .itm_form_group {
                    .ligne_form{
                        &.productSizeFieldset {
                            margin-left: 20px !important;
                        }
                    }
                }
            }
        }
    }
}

.ReactModalPortal {
    .ReactModal__Overlay {
        .ReactModal__Content {
            height: auto;
            border-radius: 0;

            &:active,
            &:focus,
            &:focus-visible {
                box-shadow: none;
                border: none;
            }

            ._12LLh {
                top: 10px;
                right: 10px;
            }

            .alma-eligibility-modal-left-side {
                .alma-eligibility-modal-title {
                    font-family: $primaryFont;
                    font-size: 18px;
                    font-weight: 700;
                    letter-spacing: 0;
                }

                .alma-eligibility-modal-info {
                    .alma-eligibility-modal-bullet {
                        font-family: $primaryFont;
                        font-size: 18px;
                        font-weight: 700;
                        letter-spacing: 0;
                    }

                    .alma-eligibility-modal-info-message {
                        font-family: $primaryFont;
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: normal;

                        strong {
                            font-weight: 300;
                            -webkit-text-stroke-width: 0.75px;
                        }
                    }
                }
            }

            .alma-eligibility-modal-right-side {
                .alma-eligibility-modal-eligibility-options {
                    display: flex;
                    align-items: center;

                    button {
                        width: calc(50% - 10px);
                        font-family: $primaryFont;
                        font-size: 16px;
                        font-weight: 300;
                        color: $white;
                        background-color: $black;
                        border-radius: 0;
                        border: 1px solid $black;
                        transition: all ease-in-out 0.5s;
                        cursor: pointer;

                        span {
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-weight: 300;
                            color: $white;
                        }

                        &:after {
                            display: none;
                        }

                        &.alma-eligibility-modal-active-option {
                            cursor: default;
                        }

                        &.alma-eligibility-modal-active-option,
                        &:hover {
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-weight: 300;
                            background-color: $white;
                            color: $black;

                            span {
                                color: $black;
                            }

                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .alma-eligibility-modal-summary {
        background-color: $grey;
        border-radius: 0;

        .alma-eligibility-modal-schedule-total {
            margin-bottom: 10px;
        }

        .alma-eligibility-modal-schedule-total,
        .alma-eligibility-modal-schedule-credit {
            font-family: $primaryFont;
            font-size: 16px;
            font-weight: 300;
            line-height: 1;
        }
    }

    .alma-eligibility-modal-close-button {
        width: 16px;
        height: 16px;
        background-image: url(../svg/spritesheet.svg);
        @include bgIconCoord(22,8,5);
        background-color: $white;

        svg {
            display: none;
        }
    }
}

@keyframes blink {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 0.5;
    }
}