.newsletter,
.customer,
.wishlist,
.paymentOption,
.order {
    #breadcrumbs {
        .breadcrumbs {
            padding-bottom: 0;
            padding-top: 0.8rem;
        }
    }

    .account_name,
    .txt_banner_top {
        position: relative;
        display: inline-block;
    }
}

// mes info
.accountBannerTop,
.account_name {
    text-align: center;
    font-family: $primaryFont;
    color: $black;
    font-size: 32px;
    font-weight: 700;
    margin-top: 10px;

    &.not_connected {
        display: none;
    }
}

#bandeau_basket_premium {
    position: absolute;
    bottom: 7px;
    right: -88px;
    background-color: $bgPremium;
    font-size: 12px;
    font-weight: 300;
    text-transform: capitalize;
    padding: 5px 8px;
}

.wrapper_moncompte {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;

    .wrapper_form_newsletter {
        width: 90%;
        max-width: 1281px;
        margin: 0 auto;

        .w-form {
            .w-form-line {
                margin-bottom: 25px;

                .w-input-container {
                    .w-input {
                        .w-input-element {
                            padding: 0;
                            font-family: $primaryFont;
                            font-size: 16px;
                            color: $black;
                            padding-top: 27px;
                            border-bottom: 1px solid $black !important;
                            border: 0;
                            border-radius: 0;  
                        }

                        .w-input-label {
                            font-family: $primaryFont;
                            color: rgba(0, 0, 0, 0.5);
                            padding-top: 27px;
                        }

                        &.w-focused {
                            .w-input-element {
                                &:focus {
                                    outline: initial;
                                    box-shadow: none;
                                    padding: 0;
                                    padding-top: 27px;
                                }
                            }
                        }

                        &.w-has-error {
                            .w-input-element {
                                box-shadow: none;
                            }
                        }

                        .w-input-label {
                            left: 0;
                            font-family: $primaryFont;
                            color: $black;
                            font-size: 16px;
                        }

                        &.w-nonempty {
                            .w-input-label {
                                font-size: 12px;
                                padding-top: 0;
                            }

                            .w-input-element {
                                padding-top: 27px;
                            }
                        }
                    }
                }
                
                &.w-submit {
                    width: 100% !important;
                    max-width: 18rem;
                    margin-top: 3.45rem !important;
                    margin: 0;
                }

                &.optin-select-line {
                    margin-bottom: 33px;
                    
                    .w-input-container {
                        .w-input {
                            &.w-checkbox-input {
                                .w-input-element {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 1em;
                                    height: 1em;
                                    opacity: 0;

                                    &:checked {
                                        & + .w-input-label {
                                            &:after {
                                                left: 0.8em;
                                            }

                                            &:before {
                                                background-color: $primaryColor;
                                            }
                                        }
                                    }
                                }
                            }

                            .w-input-label {
                                font-size: 16px;
                                font-family: $primaryFont;
                                color: $black;
                                font-weight: 300;

                                &::before {
                                    content: '';
                                    display: block;
                                    width: 26px;
                                    height: 14px;
                                    background-color: $thirdColor;
                                    box-shadow: inset 0 0 0 0 $white;
                                    border-radius: 26px;
                                    position: relative;
                                    transition: .2s ease-out;
                                    transition-property: background-color, box-shadow;
                                    margin-bottom: 20px;
                                }

                                &::after {
                                    content: '';
                                    display: block;
                                    width: 13px;
                                    height: 13px;
                                    background-color: $white;
                                    border-radius: 50%;
                                    position: absolute;
                                    top: 56%;
                                    left: 2px;
                                    transform: translate(0, -50%);
                                    transition: .2s ease-out;
                                }

                                .multiline {
                                    margin-left: 1.5rem;

                                    strong {
                                        font-weight: 300;
                                    }
                                }

                                @media screen and(max-width: 1116px) {
                                    .multiline {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                } 
            }
        }

        .update_success {
            color: $green;
        }
    }

    .left-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .account_name {
            font-family: $primaryFont;
            color: $black;
            font-size: 32px;
            font-weight: 700;
        }

        .wrapper_menu_compte {
            display: flex;
            justify-content: center;
            width: 100%;
            margin: 1.3rem 0 1.45rem 0;
            max-width: 1109px;

            .wrapper_menu_compte {
                margin: 0;
            }

            & > div {
                &:not(:last-child) {
                    margin-right: 50px;
                }
            }

            a {
                font-family: $primaryFont;
                color: $black;
                text-decoration: none;
            }

            .actif {
                text-decoration: underline;
                text-underline-offset: 3px;
            }
        }
    }

    .right-container {
        //info
        &.customer_infos {
            display: flex;
            justify-content: center;
            width: 100%;

            @media screen and(max-width: 1400px) {
                .wrapper_form {
                    max-width: 1100px !important;
                }
            }

            @media screen and(max-width: 1200px) {
                .wrapper_form {
                    max-width: 950px !important;
                }
            }

            .wrapper_form {
                width: 100%;
                max-width: 1285px;
                
                .w-form {
                    &.w-info-form {
                        padding: 0;

                        .w-form-line {
                            margin-bottom: 0.6rem;

                            .w-input-container {
                                & + .w-input-container {
                                    margin-left: 31px ;
                                }

                                .w-input {
                                    .w-input-element {
                                        padding: 0;
                                        font-family: $primaryFont;
                                        font-size: 16px;
                                        color: $black;
                                        padding-top: 27px;
                                    }

                                    .w-input-label {
                                        font-family: $primaryFont;
                                        color: rgba(0, 0, 0, 0.5);
                                        padding-top: 27px;
                                        top: 45%;
                                    }

                                    &.w-dropdown {
                                        border-bottom: 1px solid $black !important;
                                        border: 0;
                                        border-radius: 0;
                                        height: 3.4rem;

                                        &:after {
                                            border: solid black;
                                            border-width: 0 1px 1px 0;
                                            display: inline-block;
                                            padding: 3px;
                                            transform: rotate(45deg);
                                            -webkit-transform: rotate(45deg);
                                            cursor: pointer;
                                            right: 14px;
                                            margin-top: 5px;
                                        }

                                        &.w-focused {
                                            box-shadow: none;
                                        }

                                        &.w-has-error {
                                            box-shadow: initial;
                                            border: 0;
                                        }

                                        .w-value {
                                            padding: 0;
                                            padding-top: 2rem;
                                            font-family: $primaryFont;
                                            color: $black;
                                            font-size: 16px;
                                        }

                                        &.w-nonempty {
                                            .w-value {
                                                padding-top: 27px;
                                            }
                                        }
                                    }

                                    &.w-text-input {
                                        .w-input-element {
                                            border-bottom: 1px solid $black !important;
                                            border: 0;
                                            border-radius: 0;   
                                        }
                                    }

                                    &.w-focused {
                                        .w-input-element {
                                            &:focus {
                                                outline: initial;
                                                box-shadow: none;
                                                padding: 0;
                                                padding-top: 27px;
                                            }
                                        }
                                    }

                                    &.w-has-error {
                                        .w-input-element {
                                            box-shadow: none;
                                        }
                                    }

                                    &.w-tel-input {
                                        .w-input-element {
                                            border-bottom: 1px solid $black !important;
                                            border: 0;
                                            border-radius: 0;
                                            padding-left: 49px;
                                        }

                                        .selected-flag {
                                            border: 0;
                                            padding: 0;
                                            padding-top: 15px;

                                            &:before {
                                                width: 2.375rem;
                                                margin: 8px 5px 0 0;
                                            } 

                                            &:after {
                                                border: solid black;
                                                border-width: 0 1px 1px 0;
                                                display: inline-block;
                                                padding: 3px;
                                                transform: rotate(45deg);
                                                -webkit-transform: rotate(45deg);
                                                cursor: pointer;
                                                right: 1.2rem;
                                            }
                                        }

                                        p.w-input-label {
                                            left: 3rem;
                                        }

                                        &.w-focused {
                                            .w-input-element {
                                                padding-left: 49px !important;
                                            }
                                        }

                                        .country-list  {
                                            .country.FR {
                                                & + .country.FR {
                                                    display: none !important
                                                }
                                            }
                                        }
                                    }

                                    .w-input-label {
                                        left: 0;
                                        font-family: $primaryFont;
                                        color: rgba(0, 0, 0, 0.5);
                                        font-size: 16px;
                                    }

                                    &.w-nonempty {
                                        .w-input-label {
                                            font-size: 12px;
                                            padding-top: 0;
                                            top: 55%;
                                        }

                                        .w-input-element {
                                            padding-top: 27px;
                                        }
                                    }

                                    &.w-date-input {
                                        .w-input-element {
                                            padding: 0;
                                            padding-top: 1.7rem;
                                        }

                                        .w-input-label {
                                            font-size: 12px;
                                            padding: 0;
                                            top: 55%;
                                        }

                                        &.w-text-input {
                                            &.w-focused {
                                                .w-input-element {
                                                    padding-top: 1.7rem;
                                                }
                                            }
                                        }

                                        &.w-nonempty {
                                            .w-input-label {
                                                top: 55%;
                                            }
                                        }

                                        [type="date"]::-webkit-calendar-picker-indicator {
                                            opacity: 0;
                                        }
                                    }
                                }

                                .w-input-note {
                                    top: 92%;
                                }
                            }

                            &.mail-pass {
                                flex-wrap: wrap;
                                justify-content: space-between;

                                .w-input-container {
                                    &.mail-1,
                                    &.mail-2,
                                    &.pass-1,
                                    &.pass-2 {
                                        width: calc(50.6% - 1.3rem);
                                        margin: 0;

                                        .w-input-element {
                                            border-bottom: 1px solid $black !important;
                                            border: 0;
                                            border-radius: 0;  
                                        }
                                    }

                                    &.mail-1 {
                                        order: 0;
                                    }

                                    &.mail-2 {
                                        order: 1;
                                    }

                                    &.pass-1 {
                                        order: 2;
                                    }

                                    &.pass-2 {
                                        order: 3;
                                    }

                                    &.pass-1,
                                    &.pass-2 {
                                        .w-visibility-toggle {
                                            border: 0;
                                            background-color: transparent;
                                        }
                                    }
                                }
                            }

                            &.w-has-tel {
                                .errormsgadd {
                                    display: none;
                                }
                            }
                        
                            &.w-submit {
                                width: 100% !important;
                                max-width: 17.5rem;
                                margin-top: 1.95rem!important;
                                margin: 0;

                                button.w-submit-button {
                                    height: 41px;
                                    text-transform: none;
                                }
                            }
                        }
                    }
                }

                .update_success {
                    &.myInfos {
                        display: none;
                    }
                }
            }

            @media screen and (max-width: 820px) {
                .wrapper_form {
                    width: 95%;
                }
            }
        }

        .wrapper_form {
            width: 100%;
            max-width: 1260px;
        }

        //address
        &.customer_addresses {
            &.form_address {
                width: 96%;
                max-width: 1280px;
                margin: 0 auto;

                .wrapper_adresses_title {
                    display: none;
                }

                .w-address-form {
                    width: 100%;
                    margin-top: 17px;

                    .addrFullDisplay {
                        .addrDepartment {
                            display: none;
                        }
                    }
                    
                    .w-input-group {
                        padding: 0;
                        margin: 0;
                        border: 0;

                        .w-group-label {
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                            color: $black;
                        }
                    }

                    .w-form-line {
                        margin-bottom: 25px;

                        .w-input-container {
                            & + .w-input-container {
                                margin-left: 30px;
                            }
                        }

                        .w-input {
                            .w-input-element {
                                font-family: $primaryFont;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: normal;
                                color: $black;
                                height: 40px;
                                border: 0;
                                border-bottom: solid 1px $black;
                                padding: 0;
                                border-radius: 0;
                            }

                            .w-input-label {
                                font-family: $primaryFont;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: normal;
                                color: rgba(0,0,0,.5);
                                left: 0;
                            }

                            &.w-nonempty {
                                .w-input-element {
                                    padding-top: 10px;
                                }

                                .w-input-label {
                                    font-size: 12px;
                                    line-height: 14.4px;
                                    top: 44%;
                                }
                            }

                            &.w-focused {
                                .w-input-element {
                                    box-shadow: none;
                                }
                            }

                            &.w-has-error {
                                .w-input-element {
                                    border-bottom: solid 1px $red;
                                    box-shadow: none;
                                }
                            }

                            &.w-date-input {
                                .w-input-element {
                                    padding-top: 10px;
                                }

                                .w-input-label {
                                    font-size: 12px;
                                    line-height: 14.4px;
                                    color: $black;
                                    top: 44%;
                                }
                            }

                            &.w-tel-input {
                                .w-input-element {
                                    padding-left: 56px;
                                }

                                .selected-flag {
                                    background-color: transparent;
                                    justify-content: space-between;
                                    padding: 0;
                                    border: 0;
                                    width: 42px;
                                    height: 100%;

                                    &:before {
                                        background-color: transparent;
                                        width: 24px;
                                        height: 15px;
                                        margin-top: 5px;
                                    }

                                    &:after {
                                        background: transparent url('../svg/spritesheet.svg') no-repeat center;
                                        @include bgIconCoord(5,8,5);
                                        width: 12px;
                                        height: 9px;
                                        border: 0;
                                        margin-top: 2px;
                                    }
                                }
                        
                                .country-list {
                                    padding-left: 0;

                                    .country {
                                        font-size: 14px;

                                        &.FR {
                                            & + .country {
                                                display: none;
                                            }
                                        }
                                    }
                                }

                                .w-input-label {
                                    left: 56px;
                                }

                                & + .errormsgadd {
                                    display: none;
                                }
                            }
                        }
                        
                        .w-dropdown {
                            height: 40px;
                            border: 0;
                            border-bottom: solid 1px $black;
                            padding: 0;
                            border-radius: 0;

                            .w-value {
                                font-family: $primaryFont;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: normal;
                                color: $black;
                                padding: 0;
                                top: 60%;
                            }

                            &.w-has-error {
                                border-bottom: solid 1px $red;
                                box-shadow: none;
                            }
                            
                            &:after {
                                background: url('../svg/spritesheet.svg') no-repeat center;
                                @include bgIconCoord(5,8,5);
                                width: 12px;
                                height: 9px;
                                border: 0;
                                margin-top: 2px;
                            }
                        }

                        &.w-submit {
                            &.addrSubmit {
                                margin-top: 30px;
                                max-width: 278px;
                            }
                        }
    
                        [type="date"]::-webkit-calendar-picker-indicator {
                            opacity: 0;
                        }
            
                        [type="date"]::-webkit-date-and-time-value {
                            text-align: left;
                        }

                        &.add-complementary-line {
                            display: none;
                        }

                        .w-input-note {
                            margin-top: 0;
                        }
    
                        .img_error,
                        .valid_form {
                            display: none;
                        }
                    }
                }
            }

            &.list_addresses {
                max-width: 950px;
                width: 96%;
                margin-top: 6px;

                .wrapper_adresses_title {
                    display: none;
                }
    
                .conteneur_adresse {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 10px;
    
                    .adresse {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        height: 220px;
                        width: 278px;
                        padding: 30px;
                        margin-right: 20px;
                        margin-bottom: 20px;
                        position: relative;
                        border: 1px solid $black;
    
                        .infos {
                            a {
                                color: $black;
                                text-decoration: none;
                            }
                        }
    
                        .addr_line {
                            &.telephone {
                                text-decoration: none;
                                color: $black !important;
                            }

                            &.full_name {
                                text-transform: capitalize;
                            }
                        }
    
                        .addr_label {
                            .addr_type {
                                text-transform: capitalize;
                            }
                        } 
    
                        .modlinks_wrapper {
                            a {
                                color: $black;
                                text-decoration: none;
                                border-bottom: 1px solid $black;
                                line-height: 1;
                            }
    
                            .modlink {
                                &.delete {
                                    font-size: 0;
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    border-bottom: none;
    
                                    &:after {
                                        content: '';
                                        width: 14px;
                                        height: 14px;
                                        background: url('../svg/spritesheet.svg') no-repeat center;
                                        @include bgIconCoord(22,8,5);
                                        display: block;
                                        margin: 18px 18px 0 0;
                                    }
                                }
                            }
                        }
                    }
                }

                .add_new_address {
                    background-color: $black;
                    color: $white;
                    text-decoration: none;
                    width: 278px;
                    height: 41px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 29px;
                }
            }
        }
        
        //commande
        &.customer_orders {
            width: 95%;
            max-width: 1282px;
            margin: 0 auto;
            margin-top: 18px;

            .wrapper_menu_tab {
                .content_menu_tab {
                    .filtres_commandes {
                        position: relative;

                        select {
                            border: 0;
                            border-bottom: 1px solid rgba($black, 0.2);
                            width: 100%;
                            font-size: 16px;
                            font-family: $primaryFont;
                            color: $black;
                            padding-bottom: 10px;
                            background-color: transparent;
                            appearance: none;
                            cursor: pointer;

                            &:focus-visible {
                                outline: 0;
                            }
                        }

                        &:after {
                            content: "";
                            width: 14px; 
                            height: 14px;
                            background-image: url('../svg/spritesheet.svg');
                            @include bgIconCoord(5,8,5);
                            position: absolute;
                            right: 9px;
                            background-color: $white;
                            margin: 8px 2px 0 0;
                            z-index: -1;
                        }
                    }
                }
            }

            #orders_by_type {
                .table_mon_compte {
                    .header {
                        tr {
                            th {
                                font-weight: 300;
                                padding-top: 50px;
                                padding-bottom: 3px;
                                text-decoration: underline;
                                text-underline-offset: 3px;
                                
                                th:first-child {
                                    padding-left: 51px;
                                }

                                .label-bloc {
                                    text-wrap: nowrap;
                                }
                            }

                            th:first-child {
                                padding-left: 47px;
                            }
                        }
                    }

                    .content {
                        tr {
                            td {
                                border-bottom: 1px solid rgba($black, 0.2);
                                padding: 35px 5px 35px 0;
                                
                                a {
                                    text-decoration: none;
                                    color: $black;
                                }
                            }

                            td:first-child {
                                padding-left: 47px;
                            }

                            .cmdTrackingNum {
                                text-decoration: underline;
                                text-underline-offset: 3px;
                            }

                            .last {
                                &.link_detail {
                                    text-decoration: underline;
                                    padding-left: 23px;
                                    text-underline-offset: 3px;
                                }
                            }
                        }
                    }
                }
            }
        }

        //alert_stock
        &.wrapper_content_stock_alert {
            width: 95%;
            max-width: 1285px;
            margin: 14px auto 0;
            display: flex;
            justify-content: center;

            .wrapper_form {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                width: 100%;
                align-items: flex-start;
                max-width: initial;

                .product-link {
                    display: flex;
                    width: calc((100% - 46px) / 3);
                    margin-bottom: 15px!important;
                    max-width: 392px;

                    &:nth-child(3n+2) {
                        margin: 0 4.2%;
                    }

                    @media screen and (max-width: 1399px) {
                        &:nth-child(3n+2) {
                            margin: 0 1.8%;
                        }
                    }

                    .cart_thumb_holder {
                        img {
                            width: 208px;
                            height: auto;
                        }
                    }

                    .cart_detail_box {
                        padding-left: 28px;

                        .cart_product {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;

                            .sub_title {
                                order: 0;
                            }

                            .sstitre {
                                display: none;
                            }

                            .wrapper_price {
                                order: 4;
                            }

                            .sub_taille {
                                order: 2;
                                text-transform: capitalize;
                                font-size: 0;

                                span {
                                    font-size: 16px;
                                }
                            }

                            .price_tag {
                                order: 3;
                            }
                            
                            .sub_couleur {
                                font-size: 0;

                                .sub_couleur_name {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.account_payment_options {
            max-width: 1280px;
            width: 95%;
            margin: 0 auto;

            .wrapper_form {
                max-width: initial;
                margin-bottom: 41px;

                .accountPageTitle {
                    display: none;
                }

                .button {
                    &.primary {
                        &.addNewCard {
                            display: none;
                        }
                    }
                }

                &.displayed {
                    .accountAliasInfo {
                        margin-bottom: 13px;
                        margin-top: 17px;
                    }
                }
            }

            #psp_form {
                #hipay-3-form {
                    padding: 0;

                    .hipay-3-row {
                        margin-bottom: 3px;

                        .hipay-3-field-container-half {
                            width: 100%;
                        }
                    }

                    .hipay-3-elements-container {
                        margin-top: 24px;
                    }

                    .hipay-3-field-container {
                        .hipay-3-label {
                            position: relative;
                            top: 12px;
                        }

                        .form_line {
                            &.is_default {
                                margin-bottom: 2rem;
                            }
                        }
                    }
                }

                .cardsImg {
                    margin: 18px 0 2px 0;
                }

                .hipay-3-elements-container {
                    .form_line {
                        &.is_default {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            input[type="checkbox"] {
                                display: none;

                                + label {
                                    position: relative;
                                    font-family: $primaryFont;
                                    font-size: 16px;
                                    color: $black;

                                    &:before {
                                        content: '';
                                        width: 26px;
                                        height: 15px;
                                        background-color: #CFCFCF;
                                        border: 0;
                                        border-radius: 48px;
                                        box-shadow: none;
                                        transition: none;
                                        margin-right: 1em;
                                    }

                                    &:after {
                                        content: '';
                                        width: 13px;
                                        height: 13px;
                                        background-color: $white;
                                        border-radius: 50%;
                                        position: absolute;
                                        top: 1px;
                                        left: 1px;
                                    }
                                }

                                &:checked + label {
                                    &:before {
                                        background-color: $primaryColor;
                                    }

                                    &:after {
                                        left: 12px;
                                    }
                                }
                            }
                        }
                    }

                    .button {
                        font-family: $primaryFont;
                        font-size: 16px;
                        text-transform: none;
                        background: $black;
                        width: 261px;
                        height: 41px;
                        border: 1px solid $black;
                        cursor: pointer;
                        margin-top: 32px;

                        &:hover {
                            color: $black;
                            background-color: $white;
                        }

                        &:focus {
                            background-color: $black;
                            color: $white;

                            &:hover {
                                color: $black;
                                background-color: $white;
                            }
                        }
                    }

                    .hipay-loader {
                        width: 261px;
                        height: 41px;
                        border: 1px solid $black;
                        margin-top: 32px;
                    }
                }
            }

            .displayed {
                #hipay-3-form {
                    padding: 0;

                    .hipay-3-row {
                        margin-bottom: 4px;
                        
                        .hipay-3-field-container {
                            .hipay-3-label {
                                font-family: $primaryFont;
                                color: $black;
                                font-size: 12px;
                                position: relative;
                                top: 12px;
                            }

                            &.hipay-3-field-container-half {
                                width: 100%;
                            }
                        }
                    }

                    .hipay-3-elements-container {
                        margin-top: 25px;

                        .form_line.saveAlias,
                        .form_line {
                            margin-bottom: 32px;

                            &.is_default {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;

                                input[type="checkbox"] {
                                    display: none;

                                    + label {
                                        position: relative;
                                        font-family: $primaryFont;
                                        font-size: 16px;
                                        color: $black;

                                        &:before {
                                            content: '';
                                            width: 26px;
                                            height: 15px;
                                            background-color: #CFCFCF;
                                            border: 0;
                                            border-radius: 48px;
                                            box-shadow: none;
                                            transition: none;
                                            margin-right: 1em;
                                        }

                                        &:after {
                                            content: '';
                                            width: 13px;
                                            height: 13px;
                                            background-color: $white;
                                            border-radius: 50%;
                                            position: absolute;
                                            top: 1px;
                                            left: 1px;
                                        }
                                    }

                                    &:checked + label {
                                        &:before {
                                            background-color: $primaryColor;
                                        }

                                        &:after {
                                            left: 12px;
                                        }
                                    }
                                }
                            }
                        }

                        .button {
                            background: $black;
                            text-transform: none;
                            border: 1px solid $black;
                            font-size: 16px;
                            font-family: $primaryFont;
                            cursor: pointer;

                            &:hover {
                                color: $black;
                                background-color: $white;
                            }

                            &:focus {
                                background-color: $black;
                                color: $white;

                                &:hover {
                                    color: $black;
                                    background-color: $white;
                                }
                            }
                        }
                    }
                }

                .accountPageTitle {
                    font-size: 16px;
                    font-family: $primaryFont;
                    color: $black;
                }
            }

            .wrapperAlias {
                display: flex;
                width: 100%;
                position: relative;

                .alias {
                    display: flex;
                    width: 100%;
                    border-bottom: 1px solid $black;
                    position: relative;
                    padding: 24.5px 0;

                    .cardLogo {
                        margin: 0 50px 0 55px;
                        position: relative;
                        top: 3px;
                        max-width: 35px;
                    }

                    .aliasInfos {
                        display: flex;
                        justify-content: flex-start;
                        gap: 67px;
                        align-items: center;
                        width: 100%;

                        .cardNum {
                            width: 100%;
                            white-space: nowrap;
                            max-width: 225px;
                        }

                        .cardName {
                            width: 100%;
                            max-width: 183px;
                        }

                        .delete {
                            right: 11px;
                            position: absolute;
                            width: 15px;
                            font-size: 0;
                            top: 50%;
                            z-index: 1;
                            cursor: pointer;
                            transform: translateY(-50%);

                            &:after {
                                content: '';
                                width: 14px;
                                height: 14px;
                                background: url('../svg/spritesheet.svg') no-repeat center;
                                @include bgIconCoord(22,8,5);
                                display: block;
                            }
                        }
                    }
                }
            }

            input[type="radio"] {
                opacity: 0;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                &:hover+.alias,
                &:checked+.alias {
                    &:before {
                        box-shadow: inset 0 0 0 2px $white;
                    }
                }

                +.alias {
                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        width: 14px;
                        height: 14px;
                        border: 1px solid $primaryColor;
                        background-color: $primaryColor;
                        box-shadow: inset 0 0 0 10px $white;
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    .rightPart {
        width: 100%;
        
        //wishlist
        .wrapper_wishlist {
            width: 96%;
            margin: 0 auto;

            & > * {
                box-sizing: border-box;
            }

            .wish_liste_product {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                align-items: stretch;
                width: 100%;
                max-width: 1400px;
                gap: 20px;
                margin: 16px auto 0;

                .product_ctn {
                    width: calc((100% - 40px) / 3);
                    margin-bottom: 20px;
                    position:relative;
                    border: 1px #c5c5c5 solid;

                    @media only screen and (max-width: 960px) {
                        width: calc((100% - 40px) / 2);
                    }

                    .label_select_product {
                        position:absolute;
                        left:-8px;
                        top:50%;
                        transform: translateY(-50%);
                        cursor: pointer;
                        width: 15px;
                        height: 15px;
                    }

                    .checkbox_select_product{
                        position: absolute;
                        height: 100%;
                        left: 0;
                        z-index: 5;
                        opacity: 0;

                        &:not(:checked) + label {
                            &::before {
                                content: '';
                                position: absolute;
                                width: 15px;
                                height: 15px;
                                border: 1px solid #b1b1b1;
                                background: $white;
                                border-radius: 20px;
                            }
                        }

                        &:checked + label {
                            &::before {
                                content: '';
                                position: absolute;
                                width: 15px;
                                height: 15px;
                                border: 1px solid #b1b1b1;
                                background: $black;
                                box-shadow: inset 0 0 0 2px $white;
                                border-radius: 20px;
                            }
                        }
                    }

                    &:has(.checkbox_select_product:checked) {
                        border: 1px black solid;
                    }

                    .wrapper_product {
                        display: flex;
                        justify-content: flex-start;
                        padding: 15px 0 15px 15px;
                        transition: border 0.2s linear;

                        .photo_product {
                            .wrapper_img {
                                position: relative;

                                a {
                                    display: flex;
                                    width: 10.84vw;
                                    max-width: 208px;

                                    .wishProdVisu {
                                        width: 100%;
                                        height: auto;
                                    }
                                }

                                .wishNoStock {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background-color: rgba($white, 0.5);
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    text-align: center;
                                    z-index: 1;
                                }
                            }
                        }

                        .product_desc {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            width: 100%;
                            max-width: 185px;
                            margin-left: 30px;

                            .text_wrapper {
                                .wrap_txt,
                                .wp_color,
                                .wp_size,
                                .wrapper_price {
                                    margin-bottom: 10px;
                                }

                                .wrapper_price {
                                    margin-bottom: 20px;

                                    .bloc_price {
                                        display: flex;

                                        &:has(.price_tag + .price_tag) {
                                            flex-wrap: wrap;

                                            .price_tag {
                                                margin-right: 6px;

                                                & + .price_tag {
                                                    text-decoration: line-through;
                                                    margin-left: 0;
                                                    margin-right: 0;

                                                    &:before {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .btn_wrapper {
                                .addToCartFromWishlist {
                                    text-decoration: underline;
                                    cursor: pointer;
                                }
            
                                .remove_top_wishlist {
                                    display: block;
                                    color: $black;
                                    margin-top: 10px;
                                }
                            }
                        }

                        form {
                            .warn_availability {
                                display: flex !important;
                                justify-content: normal;
                                height: auto;
                                background-color: $white;
                                color: $black;
                                cursor: pointer;
                                border: none;
                                text-decoration: underline;
                            }

                            .warn_availability.none-important {
                                display: none !important;
                            }
                            
                            .expressAlertStock {
                                display: none;
                                position: relative;

                                .bloc_add_alert_form {
                                    .w-form-line {
                                        position: relative;
                                        width: 100%;
                                    }

                                    .w-input-element {
                                        width: 100%;
                                        height: 32px;
                                        font-family: $primaryFont;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 1;
                                        color: $black;
                                        padding: 0;
                                        padding-right: 24px;
                                        border: none;
                                        border-bottom: 1px solid $black;
                                        border-radius: 0;

                                        &:focus {
                                            box-shadow: none;
                                        }

                                        &.inputErr {
                                            border-bottom: 1px solid $red;
                                        }
                                    }

                                    .form_submit {
                                        position: absolute;
                                        right: 0;
                                        top: 5px;

                                        button { 
                                            height: 22px !important;
                                            width: 22px;
                                            border: none;

                                            &:focus {
                                                background-color: transparent;
                                            }

                                            span {
                                                color: $black;
                                            }
                                        }
                                    }
                                }

                                .alert_return {
                                    position: absolute;
                                    top: 21px;
                                    right: 0;
                                    width: 12px;
                                    height: 12px;
                                    cursor: pointer;
                                    background-image: url(../svg/spritesheet.svg);
                                    background-size: 800% auto;
                                    background-position: 71.42857% 50%;
                                    opacity: 1;

                                    span {
                                        display: none;
                                    }

                                    @media (min-width: 1440px) {
                                        top: -14px;
                                    }
                                }

                                .inputErr1 {
                                    button {
                                        background-color: transparent;
                                    }
                                }
                            }

                            .bloc_add_alert_erreur {
                                display: none;
                            }

                            .noSizeAvailable {
                                display: flex !important;
                                text-decoration: underline;
                                cursor: not-allowed;
                                border: none;
                                background-color: $white;
                                color: $black;

                                .alert_return {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .wrapper_btn_add,
            .wrapper_btn_share {
                width: 100%;
                max-width: 1400px;
                margin: 0 auto 10px;

                .form_submit {
                    width: 100%;
                    max-width: 298px;
                    position: relative;

                    #share_wl {
                        background-color: $white;

                        span {
                            color: $black;
                        }

                        &:hover {
                            background-color: $black;

                            span {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }

        .wrapper_form {
            &.detailCmdWrapper {
                max-width: 1280px;
                width: 95%;
                margin: 0 auto;
                display: flex;
                margin-top: 18px;

                .left_cmd_items_wrapper {
                    width: 64.8%;

                    .order_detail_head {
                        padding-bottom: 27px;
                        border-bottom: 1px solid $black;

                        .cmd_title {
                            margin-bottom: 21px;
                        }

                        .wrapper_infos_cmd {
                            display: flex;
                            justify-content: space-between;
                            gap: 93px;

                            .suivi_command {
                                display: flex;
                                align-items: flex-end;
                                justify-content: flex-end;
                                flex-direction: column;

                                .num_suivi {
                                    grid-column: 1;

                                    &:not(:last-of-type) {
                                        margin-bottom: 10px;
                                    }

                                    a {
                                        color: $black;
                                        text-decoration: none;
                                        position: relative;

                                        span {
                                            &:after {
                                                content: "Suivre ma commande";
                                                border-bottom: 1px solid $black;
                                                width: 157px;
                                                margin-left: 10px;
                                            }
                                        }
                                    }
                                }

                                .wrapperTrackBtn {
                                    display: none;
                                }
                                
                                .wrapperTrackBtn {
                                    padding-left: 9px;
                                    grid-column: 2;
                                    align-self: flex-start;

                                    a {
                                        background: initial;
                                        color: $black;
                                        border: 0;
                                        height: initial;
                                        text-decoration: underline;
                                    }
                                }
                            }

                            .status_date {
                                .cmd_date {
                                    padding-bottom: 10px;
                                }
                            }
                        }
                    }

                    .cmd_items {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        width: 100%;

                        .product_cart {
                            width: calc((100% - 46px) / 2);
                            padding-bottom: 14px;

                            .product-link {
                                display: flex;

                                .cart_detail_box {
                                    margin-left: 24px;
                                    width: 155px;

                                    .itemFeat {
                                        padding-bottom: 10px;

                                        &.item_price {
                                            display: flex;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .num_product {
                        margin: 42px 0 9px 0;
                    }
                }

                .right_cmd_items_wrapper {
                    margin-left: 59px;
                    width: 29%;

                    .wrapper_right {
                        .button {
                            margin: 10px 0;
                        }
                    }

                    .wrapper_cmd_retour {
                        display: flex;
                        flex-direction: column;
                        gap: 9px;
                        
                        a.button {
                            &.primary,
                            &.secondary {
                                &.order_detail_pfd {
                                    display: initial;
                                    background-color: initial;
                                    color: $black;
                                    border: 0;
                                    border-bottom: 1px solid $black;
                                    line-height: 1;
                                    width: fit-content;
                                    height: initial;
                                    margin-top: 0;
                                }
                            }

                            &.secondary {
                                height: initial;
                            }
                        }

                        .text_info_return {
                            display: none;
                        }

                        .cmd_retour {
                            #comment_retourner_cmd {
                                #returnCmdGlobalE a {
                                    color: $black;
                                }

                                #createNumRetour {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 9px;

                                    .intro {
                                        margin-bottom: 20px;
                                    }
                                    
                                    #liste_motif_retour {
                                        .btn_container {
                                            a.button {
                                                display: initial;
                                                background-color: initial;
                                                color: $black;
                                                border: 0;
                                                border-bottom: 1px solid $black;
                                            }
                                        }
                                    }

                                    .content {
                                        &.links_comment_retourner_cmd {
                                            display: none;
                                        }
                                    }

                                    #motif_retour_etape {
                                        .return_popup_title {
                                            display: none;
                                        }

                                        #printPdf {
                                            .print-return {
                                                #btnPrintPdf {
                                                    width: 100% !important;

                                                    .btn_container {
                                                        &.two {
                                                            width: 100%;
                                                        }
                                                    }
                                                }

                                                .content {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .wrapper_delivery_addr  {
                        margin: 23px 0 29px;
                        display: flex;
                        flex-direction: column;
                        gap: 7px;

                        &.billing {
                            margin: 40px 0 20px;
                        }
                        
                        .addr_title {
                            text-transform: lowercase;

                            &::first-letter {
                                text-transform: uppercase;
                            }
                        }
                    }
            
                    .detailRecapTop {
                        padding-top: 16px;

                        .wrap_total_commande {
                            padding-bottom: 27px;
                            display: flex;
                            flex-direction: column;
                            gap: 20px;

                            .recapTitle {
                                border-bottom: 1px solid $black;
                                line-height: 1;
                                width: fit-content;
                            }

                            .formRow {
                                display: flex;
                                justify-content: space-between;
                            }
                        }
                    }
                }
            }
        }
    }

    .detailCmdTop {
        margin-bottom: 38px;
        max-width: 1265px;
        width: 100%;
                    
        a {
            color: $black;
            padding-left: 20px;
            text-underline-offset: 3px;
        }

        &:before {
            content: "";
            width: 14px; 
            height: 14px;
            background-image: url('../svg/spritesheet.svg');
            @include bgIconCoord(5,8,5);
            position: absolute;
            rotate: 90deg;
            z-index: -1;
        }

        &:after {
            padding-right: 10px;
        }
    }
}

body.order {
    &:has(.popup_numero_retour.actif) {
        .shad {
            &.actif {
                z-index: 34;
            }
        }
    }

    .account_name {
        margin-top: 0;
    }
}

#lightboxCustomerAddress {
    right: 0;
    top: 0;
    height: 100vh;
    height: 100dvh;
    width: 496px;
    padding: 31px 26px 31px 30px;
    box-sizing: border-box;

    &.actif {
        z-index: 36;
    }

    .close_pop {
        top: 12px;
        right: 16px;
    }

    .popup_container {
        #wrapper_form_adresse {
            height: 95vh;
            height: 95dvh;

            .addNewAddress,
            .editAddress {
                .w-form {
                    .delivery_section_title {
                        padding: 7px 0px 41px 0px;
                        text-align: center;
                    }

                    .addrFullDisplay {
                        margin-bottom: 25px;

                        .addrDepartment {
                            display: none;
                        }
                    }
                    
                    .w-input-group {
                        padding: 0;
                        margin: 0;
                        border: 0;

                        .w-group-label {
                            font-family: $primaryFont;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                            color: $black;
                        }

                        .w-form-line {
                            &:nth-child(2) {
                                .w-input-container {
                                    & + .w-input-container {
                                        margin-left: 15px;
                                    }
                                }
                            }

                            &.last-is-hidden {
                                & + .w-form-line {
                                    display: none;
                                }
                            }
                        }
                    }

                    &:has(.w-input-group[style*="display: none"]) {
                        .w-form-line {
                            &:nth-child(6) {
                                margin-top: 15px;
                            }
                        }
                    }

                    .w-form-line {
                        margin-bottom: 18px;

                        &.add-complementary-line {
                            display: none;
                        }

                        &:has(+ .w-has-tel) {
                            flex-direction: column;
                            margin-top: 25px;
                            margin-bottom: 25px;

                            .w-input-container {
                                & + .w-input-container {
                                    margin-left: 0;
                                    margin-top: 25px;
                                }
                            }
                            
                        }

                        .w-input {
                            .w-input-element {
                                font-family: $primaryFont;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: normal;
                                color: $black;
                                height: 40px;
                                border: 0;
                                border-bottom: solid 1px $black;
                                padding: 0;
                                border-radius: 0;
                            }

                            .w-input-label {
                                font-family: $primaryFont;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: normal;
                                color: rgba(0, 0, 0, 0.5);
                                left: 0;
                            }

                            &.w-nonempty {
                                .w-input-element {
                                    padding-top: 10px;
                                }

                                .w-input-label {
                                    font-size: 12px;
                                    line-height: 14.4px;
                                    color: $black;
                                    top: 44%;
                                }
                            }

                            &.w-focused {
                                .w-input-element {
                                    box-shadow: none;
                                }
                            }

                            &.w-has-error {
                                .w-input-element {
                                    border-bottom: solid 1px $red;
                                    box-shadow: none;
                                }
                            }

                            &.w-address-input {
                                .noaddr {
                                    display: none;
                                }
                            }

                            &.w-date-input {
                                .w-input-element {
                                    padding-top: 10px;
                                }

                                .w-input-label {
                                    font-size: 12px;
                                    line-height: 14.4px;
                                    color: $black;
                                    top: 44%;
                                }
                            }

                            &.w-tel-input {
                                .w-input-element {
                                    padding-left: 56px;
                                }

                                .selected-flag {
                                    background-color: transparent;
                                    justify-content: space-between;
                                    padding: 0;
                                    border: 0;
                                    width: 42px;
                                    height: 100%;

                                    &:before {
                                        background-color: transparent;
                                        width: 24px;
                                        height: 15px;
                                        margin-top: 5px;
                                    }

                                    &:after {
                                        background: transparent url('../svg/spritesheet.svg') no-repeat center;
                                        @include bgIconCoord(5,8,5);
                                        width: 12px;
                                        height: 9px;
                                        border: 0;
                                        margin-top: 2px;
                                    }
                                }
                        
                                .country-list {
                                    padding-left: 0;

                                    .country {
                                        font-size: 14px;

                                        &.FR {
                                            & + .country {
                                                display: none;
                                            }
                                        }
                                    }
                                }

                                .w-input-label {
                                    left: 56px;
                                }

                                & + .errormsgadd {
                                    display: none;
                                }
                            }

                            &.addrType {
                                margin-top: 25px;
                            }
                        }
                        
                        .w-dropdown {
                            height: 40px;
                            border: 0;
                            border-bottom: solid 1px $black;
                            padding: 0;
                            border-radius: 0;

                            .w-value {
                                font-family: $primaryFont;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: normal;
                                color: $black;
                                padding: 0;
                                top: 60%;
                            }

                            &.w-has-error {
                                border-bottom: solid 1px $red;
                                box-shadow: none;
                            }
                            
                            &:after {
                                background: url('../svg/spritesheet.svg') no-repeat center;
                                @include bgIconCoord(5,8,5);
                                width: 12px;
                                height: 9px;
                                border: 0;
                                margin-top: 2px;
                            }

                            &.w-focused {
                                box-shadow: none;
                            }
                        }

                        &.w-submit {
                            max-width: 100%;
                            padding-bottom: 25px;

                            &.addrSubmit {
                                margin-top: 30px;
                                max-width: 100%;
                            }
                        }

                        &.notice {
                            display: none;
                        }
    
                        [type="date"]::-webkit-calendar-picker-indicator {
                            opacity: 0;
                        }
            
                        [type="date"]::-webkit-date-and-time-value {
                            text-align: left;
                        }

                        .w-input-note {
                            margin-top: 0;
                        }
    
                        .img_error,
                        .valid_form {
                            display: none;
                        }
                    }
                }
            }

            &.os-host-overflow-y {
                width: 98%;
                padding-right: 23px;
            }
        }
    }
}

body {
    &.customer,
    &.wishlist {
        #site_global_wrap {
            .main_ariane {
                .breadcrumbs {
                    padding-top: 0.8rem;
                    padding-bottom: 0 !important;
                }
            }
        }
    }

    &.en {
        &.order {
            .wrapper_moncompte {
                .rightPart {
                    .wrapper_form  {
                        .left_cmd_items_wrapper {
                            .order_detail_head {
                                .wrapper_infos_cmd {
                                    .suivi_command {
                                        .num_suivi {
                                            a {
                                                span {
                                                    &:after {
                                                        content: "Track my order";
                                                        border-bottom: 1px solid $black;
                                                        width: 157px;
                                                        margin-left: 10px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &:has(#lightboxCustomerAddress.actif) {
        #shad {
            &.actif {
                z-index: 35;
            }
        }
    }
}